import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { LS_LIVECHAT_OPTIONS, userRole } from "~constants";
import StorageHelper from "~helpers/StorageHelper";
import { setSelectedBot, setSelectedProject } from "~store/actions";
import { clearPushNotification, requestPushNotification } from "~store/notification/actions";
import { getPopupSettings } from "~store/settings/popupSettings/actions";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { setLivechatOptions, wsConnect } from "~store/socket/livechat/actions";
import { selectBots, selectCurrentProject, selectProjects, selectUserInfo } from "~store/user/selectors";

export const projectLoadStatus = {
  ready: "ready",
  loading: "loading",
  redirect: "redirect",
};

function useProjectLoad() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId, botId } = useParams();

  const userInfo = useSelector(selectUserInfo);
  const projects = useSelector(selectProjects);
  const bots = useSelector(selectBots);
  const currentProject = useSelector(selectCurrentProject);
  const [status, setStatus] = useState(projectLoadStatus.loading);
  const [hasLivechatAccess, setHasLivechatAccess] = useState(false);
  const popupSettings = useSelector(selectPopupSettingsState);

  let project = !!projectId && (projects || []).find((project) => project.id === Number(projectId));
  let bot = !!botId && bots.find((bot) => bot.id === Number(botId));
  if (projectId === "0" || botId === "0") {
    project = project || projects?.[0];
    const mainBotId = project?.main_bot;
    if (project) {
      const botsForProject = bots.filter((bot) => bot.projectId === project.id);
      const targetBotId = Number(botId) > 0 ? Number(botId) : (mainBotId ?? botsForProject?.[0]?.id);
      bot = botsForProject.find((bot) => bot.id === targetBotId);
      const urlChunks = history.location.pathname.split("/");
      const projectIndex = urlChunks.findIndex((chunk) => chunk === "project");
      const botIndex = urlChunks.findIndex((chunk) => chunk === "bot");
      if (projectIndex) {
        urlChunks[projectIndex + 1] = project?.id || 0;
      }
      if (botIndex) {
        urlChunks[botIndex + 1] = bot?.id || 0;
      }

      history.replace(urlChunks.join("/"));
    }
  }
  useEffect(() => {
    if (project && bot) {
      if (!currentProject) {
        setStatus(projectLoadStatus.loading);
      } else {
        const botIdList = (project.chatbot_list || Object.values(project.chatbot_status || {}))
          .map((bot) => [bot?.id, bot?.smalltalk?.id].filter(Boolean))
          .flat();
        if (botIdList.includes(bot.id)) {
          dispatch(setSelectedProject(project));
          dispatch(setSelectedBot(bot));

          setStatus(projectLoadStatus.ready);
        } else {
          setStatus(projectLoadStatus.redirect);
        }
      }
    } else {
      if (!userInfo?.id) {
        setStatus(projectLoadStatus.loading);
      } else if (userInfo?.project_roles?.length) {
        setStatus(projectLoadStatus.redirect);
      } else if (!userInfo?.project_roles?.length && projectId === "0" && botId === "0") {
        setStatus(projectLoadStatus.ready);
      } else {
        setStatus(projectLoadStatus.redirect);
      }
    }
  }, [project, bot, dispatch, currentProject, userInfo.project_roles, projectId, botId]);

  useEffect(() => {
    if (currentProject?.id && popupSettings?.project !== currentProject?.id) {
      //Load initial project settings
      dispatch(getPopupSettings(currentProject.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject?.id, dispatch]);

  useEffect(() => {
    if (projects?.length) {
      let hasLivechatAccess = false;
      const livechatValidRoles = [userRole.admin, userRole.callCenterAgent];
      for (const p of projects) {
        if (p.permissions.some((permission) => livechatValidRoles.includes(permission))) {
          hasLivechatAccess = true;
          break;
        }
      }
      //Automatically connect to livechat if user has access
      setHasLivechatAccess(hasLivechatAccess);
    }
  }, [projects, dispatch]);

  useEffect(() => {
    if (hasLivechatAccess) {
      StorageHelper.get(LS_LIVECHAT_OPTIONS).then((options = {}) => {
        if (options && typeof options === "object" && Object.keys(options)?.length) {
          if (options?.filters && Array.isArray(options?.filters)) {
            // filter type changed single dimension array to object[projectId:Array] format
            options.filters = {};
          }
          dispatch(setLivechatOptions(options));
        }
        dispatch(wsConnect("initialConnect"));
      });
    }
  }, [hasLivechatAccess, dispatch]);

  useEffect(() => {
    if (import.meta.env.VITE_APP_NOTIFICATION_ENABLED === "true") {
      dispatch(requestPushNotification());
    } else {
      dispatch(clearPushNotification());
    }
  }, [dispatch]);

  return status;
}
export default useProjectLoad;
