import { createRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit, MdSync } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import PalDot from "~components/mui/PalDot";
import {
  apiUrlWhatsappTemplate,
  chatbotGupshupCategoryMap,
  chatbotGupshupLanguageMap,
  chatbotGupshupTemplateStatus,
  chatbotGupshupTemplateStatusMap,
  chatbotGupshupTemplateTypeMap,
  tableFilterStorageKey,
} from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteWhatsappTemplate, syncWhatsappTemplate } from "~store/platform/actions";
import { selectCurrentProject } from "~store/user/selectors";
// import { deleteMemberField, getFilterList } from "~store/organization/actions";

export default function WhatsappTemplateMessageList({ organization }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = createRef();

  const currentProject = useSelector(selectCurrentProject);

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDeleteWithFormat(row?.response_data?.elementName))) return;
      await dispatch(deleteWhatsappTemplate(currentProject.id, row.id));
      tableRef.current.refresh();
    };
    return [
      {
        label: t("common.name"),
        key: "response_data.elementName",
      },
      {
        label: t("common.status"),
        key: "response_data.status",
        render: (row) => (
          <div className="d-flex align-items-center">
            <PalDot
              color={
                row.response_data?.status === chatbotGupshupTemplateStatus.PENDING
                  ? "warning-600"
                  : row.response_data?.status === chatbotGupshupTemplateStatus.APPROVED
                    ? "primary"
                    : "danger"
              }
            />
            {chatbotGupshupTemplateStatusMap[row.response_data.status]
              ? t(chatbotGupshupTemplateStatusMap[row.response_data.status])
              : row.response_data.status}
          </div>
        ),
      },
      {
        label: t("common.category"),
        key: "response_data.category",
        render: (row) => (
          <span>
            {chatbotGupshupCategoryMap[row.response_data?.category]
              ? t(chatbotGupshupCategoryMap[row.response_data?.category])
              : row.response_data?.category}
          </span>
        ),
      },
      {
        label: t("dashboard.whatsappTemplateMessageList.templateType"),
        key: "response_data.templateType",
        render: (row) => (
          <span>
            {chatbotGupshupTemplateTypeMap[row.response_data.templateType]
              ? t(chatbotGupshupTemplateTypeMap[row.response_data.templateType])
              : row.response_data.templateType}
          </span>
        ),
      },
      {
        label: t("common.template"),
        key: "response_data.data",
      },
      {
        label: t("dashboard.whatsappTemplateMessageList.vertical"),
        key: "response_data.vertical",
      },
      {
        label: t("common.language"),
        key: "response_data.languageCode",
        render: (row) => (
          <span>
            {chatbotGupshupLanguageMap[row.response_data.languageCode]
              ? t(chatbotGupshupLanguageMap[row.response_data.languageCode])
              : row.response_data.languageCode}
          </span>
        ),
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/contactcenter/livechat/whatsapp-template-messages/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, tableRef, currentProject.id, t]);

  const actions = useMemo(() => {
    const handleClickSync = async () => {
      await dispatch(syncWhatsappTemplate(currentProject.id));
      tableRef.current.refresh();
    };
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: "/contactcenter/livechat/whatsapp-template-messages/form/",
      },
      {
        icon: MdSync,
        color: "primary",
        onClick: handleClickSync,
        tooltipText: t("dashboard.whatsappTemplateMessageList.syncButtonTooltip"),
      },
    ];
  }, [dispatch, tableRef, currentProject.id, t]);

  const parentName = [
    ...(organization
      ? [
          {
            label: t("common.actionTemplates"),
            url: "/organization/action-templates/",
          },
          {
            label: t("dashboard.whatsappTemplateMessageList.actionTemplateCreate"),
            url: "/organization/action-templates/form/",
          },
          {
            label: t("dashboard.whatsappTemplateMessageList.whatsappTemplateMessages"),
          },
        ]
      : [
          {
            label: t("common.livechat"),
            url: "/contactcenter/livechat/",
          },
          {
            label: t("dashboard.whatsappTemplateMessageList.whatsappTemplateMessages"),
          },
        ]),
  ];
  return (
    <PRContainer
      actions={actions}
      description={t("dashboard.whatsappTemplateMessageList.description")}
      name={organization ? t("common.organization") : t("common.contactCenter")}
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        storageKey={tableFilterStorageKey.whatsappTemplateList}
        url={apiUrlWhatsappTemplate.getList.format(currentProject.id)}
      />
    </PRContainer>
  );
}
