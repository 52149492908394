import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { PalDiffEditor, PalEditor, useMonaco } from "@palamar/fe-library";
import classNames from "classnames";
import JSON5 from "json5";
import { cloneDeep, set } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdDarkMode,
  MdDragHandle,
  MdFullscreen,
  MdFullscreenExit,
  MdLightMode,
  MdUnfoldLess,
  MdUnfoldMore,
} from "react-icons/md";
import { PiBracketsCurlyBold } from "react-icons/pi";
import { Resizable } from "react-resizable";
import { FormFeedback, Input } from "reactstrap";

import { Box } from "@mui/material";

import { useStorage } from "~common/hooks/useStorage";
import PRButton from "~components/Generic/PRButton";
import PRModal from "~components/Generic/PRModal";
import AlertHelper from "~helpers/AlertHelper";

import "react-resizable/css/styles.css";
import "./style.scss";

const handlerHeight = 5;
export default function CodeEditor({
  onChange,
  value = "",
  defaultLanguage = "python",
  language,
  readOnly = false,
  invalid,
  onMount,
  editorProps: editorPropsProp,
  minimap = true,
  lineNumbers = true,
  noToolbar = false,
  showFoldUnfold = false,
  noResizable = false,
  noBorder = false,
  wordWrap = true,
  defaultHeight = 120,
  autoRestoreModelAndState = true,
  scrollBeyondLastLine = false,
  theme: themeProp,
  diff,
  customCodeToolbar,
}) {
  const monacoInstance = useMonaco();
  const { t } = useTranslation();
  /** @type {[import("monaco-editor/esm/vs/editor/editor.api").editor.IStandaloneCodeEditor, Function]} */
  const [editor, setEditor] = useState(null);
  /** @type {{ current: typeof import("monaco-editor/esm/vs/editor/editor.api") }} */
  const monacoRef = useRef(null);
  const [themeState, setThemeState] = useStorage("lc_editor_theme", "light");
  const [fullscreen, setFullscreen] = useState(false);
  const [folded, setFolded] = useState(false);
  const [height, setHeight] = useState(defaultHeight);
  // const [isEditorScrollable, setIsEditorScrollable] = useState(true);
  const [defaultEditorProps, setDefaultEditorProps] = useState({
    options: {
      minimap: { enabled: minimap },
      lineNumbers: lineNumbers ? "on" : "off",
      scrollBeyondLastLine:
        scrollBeyondLastLine === false
          ? false
          : scrollBeyondLastLine
            ? true
            : defaultHeight === "100%" || fullscreen || defaultLanguage === "python",
      scrollbar: {
        consumeMouseWheelIfScrollbarIsNeeded: true,
        alwaysConsumeMouseWheel: false,
      },
      unicodeHighlight: {
        allowedCharacters: {
          ı: true,
          ş: true,
          ç: true,
          ö: true,
          ü: true,
          ğ: true,
          İ: true,
          Ş: true,
          Ç: true,
          Ö: true,
          Ü: true,
          Ğ: true,
        },
      },
      // tabSize: 2,
      // indentSize: "tabSize",
      // insertSpaces: true,
      // detectIndentation: false,
    },
  });

  const [jsonConfig, setJsonConfig] = useStorage("lc_editor_json_config", {});
  const { editorPropsOptions, editorProps } = useMemo(() => {
    const { options: editorPropsOptions = {}, ...editorProps } = editorPropsProp || {};

    return {
      editorPropsOptions,
      editorProps,
    };
  }, [editorPropsProp]);

  const monacoStateRef = useRef({
    viewState: null,
    cursorPosition: null,
    model: null,
    originalModel: null,
  });

  const theme = themeProp || themeState;
  const handleMountEditor = useCallback(
    /**
     * @type {(
     *   editor: import("monaco-editor").editor.IStandaloneCodeEditor,
     *   monaco: typeof import("monaco-editor")
     * ) => void}
     */
    (editor, monaco) => {
      setEditor(editor);
      onMount?.({ editor, monaco });
      monacoRef.current = monaco;
      if (monacoStateRef.current.viewState && autoRestoreModelAndState) {
        editor.restoreViewState(monacoStateRef.current.viewState);
        if (editor.getEditorType() === "vs.editor.IDiffEditor") {
          /*           
            Do not need to auto-restore model when content is changed.
            should be implemented if diff editor is need to be used as standalone editor in future
          
            editor.setModel({
             original: monacoStateRef.current.originalModel,
             modified: monacoStateRef.current.model,
           });
            */
        } else {
          editor.setModel(monacoStateRef.current.model);
        }
      }

      const disposeList = [];

      disposeList.push(
        editor.addAction({
          id: "toggle-word-wrap",
          label: "Toggle Word Wrap",
          contextMenuGroupId: "navigation",
          keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.F1],
          run: function (ed) {
            const wordWrap = ed.getOption(monaco.editor.EditorOption.wordWrap);
            const newWrap = wordWrap === "off" ? "on" : "off";
            setJsonConfig((prev) => ({
              ...prev,
              wordWrap: newWrap,
            }));
          },
        })
      );

      //add action to editor to thats open the json config
      disposeList.push(
        editor.addAction({
          id: "open-json-config",
          label: "Load VSCode JSON Config",
          contextMenuGroupId: "navigation",
          keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyL],
          run: function (ed) {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = ".json";
            input.title = "Please select VSCode JSON Config file (settings.json)";
            input.onchange = (e) => {
              const file = e.target.files[0];
              if (!file) return;
              const reader = new FileReader();
              reader.readAsText(file);
              reader.onload = (readerEvent) => {
                let content = readerEvent.target.result;
                if (!content) return;
                let jsonObj = {};
                try {
                  jsonObj = JSON5.parse(content);
                } catch (error) {
                  AlertHelper.showError(t("component.codeEditor.jsonParseError"));
                  console.error(content);
                  return;
                }

                const newSettings = cloneDeep(ed.getModel().getOptions());
                const configPrefix = "editor.";
                for (const key in jsonObj) {
                  if (!key.startsWith(configPrefix)) {
                    //only editor settings
                    continue;
                  }
                  const newKey = key.substring(configPrefix.length);
                  set(newSettings, newKey, jsonObj[key]);
                }

                setJsonConfig(newSettings);
                AlertHelper.showSuccess(t("component.codeEditor.jsonConfigLoaded"));
                console.log("newSettings", newSettings);
              };
              input.remove();
            };
            input.click();
          },
        })
      );
      return () => {
        disposeList.forEach((d) => d.dispose());
      };
    },
    [autoRestoreModelAndState]
  );

  useEffect(() => {
    if (editor) {
      if (typeof jsonConfig !== "object") return;
      editor.updateOptions(jsonConfig);
    }
  }, [editor, jsonConfig]);

  const handleThemeChange = useCallback(() => {
    if (editor) {
      const newTheme = theme === "light" ? "vs-dark" : "light";
      setThemeState(newTheme);
      monacoRef.current.editor.setTheme(newTheme);
    }
  }, [editor, theme, setThemeState]);

  const handleFoldUnfold = useCallback(() => {
    if (editor) {
      setFolded((prev) => {
        if (prev) {
          editor.getAction("editor.unfoldAll").run();
        } else {
          editor.getAction("editor.foldAll").run();
        }
        return !prev;
      });
    }
  }, [editor]);

  const handleFormatCode = useCallback(() => {
    if (editor) {
      editor.getAction("editor.action.formatDocument").run();
    }
  }, [editor]);

  const handleFullscreen = useCallback(() => {
    setFullscreen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (editor) {
      editor.updateOptions({ readOnly });
    }
  }, [editor, readOnly]);

  // const handleVisibilityInfo = useCallback(() => {
  //   if (!editor) return;

  //   let editorModel = editor.getModel();
  //   let visibleRange = editor.getVisibleRanges();
  //   if (!visibleRange || visibleRange.length === 0 || !editorModel) return;

  //   let lineCount = editorModel.getLineCount();
  //   let firstVisibleLineNumber = visibleRange[0].startLineNumber;
  //   let lastVisibleLineNumber = visibleRange[0].endLineNumber;
  //   let visibleLineCount = lastVisibleLineNumber - firstVisibleLineNumber + 1;

  //   console.log("Total line count:", lineCount);
  //   console.log("Visible line count:", visibleLineCount);
  //   const isScrollable = lineCount > visibleLineCount;
  //   if (isEditorScrollable !== isScrollable) {
  //     setIsEditorScrollable(isScrollable);
  //   }
  // }, [editor, isEditorScrollable]);

  // useEffect(() => {
  //   if (!editor) return;
  //   let editorElement = editor.getDomNode();
  //   if (!editorElement) return;
  //   if (!isEditorScrollable) {
  //     editorElement.addEventListener("wheel", preventScroll, { passive: false });
  //   } else {
  //     let editorElement = editor.getDomNode();
  //     editorElement.removeEventListener("wheel", preventScroll);
  //     // console.log("editorElement", editorElement);
  //     // let listeners = editorElement.getEventListeners("wheel");
  //     // listeners.forEach(function (listener) {
  //     //   editorElement.removeEventListener("wheel", listener);
  //     // });
  //   }
  // }, [isEditorScrollable, editor]);

  // useEffect(() => {
  //   handleVisibilityInfo();
  // }, [height, handleVisibilityInfo]);

  useEffect(() => {
    if (!monacoRef.current?.editor || !editor) return;
    const disposables = [];
    let activeModelDisposables = {
      current: [],
    };

    const subscribeContentChange = () => {
      activeModelDisposables.current.forEach((d) => d.dispose());
      activeModelDisposables.current = [];
      const isDiffEditor = editor.getEditorType() === "vs.editor.IDiffEditor";
      const activeModel = isDiffEditor ? editor.getModifiedEditor().getModel() : editor.getModel();

      if (activeModel) {
        activeModelDisposables.current.push(
          activeModel.onDidChangeContent(() => {
            // const activeModel =
            //   editor.getEditorType() === "vs.editor.IDiffEditor"
            //     ? editor.getModifiedEditor().getModel()
            //     : editor.getModel();

            let currentContent = "";
            if (activeModel && !activeModel?.isDisposed()) {
              currentContent = activeModel?.getValue() || "";
            }
            onChange?.(currentContent, activeModel);
            if (autoRestoreModelAndState) {
              monacoStateRef.current = {
                viewState: editor.saveViewState(),
                model: activeModel,
              };
              if (editor.getEditorType() === "vs.editor.IDiffEditor") {
                monacoStateRef.current.originalModel = editor.getOriginalEditor().getModel();
              }
            }
          })
        );
      }
    };

    const isDiffEditor = editor.getEditorType() === "vs.editor.IDiffEditor";
    if (isDiffEditor) {
      const originalEditor = editor.getOriginalEditor();
      const modifiedEditor = editor.getModifiedEditor();
      disposables.push(
        originalEditor.onDidChangeModel(() => {
          subscribeContentChange();
        })
      );
      disposables.push(
        modifiedEditor.onDidChangeModel(() => {
          subscribeContentChange();
        })
      );
    } else {
      disposables.push(
        editor.onDidChangeModel(() => {
          subscribeContentChange();
        })
      );
    }
    subscribeContentChange();
    // disposables.push(editor.onDidChangeModelContent(handleVisibilityInfo));

    return () => {
      disposables.forEach((d) => d.dispose());
      activeModelDisposables.current.forEach((d) => d.dispose());
    };
  }, [onChange, monacoRef, editor, autoRestoreModelAndState]);

  useEffect(() => {
    if (diff || !monacoRef.current?.editor || !editor) return;
    const activeModel =
      editor.getEditorType() === "vs.editor.IDiffEditor" ? editor.getModifiedEditor().getModel() : editor.getModel();

    let currentContent = "";
    if (activeModel && !activeModel?.isDisposed()) {
      currentContent = activeModel?.getValue() || "";
    }
    if (currentContent !== value) {
      editor.setValue(value || "");
    }
  }, [monacoRef, editor, value, diff]);

  useEffect(() => {
    setDefaultEditorProps((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        ...editorPropsOptions,
        minimap: { enabled: minimap },
        lineNumbers: lineNumbers ? "on" : "off",
        ...(wordWrap && { wordWrap: "on", wrappingIndent: "indent" }),
      },
    }));
  }, [minimap, editorPropsOptions, wordWrap, lineNumbers]);

  const handleResize = useCallback((e, { size }) => {
    setHeight(size.height);
  }, []);

  const ResizableWrapper = useCallback(
    ({ children, ...rest }) => {
      if (noResizable) {
        return <>{children}</>;
      }
      return <Resizable {...rest}>{children}</Resizable>;
    },
    [noResizable]
  );

  const EditorItem = diff ? PalDiffEditor : PalEditor;

  const monacoEditor = (
    <>
      {!noToolbar ? (
        <div className="code-toolbar ">
          <div className="d-flex p-1 gap-1">
            <PRButton
              outline
              icon={!fullscreen ? MdFullscreen : MdFullscreenExit}
              size="sm"
              tooltipDelay={1000}
              tooltipText={!fullscreen ? "Fullscreen" : "Exit Fullscreen"}
              onClick={handleFullscreen}
            />
            <PRButton
              outline
              icon={theme === "light" ? MdDarkMode : MdLightMode}
              size="sm"
              tooltipDelay={1000}
              tooltipText={theme === "light" ? "Dark Theme" : "Light Theme"}
              onClick={handleThemeChange}
            />
            {showFoldUnfold && (
              <PRButton
                outline
                icon={!folded ? MdUnfoldLess : MdUnfoldMore}
                size="sm"
                tooltipDelay={1000}
                tooltipText={folded ? "Unfold all" : "Fold all"}
                onClick={handleFoldUnfold}
              />
            )}
            {["json", "html"].includes(editor?.getModel()?.getLanguageId()) && (
              <PRButton
                outline
                icon={PiBracketsCurlyBold}
                size="sm"
                tooltipDelay={1000}
                tooltipText={"Format Code"}
                onClick={handleFormatCode}
              />
            )}
            {customCodeToolbar}
          </div>
        </div>
      ) : (
        <div className="no-code-toolbar"></div>
      )}
      <ResizableWrapper
        // handleSize={""}
        handle={
          <div className="d-flex justify-content-center align-items-center pr-resize-handle">
            <MdDragHandle className="fs-6" />
          </div>
        }
        height={height}
        resizeHandles={fullscreen ? [] : ["s"]}
        width={Infinity}
        onResize={handleResize}
      >
        <div
          className="code-editor "
          style={{
            height: fullscreen || height === "100%" ? "100%" : `${height + (noResizable ? 0 : handlerHeight)}px`,
            width: "100%",
          }}
        >
          {monacoInstance ? (
            <EditorItem
              defaultLanguage={defaultLanguage}
              defaultValue={value}
              height={fullscreen || height === "100%" ? "100%" : Math.max(height - 10, 0)}
              keepCurrentModel={true}
              keepCurrentModifiedModel={true}
              keepCurrentOriginalModel={true}
              language={language}
              loading={<div>Loading...</div>}
              theme={theme}
              onMount={handleMountEditor}
              {...defaultEditorProps}
              {...editorProps}
            />
          ) : (
            <Box alignItems="center" display="flex" height={1} justifyContent="center" textAlign="center" width={1}>
              {t("component.codeEditor.monacoLoading")}
            </Box>
          )}
        </div>
      </ResizableWrapper>
      <Input className="d-none" invalid={true} />
      {invalid && <FormFeedback type="invalid">{invalid}</FormFeedback>}
    </>
  );
  return (
    <div
      className={classNames("pr-code-editor", {
        invalid: invalid,
        "h-100": height === "100%",
        "no-border": noBorder,
      })}
    >
      {!fullscreen && monacoEditor}
      <PRModal
        fullscreen
        contentClassName={classNames("pr-code-editor", {
          dark: theme === "vs-dark",
          invalid: invalid,
          readonly: readOnly,
        })}
        submitText=""
        visible={fullscreen}
        onClose={handleFullscreen}
      >
        <div className="d-flex flex-column h-100">{fullscreen && monacoEditor}</div>
      </PRModal>
    </div>
  );
}
