import { useEffect, useMemo, useState } from "react";

import { PalMenu, PalMenuItem } from "@palamar/fe-library";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { Grid, Tab, Tabs } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import chartTheme from "~components/Generic/PRChart/theme.json";
import PalContainer from "~components/mui/PalContainer";
import PalPage from "~components/mui/PalPage";
import ExcelButton from "~components/Statistics/ExcelButton";
import FilterCard from "~components/Statistics/FilterCard";
import MoreButton from "~components/Statistics/MoreButton";
import { userRole } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import FileHelper from "~helpers/FileHelper";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import {
  getAgentAvailableTimeStatistics,
  getAgentLikeDislikeStatistics,
  getAgentRateStatistics,
  getAgentResponseTimeStatistics,
  getAgentSessionCountStatistics,
  getAgentTicketResolveCountStatistics,
  getAgentTicketResolveStatistics,
  getAgentTotalChatTimeStatistics,
} from "~store/statistics/actions";
import { selectAgents, selectAgentSpecificStatistics, selectStatisticsFilter } from "~store/statistics/selectors";

const chartStyle = {
  height: "600px",
  maxHeight: "50vh",
};

const chartTooltip = {
  trigger: "axis",
  formatter: function (params) {
    const date = new Date(params[0].data[0]);
    const formattedDate = date.toLocaleDateString();
    let tooltipText = `${formattedDate}<br/>`;
    if (params.value === 0) {
      return "";
    }
    params.forEach((item) => {
      if (item.value[1] !== 0) {
        tooltipText += `${item.marker} ${item.seriesName}: ${item.data[1]}<br/>`;
      }
    });
    return tooltipText;
  },
};
const chartSecondTimeTooltip = {
  trigger: "axis",
  formatter: function (params) {
    const date = new Date(params[0].data[0]);
    const formattedDate = date.toLocaleDateString();
    let tooltipText = `${formattedDate}<br/>`;
    if (params.value === 0) {
      return "";
    }
    params.forEach((item) => {
      if (item.value[1] !== 0) {
        tooltipText += `${item.marker} ${item.seriesName}: ${DateHelper.formatMoment(
          item.data[1],
          "seconds",
          "d [day] h [hour] m [min] s [sec]"
        )}<br/>`;
      }
    });
    return tooltipText;
  },
};

const AgentSessionRateStatistics = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpecificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentRateStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  // color: [
  //   chartTheme.theme.color[3],
  //   chartTheme.theme.color[6],
  //   chartTheme.theme.color[2],
  //   chartTheme.theme.color[9],
  //   chartTheme.theme.color[5],
  // ],
  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.rating1"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RATING_1 || {}),
          itemStyle: {
            color: chartTheme.theme.color[3],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.rating2"),
          type: "bar",
          stack: id,

          data: Object.entries(value?.AGENT_RATING_2 || {}),
          itemStyle: {
            color: chartTheme.theme.color[6],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.rating3"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RATING_3 || {}),
          itemStyle: {
            color: chartTheme.theme.color[2],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.rating4"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RATING_4 || {}),
          itemStyle: {
            color: chartTheme.theme.color[9],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.rating5"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RATING_5 || {}),
          itemStyle: {
            color: chartTheme.theme.color[5],
          },
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  const barWidth = 80 / (series.length / 5); //80 optimal width, 5 is the number of stacks

  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      barWidth: `${barWidth}%`,
    })),
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.agentStatistics.agentRatings")}
    />
  );
};
const AgentSessionResponseTimeStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpecificStatistics);
  const agents = useSelector(selectAgents);
  const { t } = useTranslation();
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentResponseTimeStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.5plusMin"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RESPONSE_TIME_300_PLUS || {}),
          itemStyle: {
            color: chartTheme.theme.color[3],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.5min"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RESPONSE_TIME_300 || {}),
          itemStyle: {
            color: chartTheme.theme.color[6],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.1min"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RESPONSE_TIME_60 || {}),
          itemStyle: {
            color: chartTheme.theme.color[2],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.30sec"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RESPONSE_TIME_30 || {}),
          itemStyle: {
            color: chartTheme.theme.color[9],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.15sec"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_RESPONSE_TIME_15 || {}),
          itemStyle: {
            color: chartTheme.theme.color[5],
          },
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  const barWidth = 80 / (series.length / 5); //80 optimal width, 5 is the number of stacks
  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      barWidth: `${barWidth}%`,
    })),
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.agentStatistics.agentResponseTime")}
    />
  );
};
const AgentSessionLikeDislikeStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpecificStatistics);
  const agents = useSelector(selectAgents);
  const { t } = useTranslation();
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentLikeDislikeStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " " + t("dashboard.agentStatistics.dislike"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_DISLIKE_COUNT || {}),
          itemStyle: {
            color: chartTheme.theme.color[3],
          },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + " " + t("dashboard.agentStatistics.like"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_LIKE_COUNT || {}),
          itemStyle: {
            color: chartTheme.theme.color[5],
          },
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  const barWidth = 80 / (series.length / 2); //80 optimal width, 2 is the number of stacks
  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      barWidth: `${barWidth}%`,
    })),
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.agentStatistics.agentLikeDislike")}
    />
  );
};

const AgentSessionCountStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpecificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  const { t } = useTranslation();
  useEffect(() => {
    q(dispatch(getAgentSessionCountStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label,
          type: "line",
          stack: id,
          data: Object.entries(value?.AGENT_SESSION_COUNT || {}),
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      // barWidth: `${barWidth}%`,
    })),
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.agentStatistics.agentSessionCount")}
    />
  );
};

const AgentAvailableTimeStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpecificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  const { t } = useTranslation();
  useEffect(() => {
    q(dispatch(getAgentAvailableTimeStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label,
          type: "line",
          stack: id,
          data: Object.entries(value?.AGENT_AVAILABLE_TIME || {}),
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  // const barWidth = 100 / series.length;
  const options = {
    tooltip: chartSecondTimeTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: function (value) {
          return DateHelper.formatMoment(value, "seconds", "h [hrs] m [min]");
        },
      },
    },
    series: series.map((series) => ({
      ...series,
      // barWidth: `${barWidth}%`,
    })),
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.agentStatistics.agentAvailableTime")}
    />
  );
};
const AgentTotalChatTimeStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpecificStatistics);
  const agents = useSelector(selectAgents);
  const { t } = useTranslation();
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  useEffect(() => {
    q(dispatch(getAgentTotalChatTimeStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label,
          type: "line",
          stack: id,
          data: Object.entries(value?.AGENT_TOTAL_CHAT_TIME || {}),
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  // const barWidth = 100 / series.length;
  const options = {
    tooltip: chartSecondTimeTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: function (value) {
          return DateHelper.formatMoment(value, "seconds", "h [hrs] m [min]");
        },
      },
    },
    series: series.map((series) => ({
      ...series,
      // barWidth: `${barWidth}%`,
    })),
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.agentStatistics.agentTotalChatTime")}
    />
  );
};

const AgentTicketResolveCountStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpecificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  const { t } = useTranslation();
  useEffect(() => {
    q(dispatch(getAgentTicketResolveCountStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label,
          type: "line",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_COUNT || {}),
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  // const barWidth = 100 / series.length;
  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      // barWidth: `${barWidth}%`,
    })),
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.agentStatistics.agentTicketResolveCount")}
    />
  );
};

const AgentTicketResolveStatistics = () => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectAgentSpecificStatistics);
  const agents = useSelector(selectAgents);
  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);
  const { t } = useTranslation();
  useEffect(() => {
    q(dispatch(getAgentTicketResolveStatistics(projectId, agentIds, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate, agentIds]);

  const series = Object.entries(statistics || {})
    .map(([id, value]) => {
      return [
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.15min"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_15 || {}),
          itemStyle: { color: chartTheme.theme.color[5] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.60min"),
          type: "bar",
          stack: id,

          data: Object.entries(value?.AGENT_TICKET_RESOLVE_60 || {}),
          itemStyle: { color: chartTheme.theme.color[9] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.2hrs"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_120 || {}),
          itemStyle: { color: chartTheme.theme.color[2] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.6hrs"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_360 || {}),
          itemStyle: { color: chartTheme.theme.color[10] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.1day"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_1440 || {}),
          itemStyle: { color: chartTheme.theme.color[11] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.2day"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_2880 || {}),
          itemStyle: { color: chartTheme.theme.color[6] },
        },
        {
          name: agents.find((a) => a.value === parseInt(id))?.label + t("dashboard.agentStatistics.2plusDay"),
          type: "bar",
          stack: id,
          data: Object.entries(value?.AGENT_TICKET_RESOLVE_2880_PLUS || {}),
          itemStyle: { color: chartTheme.theme.color[3] },
        },
      ];
    })
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((s) => agentIds.includes(parseInt(s.stack)));

  const barWidth = 80 / (series.length / 7); //80 optimal width, 7 is the number of stacks
  const options = {
    tooltip: chartTooltip,
    xAxis: {
      type: "time",
      min: filter.beginDate,
      max: filter.endDate,
    },
    yAxis: {
      type: "value",
    },
    series: series.map((series) => ({
      ...series,
      barWidth: `${barWidth}%`,
    })),
  };
  return (
    <PRChart
      notMerge
      loading={loading}
      option={options}
      style={chartStyle}
      title={t("dashboard.agentStatistics.agentTicketResolve")}
    />
  );
};

const StyledTab = styled(Tab)`
  font-weight: 600;
`;

const StyledGrid = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
`;

const AgentStatistics = () => {
  const [tab, setTab] = useState(0);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const agents = useSelector(selectAgents);
  const [menuOpen, setMenuOpen] = useState(false);
  const permissionUserList = useSelector(selectPermissionUserList);
  const { t } = useTranslation();
  const allAgents = useMemo(() => {
    return permissionUserList
      .filter((item) =>
        item.roles.some((r) =>
          [userRole.admin, userRole.ticketAgent, userRole.callCenterAgent, userRole.AgentManager].includes(r)
        )
      )
      .map((item) => item.id);
  }, [permissionUserList]);

  const agentIds = useMemo(() => agents.map((agent) => agent.value), [agents]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleExcelExport = async () => {
    let fn;
    switch (tab) {
      case 0:
        fn = getAgentRateStatistics;
        break;
      case 1:
        fn = getAgentResponseTimeStatistics;
        break;
      case 2:
        fn = getAgentLikeDislikeStatistics;
        break;
      case 3:
        fn = getAgentSessionCountStatistics;
        break;
      case 4:
        fn = getAgentAvailableTimeStatistics;
        break;
      case 5:
        fn = getAgentTotalChatTimeStatistics;
        break;
      case 6:
        fn = getAgentTicketResolveCountStatistics;
        break;
      case 7:
        fn = getAgentTicketResolveStatistics;
        break;
      default:
        break;
    }
    if (!fn) {
      AlertHelper.show(t("dashboard.agentStatistics.noDataError"), "error");
      return;
    }

    // get the buffer from fn(), and then save it as a file
    const buffer = await dispatch(fn(projectId, agentIds, filter.beginDate, filter.endDate, true));
    FileHelper.saveAs(buffer, "statistics", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  };

  const handleAllExcelExport = async () => {
    let fn;
    switch (tab) {
      case 0:
        fn = getAgentRateStatistics;
        break;
      case 1:
        fn = getAgentResponseTimeStatistics;
        break;
      case 2:
        fn = getAgentLikeDislikeStatistics;
        break;
      case 3:
        fn = getAgentSessionCountStatistics;
        break;
      case 4:
        fn = getAgentAvailableTimeStatistics;
        break;
      case 5:
        fn = getAgentTotalChatTimeStatistics;
        break;
      case 6:
        fn = getAgentTicketResolveCountStatistics;
        break;
      case 7:
        fn = getAgentTicketResolveStatistics;
        break;
      default:
        break;
    }
    if (!fn) {
      AlertHelper.show(t("dashboard.agentStatistics.noDataError"), "error");
      return;
    }

    // get the buffer from fn(), and then save it as a file
    setMenuOpen(false);
    const buffer = await dispatch(fn(projectId, allAgents, filter.beginDate, filter.endDate, true));
    FileHelper.saveAs(buffer, "statistics-all", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  };

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  return (
    <PalContainer
      description={t("dashboard.agentStatistics.description")}
      name={t("common.statistics")}
      parentName={t("dashboard.agentStatistics")}
    >
      <Grid container>
        <Grid item xs={12}>
          <FilterCard agentFilter />
        </Grid>
      </Grid>
      <PalPage collapse>
        <Grid container rowSpacing={2}>
          <Grid item md xs={10}>
            <Tabs
              sx={(t) => ({
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                },
                ".MuiTab-root.Mui-selected": {
                  borderBottom: `2px solid ${t.palette.primary.main}`,
                  paddingBottom: "10px",
                },
              })}
              TabIndicatorProps={{ sx: { display: "none" } }}
              value={tab}
              onChange={handleChangeTab}
            >
              <StyledTab label={t("dashboard.agentStatistics.agentRatings")} />
              <StyledTab label={t("dashboard.agentStatistics.agentResponseTime")} />
              <StyledTab label={t("dashboard.agentStatistics.agentLikeDislike")} />
              <StyledTab label={t("dashboard.agentStatistics.agentSessionCount")} />
              <StyledTab label={t("dashboard.agentStatistics.agentAvailableTime")} />
              <StyledTab label={t("dashboard.agentStatistics.agentTotalChatTime")} />
              <StyledTab label={t("dashboard.agentStatistics.agentTicketResolveCount")} />
              <StyledTab label={t("dashboard.agentStatistics.agentTicketResolve")} />
            </Tabs>
          </Grid>
          <StyledGrid item md={"auto"} xs={2}>
            <ExcelButton onClick={handleExcelExport} />
            <MoreButton id={"more-button"} onClick={handleOpenMenu} />
            <PalMenu
              anchorEl={document.getElementById("more-button")}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              <PalMenuItem onClick={handleAllExcelExport}>
                {t("dashboard.agentStatistics.exportAllToExcel")}
              </PalMenuItem>
            </PalMenu>
          </StyledGrid>

          <Grid item xs={12}>
            {tab === 0 && <AgentSessionRateStatistics />}
            {tab === 1 && <AgentSessionResponseTimeStatistics />}
            {tab === 2 && <AgentSessionLikeDislikeStatistics />}
            {tab === 3 && <AgentSessionCountStatistics />}
            {tab === 4 && <AgentAvailableTimeStatistics />}
            {tab === 5 && <AgentTotalChatTimeStatistics />}
            {tab === 6 && <AgentTicketResolveCountStatistics />}
            {tab === 7 && <AgentTicketResolveStatistics />}
          </Grid>
        </Grid>
      </PalPage>
    </PalContainer>
  );
};

export default AgentStatistics;
