import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label } from "reactstrap";

import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import PRInput from "~components/Generic/PRInput";
import HistoryHelper from "~helpers/HistoryHelper";
import { setSelectedBot } from "~store/actions";
import { selectBots, selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

export default function SmalltalkSelector() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const bots = useSelector(selectBots);
  const currentBot = useSelector(selectCurrentBot);
  const currentProject = useSelector(selectCurrentProject);
  const [checked, setChecked] = useState(currentBot.isSmalltalk);
  const [hasMounted, setHasMounted] = useState(false);

  const isSm = useBootstrapBreakpoint("sm");
  const isDisabled = !currentBot.smalltalk && !currentBot.parentBot;

  useEffect(() => {
    if (!hasMounted) setHasMounted(true);
  }, [hasMounted]);

  useEffect(() => {
    if (!hasMounted) return;
    setChecked(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject?.id]);

  return (
    <div className={isSm ? "fs-6" : "fs-5"} disabled={isDisabled}>
      <FormGroup switch className="d-flex align-items-center gap-2 m-0">
        <PRInput
          checked={checked}
          classNameInput="m-0"
          disabled={isDisabled}
          id="smalltalk_changer"
          type="switch"
          onClick={() => {
            setChecked(!checked);
            const currentUrlChunks = location.pathname.split("/");
            const botIndex = currentUrlChunks.findIndex((x) => x === "bot");
            if (currentBot.isSmalltalk) {
              dispatch(setSelectedBot(currentBot.parentBot));
              const id = currentBot.parentBot.id;
              currentUrlChunks[botIndex + 1] = id;
            } else {
              const matchedBot = bots.find((x) => x.id === currentBot.smalltalk.id);
              dispatch(setSelectedBot(matchedBot));
              const id = matchedBot.id;
              currentUrlChunks[botIndex + 1] = id;
            }
            const newUrl = currentUrlChunks.join("/");
            if (newUrl !== location.pathname) {
              HistoryHelper.replace(newUrl);
            }
          }}
        />
        <Label className="m-0" for="smalltalk_changer">
          {checked ? t("component.smalltalkSelector.visible") : t("component.smalltalkSelector.hidden")}
        </Label>
      </FormGroup>
    </div>
  );
}
