import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { BiLoaderAlt } from "react-icons/bi";
import { MdAdd, MdPriorityHigh } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Col, Label, Row } from "reactstrap";

import styled from "@emotion/styled";

import useLoading from "~common/hooks/useLoading";
import PRButton from "~components/Generic/PRButton";
import PRChatMessage from "~components/Generic/PRChatMessage";
import { PRTextArea } from "~components/Generic/PRInput";
import PalIconButton from "~components/mui/PalIconButton";
import PalTooltip from "~components/mui/PalTooltip";
import { dialogComponentsRagFactGroupStatus } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import store from "~store";
import {
  createOrUpdateScenarioManager,
  fetchScenarioForm,
  getRagFactGroup,
  postMessageToRag,
  processRag,
  setScenarioFormState,
  stopRag,
} from "~store/dialogComponents/scenarioManager/actions";
import {
  selectScenarioManagerRagFactGroup,
  selectScenarioManagerSamples,
  selectScenarioManagerSaving,
} from "~store/dialogComponents/scenarioManager/selectors";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import useMonacoCollapsibleDecorator from "../useMonacoCollapsibleDecorator";
import useMonacoDoubleNewLineDecorator from "../useMonacoDoubleNewLineDecorator";

const StyledDiv = styled.div`
  #chat-area {
    min-height: 500px;
    max-height: 500px;
    padding: 10px 10px;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-right: 2px solid $color-chat-background;
      border-radius: 0;
    }
  }

  .rag-chat {
    border: 1px solid var(--bs-secondary-400);
    border-radius: 3px;
    padding: 0px 0px 10px 0px;
  }
  .input-area {
    height: 52px;
    padding: 10px 10px;
    /* padding: 0px; */
  }
  .chat-message {
    & > .MuiBadge-root {
      right: 12px;
    }
  }
`;

// const getRagColor = (value) => {
//   return chroma
//     .scale(["#66CC00", "#FFD700", "#FF0000"])(value / 100)
//     .hex();
// };

const useIsRagProcessing = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const ragFactGroup = useSelector(selectScenarioManagerRagFactGroup);

  useEffect(() => {
    if (id && ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processing) {
      const interval = setInterval(async () => {
        const response = await dispatch(getRagFactGroup(currentProject.id, ragFactGroup.id));
        if (response?.status === dialogComponentsRagFactGroupStatus.Processed) {
          clearInterval(interval);
          AlertHelper.showSuccess(t("component.rag.ragProcessedAlert"));
          dispatch(fetchScenarioForm(id));
        }
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [id, ragFactGroup?.status, dispatch, currentProject.id, ragFactGroup?.id, t]);

  return ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processing;
};

function AddToSamplesFooterComponent({ question }) {
  const dispatch = useDispatch();
  const samples = useSelector(selectScenarioManagerSamples);

  const handleClickAddQuestionToSamples = () => {
    const samples = selectScenarioManagerSamples(store.getState());
    dispatch(setScenarioFormState("samples", [...samples, { intent_text: question }]));
  };

  return (
    <div>
      <Row className="gx-2">
        <Col xs={12}>
          <PalTooltip placement="left" title="Add to Samples">
            <PalIconButton
              color="error"
              disabled={samples.some((item) => item.intent_text === question)}
              sx={{
                "&.MuiIconButton-sizeMedium": {
                  width: 32,
                  height: 32,
                  padding: 0,
                },
                // backgroundColor: "var(--bs-gray-100)",
              }}
              onClick={handleClickAddQuestionToSamples}
            >
              <MdAdd />
            </PalIconButton>
          </PalTooltip>
        </Col>
      </Row>
    </div>
  );
}

export default function Rag() {
  const { t } = useTranslation();
  const { id } = useParams();
  useIsRagProcessing();

  const [chatResponseLoading, q] = useLoading();
  const [initRagFactGroup, setInitRagFactGroup] = useState();
  const dispatch = useDispatch();
  const [isPendingForRagTrain, setIsPendingForRagTrain] = useState(false);
  const currentProject = useSelector(selectCurrentProject);
  const ragFactGroup = useSelector(selectScenarioManagerRagFactGroup);
  const scenarioManagerSaving = useSelector(selectScenarioManagerSaving);
  const popupSettings = useSelector(selectPopupSettingsState);
  const [codeEditor, setCodeEditor] = useState(null);

  useEffect(() => {
    setInitRagFactGroup(ragFactGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ragFactGroup?.id]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      messages: [],
    },
  });

  const handleChangeInput = (key) => (e) => {
    const value = e.target?.value;
    // formik.setFieldValue(key, value);

    dispatch(
      setScenarioFormState("rag_fact_group", {
        ...ragFactGroup,
        [key]: value,
      })
    );
  };

  const handleChangeOnMessage = (message, e) => {
    const messages = [...formik.values.messages];

    messages.push({
      type: "text",
      position: "right",
      sender_type: "CUS",
      messageTime: DateHelper.getDateTime(),
      text: message.text,
      isSent: true,
      footerComponent: <AddToSamplesFooterComponent question={message.text} />,
      format: "markdown",
    });

    formik.setFieldValue("messages", [...messages]);
    const receiveResponse = async () => {
      const response = await q(dispatch(postMessageToRag(currentProject.id, ragFactGroup.id, message.text)));

      messages.push({
        type: "text",
        position: "left",
        sender_type: "BOT",
        messageTime: DateHelper.getDateTime(),
        text: response?.output,
        isSent: true,
        format: "markdown",
      });
      formik.setFieldValue("messages", [...messages]);
    };
    //run internal async function to prevent blocking onMessage event to clear input ASAP
    receiveResponse();
  };

  // useEffect(() => {
  //   if (id && ragFactGroup?.id && ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Fresh) {
  //     dispatch(processRag(currentProject.id, ragFactGroup.id))
  //       .then(() => {
  //         dispatch(fetchScenarioForm(id));
  //       })
  //       .finally(() => {
  //         setIsPendingForRagTrain(false);
  //       });
  //   }
  // }, [id, ragFactGroup?.id, dispatch, currentProject.id, ragFactGroup?.status]);

  useEffect(() => {
    if (id && isPendingForRagTrain && !scenarioManagerSaving && currentProject.id && ragFactGroup?.id) {
      setIsPendingForRagTrain(false);
      (async () => {
        await dispatch(processRag(currentProject.id, ragFactGroup.id));
        await dispatch(fetchScenarioForm(id));
      })();
    }
  }, [id, isPendingForRagTrain, dispatch, scenarioManagerSaving, currentProject.id, ragFactGroup?.id]);

  const handleClickTrain = async () => {
    if (!ragFactGroup?.content) return;
    if (!id || initRagFactGroup?.content !== ragFactGroup?.content) {
      await dispatch(createOrUpdateScenarioManager(true)); //true for save only. no redirect to list page
      //save intent first and then queue train after state is updated
      setIsPendingForRagTrain(true);
    } else {
      await dispatch(processRag(currentProject.id, ragFactGroup.id));
      dispatch(fetchScenarioForm(id));
    }
  };

  const handleClickStopTrain = async () => {
    await dispatch(stopRag(currentProject.id, ragFactGroup.id));
    dispatch(fetchScenarioForm(id));
  };

  const isChatHidden = !id;
  useMonacoDoubleNewLineDecorator(codeEditor);
  useMonacoCollapsibleDecorator(codeEditor);
  return (
    <StyledDiv>
      <Row className="gx-2">
        <Col xs={12}>
          <Alert
            className="d-flex align-items-center"
            color="success"
            isOpen={
              initRagFactGroup?.content === ragFactGroup?.content &&
              ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processed
            }
          >
            {t("component.rag.ragReadyAlert")}
          </Alert>
          <Alert
            color="secondary"
            isOpen={
              ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processing ||
              initRagFactGroup?.content !== ragFactGroup?.content ||
              ragFactGroup?.status !== dialogComponentsRagFactGroupStatus.Processed
            }
          >
            {isPendingForRagTrain || ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processing ? (
              <Row className="gx-2 align-items-center">
                <Col xs className="d-flex align-items-center">
                  <BiLoaderAlt className="me-2 spin" />
                  {t("component.rag.ragTrainingAlert")}
                </Col>
                <Col xs="auto">
                  <PRButton
                    color="primary"
                    disabled={scenarioManagerSaving}
                    size="small"
                    onClick={handleClickStopTrain}
                  >
                    {t("component.rag.stopTrain")}
                  </PRButton>
                </Col>
              </Row>
            ) : (
              <div className="d-flex align-items-center justify-content-between">
                <span>
                  <MdPriorityHigh className="me-2 " />
                  {ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processed
                    ? t("component.rag.ragModifiedAlert")
                    : id
                      ? t("component.rag.ragNotProcessedAlert")
                      : t("component.rag.createRagAlert")}
                </span>
                {!!id && (
                  <PRButton color="primary" size="small" onClick={handleClickTrain}>
                    {t("common.train")}
                  </PRButton>
                )}
              </div>
            )}
          </Alert>
        </Col>

        <Col className="mt-2" xs={12} />
        <Col xs={12}>
          <Label>{t("component.rag.prompt")}:</Label>
          <PRTextArea
            editorMode
            editorProps={{
              readOnly: chatResponseLoading || ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processing,
              noResizable: false,
              defaultHeight: ragFactGroup?.prompt ? 350 : 50,
            }}
            name="prompt"
            rows={3}
            value={ragFactGroup?.prompt || ""}
            onChange={handleChangeInput("prompt")}
          />
        </Col>
        <Col
          lg={isChatHidden ? 12 : 7}
          md={isChatHidden ? 12 : 6}
          sm={isChatHidden ? 12 : 12}
          xl={isChatHidden ? 12 : 8}
          xs={12}
        >
          <Label>{t("common.content")}:</Label>
          <PRTextArea
            editorMode
            editorProps={{
              readOnly: chatResponseLoading || ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processing,
              noResizable: true,
              showFoldUnfold: true,
              defaultHeight: 500 + 35 + 10, //height + offset + padding
              onMount: setCodeEditor,

              // customCodeToolbar: (
              //   <Box
              //     sx={{
              //       color: getRagColor(Math.min((ragFactGroup?.content?.length / 50000) * 100, 100)),
              //       mr: "auto",
              //       display: "flex",
              //       alignItems: "center",
              //       width: "100%",
              //       justifyContent: "flex-end",
              //     }}
              //   >
              //     {ragFactGroup?.content?.length > 50000 && <>Content limit exceeded -&nbsp;</>}
              //     {ragFactGroup?.content?.length}/50000
              //   </Box>
              // ),
            }}
            name="content"
            rows={3}
            value={ragFactGroup?.content || ""}
            onChange={handleChangeInput("content")}
          />
        </Col>
        {!isChatHidden && (
          <Col lg={5} md={6} sm={12} xl={4} xs={12}>
            <Label>{t("component.rag.qaChat")}:</Label>
            <div className="rag-chat">
              <PRChatMessage
                noMic
                noReturnToHome
                disableTextInput={
                  chatResponseLoading ||
                  isPendingForRagTrain ||
                  ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processing
                }
                focusOnMount={false}
                focusOnRender={false}
                inputPlaceholder={t("component.rag.inputPlaceholder")}
                messages={formik.values.messages}
                popupStyleInfo={popupSettings.popup_style_info}
                showTyping={chatResponseLoading}
                onMessage={handleChangeOnMessage}
              />
            </div>
          </Col>
        )}
        {/* <Col className="align-items-center justify-content-end d-flex" xs={12}>
          <PRButton className="mt-2 " color="primary" onClick={handleClickUpdateRag}>
            Update RAG
          </PRButton>
        </Col> */}
      </Row>
    </StyledDiv>
  );
}
