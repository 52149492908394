import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import WelcomeImage from "~assets/images/welcome.png";
import { CreateOrUpdateProjectModal } from "~common/modals/CreateOrUpdateProjectModal";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRLink from "~components/Generic/PRLink";
import PRPage from "~components/Generic/PRPage";
import PRSwitch from "~components/Generic/PRSwitch";
import { selectUserInfo } from "~store/user/selectors";

import "./style.scss";

function WelcomePage() {
  const userInfo = useSelector(selectUserInfo);
  const { t } = useTranslation();
  const handleCreateProject = async () => {
    const result = await CreateOrUpdateProjectModal.show();
    if (result) {
      location.reload();
    }
  };
  return (
    <PRContainer className={"pr-welcome"}>
      <PRPage>
        <div className="text-center welcome">
          <h1 className="my-3">{t("dashboard.welcome.title")}</h1>
          <h5>
            {t("dashboard.welcome.descriptionPreLinkText")}{" "}
            <PRLink noPrefix to="mailto:info@palmate.ai">
              {t("dashboard.welcome.descriptionLinkText")}
            </PRLink>{" "}
            {t("dashboard.welcome.descriptionPostLinkText")}
          </h5>

          <div>
            <PRButton
              className="my-5"
              color="primary"
              disabled={userInfo.role_title !== "Admin"}
              size="lg"
              onClick={handleCreateProject}
            >
              {t("dashboard.welcome.createProject")}
            </PRButton>
          </div>
          <img alt="Welcome" src={WelcomeImage} />
        </div>
      </PRPage>
    </PRContainer>
  );
}

export default function Welcome() {
  const routes = [
    { path: "/welcome/", component: WelcomePage },
    { path: "/", to: "/welcome", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}
