class ConstantHelper {
  /**
   * Generates `type`, `typeOptions`, and `typeMap` from an object.
   *
   * @example
   *   const [type, typeOptions, typeMap] = ConstantHelper.typify({
   *     key1: "Key 1 Display Name",
   *     key2: "Key 2 Display Name",
   *   });
   *
   *   console.log(type); // { key1: "key1", key2: "key2" }
   *   console.log(typeOptions); // [{ label: "Key 1 Display Name", value: "key1" }, { label: "Key 2 Display Name", value: "key2" }]
   *   console.log(typeMap); // { key1: "Key 1 Display Name", key2: "Key 2 Display Name" }
   *
   * @template T
   * @param {T} obj Object to be converted to type. The object should have a structure like `{"key": "Key Display
   *   Name"}`.
   * @returns {readonly [{ [K in keyof T]: K }, { label: T[K]; value: keyof T }[], { [K in keyof T]: T[K] }]}
   */
  static typify(obj) {
    const typeMap = {};
    const type = {};
    const typeOptions = [];

    for (const key in obj) {
      typeMap[key] = obj[key];
      type[key] = key;
      typeOptions.push({ label: obj[key], value: key });
    }

    return [type, typeOptions, typeMap];
  }
}

export default ConstantHelper;
