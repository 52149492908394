import { audioList } from "~constants";

import LockHelper from "./LockHelper";

/**
 * @augments Audio
 * @class
 */
class AudioWrapper {
  _audio = null;
  _isPlaying = false;
  _audioPlayPromise = Promise.resolve();
  _isAudioAvailable = false;
  constructor(wav) {
    this._audio = new Audio(wav);
    this._audioPath = wav;
    this._isAudioLoaded = true;
    AudioHelper.isAudioAvailable().then((isAvailable) => {
      this._isAudioAvailable = isAvailable;
    });
    this._audio.addEventListener("ended", () => {
      this._isPlaying = false;
    });
  }
  play = async () => {
    if (this._audio) {
      //Only one audio can be played at a time between multiple tabs in 250ms
      const isAvailable = await LockHelper.acquireLockWithinMs(this._audioPath, 250);
      if (!isAvailable) {
        return;
      }

      try {
        await Promise.allSettled([this._audioPlayPromise]);
        this._isPlaying = true;
        this._audioPlayPromise = this._audio.play();
        await this._audioPlayPromise;
      } catch {}
    }
  };
  replay = async () => {
    if (this._audio) {
      //Only one audio can be played at a time between multiple tabs in 250ms
      const isAvailable = await LockHelper.acquireLockWithinMs(this._audioPath, 250);
      if (!isAvailable) {
        return;
      }

      try {
        await Promise.allSettled([this._audioPlayPromise]);
        this._isPlaying = true;
        this._audio.currentTime = 0;
        this._audioPlayPromise = this._audio.play();
        await this._audioPlayPromise;
      } catch {}
    }
  };

  stop = () => {
    if (this._audio) {
      try {
        this._isPlaying = false;
        this._audio.stop();
      } catch {}
    }
  };
  stopAndReset = async () => {
    if (this._audio) {
      try {
        await Promise.allSettled([this._audioPlayPromise]);
        this._isPlaying = false;
        this._audio.stop();
        this._audio.currentTime = 0;
      } catch {}
    }
  };
  isPlaying = () => {
    return this._isPlaying;
  };
  pause = async () => {
    if (this._audio) {
      try {
        await Promise.allSettled([this._audioPlayPromise]);
        this._isPlaying = false;
        this._audio.pause();
      } catch {}
    }
  };
}
/**
 * Load audio files asynchronicity and store them in the `_audioList`
 *
 * Audio files are loaded only once and stored in the `_audioList` object for later use and also prevent multiple
 * loading for Mac OS Safari
 */
export default class AudioHelper {
  static _audioList = {};

  static async loadRecognitionSound(loadKey = "") {
    if (!this._audioList[`${audioList.recognition}`] && (!loadKey || loadKey === audioList.recognition)) {
      const { default: wav } = await import("~assets/sounds/recognize-start.mp3");
      this._audioList[`${audioList.recognition}`] = new AudioWrapper(wav);
    }
    if (!this._audioList[`${audioList.recognitionError}`] && (!loadKey || loadKey === audioList.recognitionError)) {
      const { default: wav } = await import("~assets/sounds/recognize-error.mp3");
      this._audioList[`${audioList.recognitionError}`] = new AudioWrapper(wav);
    }
    if (!this._audioList[`${audioList.agentWaitingAlert}`] && (!loadKey || loadKey === audioList.agentWaitingAlert)) {
      const { default: wav } = await import("~assets/sounds/agent-waiting-alert.mp3");
      this._audioList[`${audioList.agentWaitingAlert}`] = new AudioWrapper(wav);
    }
    if (!this._audioList[`${audioList.agentNewMessage}`] && (!loadKey || loadKey === audioList.agentNewMessage)) {
      const { default: wav } = await import("~assets/sounds/new-message.mp3");
      this._audioList[`${audioList.agentNewMessage}`] = new AudioWrapper(wav);
    }
    if (!this._audioList[`${audioList.customerNewMessage}`] && (!loadKey || loadKey === audioList.customerNewMessage)) {
      const { default: wav } = await import("~assets/sounds/customer-new-message.mp3");
      this._audioList[`${audioList.customerNewMessage}`] = new AudioWrapper(wav);
    }
    if (!this._audioList[`${audioList.noSound}`] && (!loadKey || loadKey === audioList.noSound)) {
      const { default: wav } = await import("~assets/sounds/no-sound.mp3");
      this._audioList[`${audioList.noSound}`] = new AudioWrapper(wav);
    }
    if (!this._audioList[`${audioList.agentTransferred}`] && (!loadKey || loadKey === audioList.agentTransferred)) {
      const { default: wav } = await import("~assets/sounds/agent-transferred.mp3");
      this._audioList[`${audioList.agentTransferred}`] = new AudioWrapper(wav);
    }
    if (
      !this._audioList[`${audioList.customerAgentJoined}`] &&
      (!loadKey || loadKey === audioList.customerAgentJoined)
    ) {
      const { default: wav } = await import("~assets/sounds/customer-agent-joined.mp3");
      this._audioList[`${audioList.customerAgentJoined}`] = new AudioWrapper(wav);
    }
  }

  /** @param {keyof audioKeyList} key */
  static async get(key) {
    if (!this._audioList[key]) {
      await this.loadRecognitionSound();
    }
    return this._audioList[key];
  }
  static async isMicAvailable() {
    if (!window?.navigator?.mediaDevices) {
      return false;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const tracks = stream.getAudioTracks();
      return tracks.length > 0;
    } catch (error) {
      return false;
    }
  }
  static async isAudioAvailable() {
    if (!window?.HTMLMediaElement) {
      return false;
    }

    const audioElement = new Audio();
    const audioPromise = audioElement.play();

    if (audioPromise !== undefined) {
      try {
        await audioPromise;
        return true;
      } catch (error) {
        return false;
      }
    } else {
      return true;
    }
  }
}
