import "./init.js";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "~App.js";
import ManifestHelper from "~helpers/ManifestHelper.js";

import RenderWrapper from "./components/RenderWrapper";
import store from "./store";

ManifestHelper.ApplyDefaultManifest();

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RenderWrapper>
      <App />
    </RenderWrapper>
  </Provider>
);

document.documentElement.setAttribute("translate", "no");
