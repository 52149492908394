import { useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlChatbot, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteDictionary } from "~store/dialogComponents/dictionaries/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

export default function DictionariesList() {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const dispatch = useDispatch();

  useProjectChange(() => {
    tableRef.current.resetPagination();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        label: t("common.name"),
        key: "key",
      },
      {
        label: t("common.values"),
        key: "values",
        render: (row) => {
          return (
            <>
              {row.values?.map((value) => {
                return (
                  <Badge key={value} className="badge-soft-secondary me-1">
                    <span className="font-size-12">{value}</span>
                  </Badge>
                );
              })}
            </>
          );
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => {
          const handleClickDelete = async () => {
            if (!(await DialogHelper.showQuestionDeleteWithFormat(row.key))) return;
            dispatch(deleteDictionary(currentProject.id, currentBot.id, row.id)).then(() => {
              tableRef.current.refresh();
            });
          };

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                color="primary"
                icon={MdEdit}
                link={`/chatbot/dictionaries/form/${row.id}`}
                size="sm"
                tooltipText={t("common.edit")}
              />
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText={t("common.delete")}
                onClick={handleClickDelete}
              />
            </div>
          );
        },
      },
    ];
  }, [dispatch, currentProject, currentBot, t]);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: `/chatbot/dictionaries/form/`,
      },
    ];
  }, [t]);

  const filters = useMemo(() => {
    return [
      {
        key: "bot",
        visible: false,
      },
      {
        key: "key",
        label: t("common.name"),
      },
    ];
  }, [t]);

  const parentName = [
    {
      label: t("common.dictionaries"),
      url: "/chatbot/dictionaries",
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      actions={actions}
      description={t("dashboard.dictionariesList.description")}
      name={t("common.chatbot")}
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.dictionaryList}
        url={apiUrlChatbot.getDictionary.format(currentProject.id, currentBot.id)}
      />
    </PRContainer>
  );
}
