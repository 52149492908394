import { createRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlOrganization, organizationAnnouncementFilterTypeMap, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteFilter } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";
// import { deleteMemberField, getFilterList } from "~store/organization/actions";

import { filterGetReadableText } from "../FilterUtils";

import "./style.scss";

export default function FilterList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = createRef();

  const currentProject = useSelector(selectCurrentProject);

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDeleteWithFormat(row.name))) return;
      await dispatch(deleteFilter(row.id));
      tableRef.current.refresh();
    };
    return [
      {
        label: t("common.name"),
        key: "name",
        className: "text-nowrap",
      },
      {
        label: t("dashboard.filterTemplates.filterType"),
        key: "filter_type",
        className: "text-nowrap",
        render: (row) => (
          <span>
            {organizationAnnouncementFilterTypeMap[row.filter_type]
              ? t(organizationAnnouncementFilterTypeMap[row.filter_type])
              : row.filter_type}
          </span>
        ),
      },
      {
        label: t("dashboard.filterTemplates.filterRule"),
        key: "filter_rule",
        render: (row) => <span>{filterGetReadableText(row, true)}</span>,
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/organization/filter-templates/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, tableRef, t]);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: "/organization/filter-templates/form",
      },
    ];
  }, []);

  return (
    <PRContainer
      actions={actions}
      className="pr-"
      description={t("dashboard.filterTemplates.description")}
      name={t("common.organization")}
      parentName={t("dashboard.filterTemplates")}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        storageKey={tableFilterStorageKey.filterList}
        url={apiUrlOrganization.getFilter.format(currentProject.id)}
        urlQuery={{ is_template: true }}
      />
    </PRContainer>
  );
}
