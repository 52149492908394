import { detect } from "detect-browser";
import { deleteToken, getMessaging, getToken } from "firebase/messaging";
import { all, call, takeLatest } from "redux-saga/effects";

import { apiUrlNotification } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

const waitLoad = () =>
  new Promise((resolve) => {
    if (document.readyState === "complete") {
      resolve();
      return;
    }
    const loadListener = () => {
      resolve();
      window.removeEventListener("load", loadListener);
    };
    window.addEventListener("load", loadListener);
  });

const registerSw = () => {
  return new Promise((resolve, reject) => {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        resolve(registration);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const unregisterAllSw = async () => {
  const toBeRemoveRegistrations = [];
  if ("serviceWorker" in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      if (registration?.active?.scriptURL?.includes?.("firebase-messaging-sw.js")) {
        toBeRemoveRegistrations.push(registration);
      }
    }
  }

  if (toBeRemoveRegistrations.length > 0) {
    try {
      const messaging = getMessaging();
      await deleteToken(messaging);
    } catch (e) {}
    await Promise.all(toBeRemoveRegistrations.map((registration) => registration.unregister()));
  }

  if ("caches" in window) {
    await Promise.all(["other-file-cache"].map((cacheName) => caches.delete(cacheName)));
  }
};

function* handleRequestPushNotification({ payload }) {
  const vapidKey = window.pr_config?.firebase;
  if (!vapidKey) return console.log("Vapid key is not found");
  //register sw.js
  if (!("serviceWorker" in navigator)) {
    console.log("Service worker is not supported");
    return;
  }

  try {
    yield call(waitLoad);
    // yield call(navigator.serviceWorker.register, "/firebase-messaging-sw.js");

    //check if "/firebase-messaging-sw.js" is registered  or not
    const registration = yield call(registerSw);
    const messaging = getMessaging();
    const browser = detect();
    const normalize = (str) =>
      str
        .replace(/[\s./]/g, "")
        .replace(/-/g, "_")
        .toUpperCase();

    const osType = normalize(browser?.os || "unknown");
    const browserType = normalize(browser?.name || "");

    // const isMobile = ["iOS", "Android OS", "BlackBerry OS", "Windows Mobile", "Amazon OS", "Windows Phone"].includes(
    //   browser.os
    // );

    // const topicEnvTag = import.meta.env.NODE_ENV === "production" ? "production" : "development";
    // messaging.subscribeToTopic([
    //   "general",
    //   `general-${topicEnvTag}`,
    //   `general-${topicEnvTag}-${isMobile ? "mobile" : "web"}"}`,
    //   `general-${isMobile ? "mobile" : "web"}`,
    //   `${osType}`,
    // ]);
    const permissionResult = yield call(Notification.requestPermission);

    if (permissionResult === "granted") {
      console.log("Notification permission granted.");
      try {
        const token = yield call(getToken, messaging, { vapidKey: vapidKey, serviceWorkerRegistration: registration });

        if (token) {
          console.log("FCM Token:", token);

          // Converts ".", " ", "/" to "" and "-" to "_"
          yield call(Network.request, apiUrlNotification.createDevice, {
            method: "POST",
            noAlert: true,
            data: {
              registration_id: token,
              os_type: osType,
              browser_type: browserType,
            },
          });

          //TODO register token to server
        }
      } catch (err) {
        console.log("An error occurred while retrieving token. ", err);
      }
    } else {
      console.log("Unable to get permission to notify.");
    }
  } catch (error) {
    console.log("Service worker registration failed, error:", error);
  }
}

function* handleClearPushNotification({ payload }) {
  yield call(unregisterAllSw);
}
function* handlePushNotification({ payload }) {}
function* handlePushNotificationAction({ payload }) {}

function* scenarioManagerSaga() {
  yield all([
    takeLatest(at.REQUEST_PUSH_NOTIFICATION, handleRequestPushNotification),
    takeLatest(at.CLEAR_PUSH_NOTIFICATION, handleClearPushNotification),
    takeLatest(at.PUSH_NOTIFICATION, handlePushNotification),
    takeLatest(at.PUSH_NOTIFICATION_ACTION, handlePushNotificationAction),
  ]);
}

export default scenarioManagerSaga;
