import { forwardRef } from "react";

import { useSelector } from "react-redux";

import HistoryHelper from "~helpers/HistoryHelper";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

const isCustomProtocol = (url = "") => {
  const protocols = ["ftp", "ftps", "mailto", "tel", "sms", "callto", "webcal"];
  const urlProtocol = url.split(":")[0];
  return protocols.includes(urlProtocol);
};
const PRLink = forwardRef(function PRLink(
  { to, noPrefix, scope = "dashboard", newTab, children, onClick, ...rest },
  ref
) {
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const isCustomProtocolHandler = isCustomProtocol(to);
  const urlPrefix = `${import.meta.env.VITE_APP_PUBLIC_URL}/dashboard/project/${currentProject?.id || 0}/bot/${currentBot?.id || 0}`;
  const navigateUrl = noPrefix || isCustomProtocolHandler ? to : `${urlPrefix}${to}`;

  return (
    <a
      ref={ref}
      {...rest}
      {...(to ? { href: navigateUrl } : { href: "/#" })}
      {...(newTab ? { target: "_blank" } : {})}
      rel="noopener noreferrer"
      onClick={(e) => {
        onClick?.(e);
        if (e.defaultPrevented) return;
        if (newTab) return;
        if (isCustomProtocolHandler) {
          return;
        }
        e.preventDefault();
        if (!to) return;

        if (to === "/") {
          HistoryHelper.push("/home", { scope });
        } else {
          HistoryHelper.push(to, { scope });
        }
      }}
    >
      {children}
    </a>
  );
});
export default PRLink;
