import { cloneDeep } from "lodash";

import Utils from "~helpers/Utils";

export const getSimplifiedResponse = (obj) => {
  const allowedKeys = [
    "text",
    "file_url",
    "image_url",
    "message_type",
    "message_items",
    "message_buttons",
    "button_type",
    "go_message_item",
    "children",
  ];
  return Utils.getWithOnlyAllowedKeys(cloneDeep(obj), allowedKeys);
};
