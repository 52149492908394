import { useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import { MdDelete, MdEdit, MdFileUpload, MdOpenInNew, MdVisibility } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import ImagePreviewModal from "~common/modals/ImagePreviewModal";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlChatbot, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteMedia, updateMedia, uploadMedia } from "~store/dialogComponents/scenarioManager/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

import RenameFileModal from "../RenameFileModal";
import UploadModal from "../UploadModal";

function Media() {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const handleClickUpload = async () => {
    const result = await UploadModal?.show({ title: t("dashboard.media.uploadMedia") });
    if (result?.length) {
      await dispatch(uploadMedia(currentProject.id, currentBot.id, result[0].file, result[0].name));
      tableRef.current.refresh();
      UploadModal?.hide();
    }
  };

  const columns = useMemo(() => {
    const handleClickDelete = (id, row) => async () => {
      if (await DialogHelper.showQuestionDeleteWithFormat(row.name)) {
        await dispatch(deleteMedia(currentProject.id, currentBot.id, id));
        tableRef.current.refresh();
      }
    };

    const handleClickEdit = (row) => async () => {
      const result = await RenameFileModal?.show({ title: t("dashboard.media.editMedia"), name: row.name });
      if (result) {
        await dispatch(updateMedia(currentProject.id, currentBot.id, { id: row.id, name: result }));
        tableRef.current.refresh();
        RenameFileModal?.hide();
      }
    };

    const handleClickViewImage = (row) => async () => {
      ImagePreviewModal.show({ image: row.media_url, title: row.name });
    };

    return [
      {
        label: t("common.name"),
        key: "name",
      },
      // {
      //   label: "URL",
      //   key: "media_url",
      //   render: (row) => {
      //     //parse URL replace origin with existing origin

      //     const url = new URL(row.media_url);
      //     const newUrl = `${window.location.origin}${url.pathname}`;

      //     return (
      //       <div className="text-truncate">
      //         <PRTooltip title={newUrl}>
      //           <a href={newUrl} target="_blank" rel="noreferrer">
      //             {Utils.ellipsisUrl(newUrl)}
      //           </a>
      //         </PRTooltip>
      //       </div>
      //     );
      //   },
      // },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => {
          const url = new URL(row.media_url);
          const newUrl = `${window.location.origin}${url.pathname}`;
          const extension = row.media_url.split(".").pop();
          const isPreviewAvailable = ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(extension.toLowerCase());
          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                className="ms-1"
                color="secondary"
                icon={MdOpenInNew}
                link={newUrl}
                linkProps={{ newTab: true, noPrefix: true }}
                size="sm"
                tooltipText={t("common.openInNewTab")}
              />
              <PRButton
                outline
                className="ms-1"
                color="secondary"
                disabled={!isPreviewAvailable}
                icon={MdVisibility}
                size="sm"
                tooltipText={t("common.view")}
                onClick={handleClickViewImage(row)}
              />
              <PRButton
                outline
                className="ms-1"
                color="primary"
                icon={MdEdit}
                size="sm"
                tooltipText={t("common.edit")}
                onClick={handleClickEdit(row)}
              />
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText={t("common.delete")}
                onClick={handleClickDelete(row.id, row)}
              />
            </div>
          );
        },
      },
    ];
  }, [dispatch, currentProject.id, currentBot.id, t]);

  const actions = [
    {
      label: t("dashboard.media.uploadMedia"),
      icon: MdFileUpload,
      color: "success",
      onClick: handleClickUpload,
    },
  ];

  const parentName = [
    {
      label: t("common.media"),
      url: "/chatbot/media/",
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      actions={actions}
      description={t("dashboard.media.description")}
      name={t("common.chatbot")}
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        storageKey={tableFilterStorageKey.mediaList}
        url={apiUrlChatbot.getMedia.format(currentProject.id, currentBot.id)}
      />
    </PRContainer>
  );
}

export default Media;
