import { useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label, Modal } from "reactstrap";

import PRButton from "~components/Generic/PRButton";
import { defaultModalZIndex } from "~components/Generic/PRModal";
import AlertHelper from "~helpers/AlertHelper";
import { createOrUpdateIntentTag } from "~store/dialogComponents/intentTags/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

function CreateIntentTag({ get }) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleCreate = async () => {
    setLoading(true);
    let result;
    try {
      result = await dispatch(createOrUpdateIntentTag(currentProject.id, currentBot.id, { key: value }));
      AlertHelper.show(t("network.success"), "success");
    } catch (e) {
      AlertHelper.show(t("network.serverError"), "error");
      console.error(e);
    }
    setLoading(false);
    get(result)();
  };
  return (
    <Modal centered isOpen={true} toggle={get(false)} zIndex={defaultModalZIndex}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Create Intent Tag</h5>
        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={get(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Label htmlFor="slot-validation-text">Intent tag:</Label>
        <Input
          className="form-control"
          name="slotValidationText"
          placeholder="Enter your intent tag"
          type="text"
          value={value}
          onChange={handleChange}
        />
      </div>
      <div className="modal-footer">
        <PRButton outline color="primary" data-dismiss="modal" onClick={get(false)}>
          Close
        </PRButton>
        <PRButton color="primary" loading={loading} type="button" onClick={handleCreate}>
          Create
        </PRButton>
      </div>
    </Modal>
  );
}
const CreateIntentTagModal = withCardon(CreateIntentTag, { destroyOnHide: true });
export default CreateIntentTagModal;
