import { useEffect, useState, useRef, useCallback } from "react";

import { useMonaco } from "@palamar/fe-library";
import { useSelector } from "react-redux";

import useCopyPasteHistory from "~common/hooks/useCopyPasteHistory";
import useLoading from "~common/hooks/useLoading";
import { apiUrlLowcode } from "~constants";
import Network from "~helpers/Network";
import { selectCurrentProject } from "~store/user/selectors";

const OPENING_BRACKETS = ["(", "[", "{"];
const CLOSING_BRACKETS = [")", "]", "}"];
const QUOTES = ['"', "'", "`"];
export const ALL_BRACKETS = [...OPENING_BRACKETS, ...CLOSING_BRACKETS];

/*

Task: Code Completion

Language: {language}

Instructions:

- You are a world class coding assistant.
- Based on the given context, including the current code, user's cursor position, relevant files, and the user's intent, provide a code completion suggestion.
- The completion must respect the current indentation and style of the code.
- Ensure the completion is syntactically correct and follows best practices for the specified language.
- Return only valid code snippets. Do not include any natural language or explanatory text in the response.
- The suggestion must start exactly at the cursor position, without any extra characters or explanations.
- **For Python specifically:**
  - Maintain correct indentation based on the current code context.
  - If the cursor is inside a function or a code block, ensure that the suggested code is properly indented as part of that block, not at the root level.
  - The completion must align with the current cursor's indentation level within the existing code structure.
- **Multiline Code Completion:**
  - Do not limit the completion to a single line. If the context or the task requires it, generate a multiline completion.
  - Ensure that the generated code is cohesive and fully functional, whether it's a single line or multiple lines.
   


IMPORTANT NOTES:
- NEVER INCLUDE ANY MARKDOWN IN THE RESPONSE - THIS MEANS CODEBLOCKS AS WELL.
- Never include any annotations such as "Suggestion:" or "Suggestions:".
- Newlines should be included after any of the following characters: "{", "[", "(", ")", "]", "}", and ",".
- Ensure that newline suggestions follow the same indentation as the current line.
- The suggestion must start exactly at the cursor position, without any extra characters before it.
- Only ever return the code snippet, do not return any markdown unless it is part of the code snippet.
- Do not return any code that is already present in the current text.
- Do not return anything that is not valid code.
- If you do not have a suggestion, return an empty string.
- If there is Context Data related to the Context Information field, take this into consideration.


Context Information:
**Current File:**
 - The current file being edited, including the code before and after the cursor.

**Cursor Position:**
 - The exact position of the user's cursor in the file.

**Code Before Cursor:**
 - The code on the same line immediately before the cursor.

**Code After Cursor:**
 - The code on the same line immediately after the cursor.
 
**Selected Code:**
 - The code that is currently selected in the editor by the user.

**Copy-Paste History:**
 - The last few code snippets that were copied and pasted by the user. These snippets might indicate the user's current task or a pattern they are following.

**User’s Previous Interactions:**
 - The user's recent commands, requests, or code snippets that were previously generated. This helps to understand the user's intent and the direction of the current task.

**Open Files:**
 - The code in other files that are currently open in the user's editor. This can include relevant configurations, class definitions, or functions that are being referenced in the current file.

**Project-Specific Settings:**
  - Any settings or configurations specific to the current project, such as linter rules, style guides, or specific coding conventions that should be followed.

**Documentation:**
  - Relevant documentation snippets or docstrings associated with the current function, method, or class being edited. This helps ensure that the completion aligns with the documented behavior. This might include intellisense documentation or comments within the code.

**User Intent:**
  - If the user has explicitly requested a specific task or functionality (e.g., "Optimize this function for performance", "Add error handling", etc.), that should guide the completion.

**Relevant Information:**
  - Any other relevant information that can help in providing a more accurate code completion suggestion.
  - This could include specific libraries or frameworks being used, the purpose of the code snippet, or any constraints that need to be considered.

Context Data:

**Documentation:**

Intellisense Documentation Data
```json
{documentation}
```

@=====@

{dynamic_context}


*/

function codeBlockWithLanguage(text, language) {
  return "```" + language + "\n" + text + "\n```";
}

function createCompletionContext(contextName, contextData) {
  return `**${contextName}:**\n${contextData}`;
}

class CompletionFormatter {
  constructor(monaco, editor, position) {
    this._editor = editor;
    this._monaco = monaco;
    this._cursorPosition = position;

    const lineEndPosition = editor.getFullModelRange()?.getEndPosition();
    const textAfterRange = new this._monaco.Range(
      this._cursorPosition.lineNumber,
      this._cursorPosition.column,
      lineEndPosition?.lineNumber ?? 1,
      lineEndPosition?.column ?? 1
    );
    this._lineText = editor.getLineContent(this._cursorPosition.lineNumber);
    this._textAfterCursor = editor.getValueInRange(textAfterRange);
    this._characterBeforeCursor = this._lineText[this._cursorPosition.column - 2] ?? "";
    this._characterAfterCursor = this._lineText[this._cursorPosition.column] ?? "";
    this._lineCount = editor.getLineCount();
  }

  isMatchingPair(open, close) {
    return (
      (open === "(" && close === ")") ||
      (open === "[" && close === "]") ||
      (open === "{" && close === "}") ||
      (open === '"' && close === '"') ||
      (open === "'" && close === "'")
    );
  }

  matchCompletionBrackets() {
    let accumulatedCompletion = "";
    const openBrackets = [];
    for (const character of this._originalCompletion) {
      if (OPENING_BRACKETS.includes(character)) {
        openBrackets.push(character);
      }
      if (CLOSING_BRACKETS.includes(character)) {
        if (openBrackets.length && this.isMatchingPair(openBrackets[openBrackets.length - 1], character)) {
          openBrackets.pop();
        } else {
          break;
        }
      }
      accumulatedCompletion += character;
    }

    this._completion = accumulatedCompletion.trimEnd() || this._originalCompletion.trimEnd();
    return this;
  }

  ignoreBlankLines() {
    if (this._completion.trimStart() === "" && this._originalCompletion !== "\n") {
      this._completion = this._completion.trim();
    }
    return this;
  }

  normalise(text) {
    return text?.trim();
  }

  removeDuplicateStartOfSuggestions() {
    const before = this._editor
      .getValueInRange(new this._monaco.Range(1, 1, this._cursorPosition.lineNumber, this._cursorPosition.column))
      .trim();

    const completion = this.normalise(this._completion);

    const maxLength = Math.min(completion.length, before.length);
    let overlapLength = 0;

    for (let length = 1; length <= maxLength; length++) {
      const endOfBefore = before.substring(before.length - length);
      const startOfCompletion = completion.substring(0, length);
      if (endOfBefore === startOfCompletion) {
        overlapLength = length;
      }
    }

    if (overlapLength > 0) {
      this._completion = this._completion.substring(overlapLength);
    }

    return this;
  }

  isCursorAtMiddleOfWord() {
    return (
      this._characterBeforeCursor && /\w/.test(this._characterBeforeCursor) && /\w/.test(this._characterAfterCursor)
    );
  }

  removeUnnecessaryMiddleQuote() {
    const startsWithQuote = QUOTES.includes(this._completion[0] ?? "");
    const endsWithQuote = QUOTES.includes(this._completion[this._completion.length - 1] ?? "");

    if (startsWithQuote && endsWithQuote) {
      this._completion = this._completion.substring(1);
    }

    if (endsWithQuote && this.isCursorAtMiddleOfWord()) {
      this._completion = this._completion.slice(0, -1);
    }

    return this;
  }

  preventDuplicateLines() {
    let nextLineIndex = this._cursorPosition.lineNumber + 1;
    while (nextLineIndex < this._cursorPosition.lineNumber + 3 && nextLineIndex < this._lineCount) {
      const line = this._editor.getLineContent(nextLineIndex);
      if (this.normalise(line) === this.normalise(this._originalCompletion)) {
        this._completion = "";
        return this;
      }
      nextLineIndex++;
    }
    return this;
  }

  removeInvalidLineBreaks() {
    if (this._completion.endsWith("\n")) {
      this._completion = this._completion.trimEnd();
    }
    return this;
  }

  newLineCount() {
    return this._completion.match(/\n/g) || [];
  }

  getLastLineColumnCount() {
    const lines = this._completion.split("\n");
    return lines[lines.length - 1].length;
  }

  trimStart() {
    const firstNonSpaceIndex = this._completion.search(/\S/);

    if (firstNonSpaceIndex > this._cursorPosition.column - 1) {
      this._completion = this._completion.substring(firstNonSpaceIndex);
    }

    return this;
  }

  stripMarkdownAndSuggestionText() {
    this._completion = this._completion.replace(/```.*\n/g, "");
    this._completion = this._completion.replace(/```/g, "");
    this._completion = this._completion.replace(/`/g, "");
    this._completion = this._completion.replace(/# ?Suggestions?: ?/g, "");

    return this;
  }

  getNoTextBeforeOrAfter() {
    const textAfter = this._textAfterCursor;
    const textBeforeRange = new this._monaco.Range(1, 1, this._cursorPosition.lineNumber, this._cursorPosition.column);
    const textBefore = this._editor.getValueInRange(textBeforeRange);
    return !textAfter || !textBefore;
  }

  ignoreContextCompletionAtStartOrEnd() {
    const isNoTextBeforeOrAfter = this.getNoTextBeforeOrAfter();

    const contextMatch = this._normalisedCompletion.match(/\/\*\s*Language:\s*(.*)\s*\*\//);
    const extensionContext = this._normalisedCompletion.match(/\/\*\s*File extension:\s*(.*)\s*\*\//);
    const commentMatch = this._normalisedCompletion.match(/\/\*\s*\*\//);

    if (isNoTextBeforeOrAfter && (contextMatch || extensionContext || commentMatch)) {
      this._completion = "";
    }

    return this;
  }

  formatCompletion(range) {
    const newLineCount = this.newLineCount();
    const getLastLineLength = this.getLastLineColumnCount();
    return {
      insertText: this._completion,
      range: {
        startLineNumber: this._cursorPosition.lineNumber,
        startColumn: this._cursorPosition.column,
        endLineNumber: this._cursorPosition.lineNumber + newLineCount.length,
        endColumn:
          this._cursorPosition.lineNumber === range.startLineNumber && newLineCount.length === 0
            ? this._cursorPosition.column + getLastLineLength
            : getLastLineLength,
      },
    };
  }

  format(insertText, range) {
    this._completion = "";
    this._normalisedCompletion = this.normalise(insertText);
    this._originalCompletion = insertText;
    const result = this.matchCompletionBrackets()
      .ignoreBlankLines()
      .removeDuplicateStartOfSuggestions()
      .removeUnnecessaryMiddleQuote()
      .preventDuplicateLines()
      .removeInvalidLineBreaks()
      .trimStart()
      .stripMarkdownAndSuggestionText()
      .ignoreContextCompletionAtStartOrEnd()
      .formatCompletion(range);
    return result;
  }
}

function removeWrappedCode(text = "") {
  const start = text.indexOf("```");
  const end = text.lastIndexOf("```");
  if (start === -1 || end === -1) return text;
  const regex = /```.*?\n(?<code>.*?)\n```/s;
  const code = text.match(regex)?.groups?.code;
  if (code) {
    return code;
  }
  return text;
}

const defaultProps = {
  language: "python",
  cacheSize: 10,
  getRelevantContext: () => "",
  onLoading: (_status) => {},
};

const useMonacoCodeCompletion = (
  /** @type {import("monaco-editor").editor.IStandaloneCodeEditor} */
  editor,
  options
) => {
  const { onLoading, language, cacheSize, getRelevantContext } = { ...defaultProps, ...options };
  const monaco = useMonaco();
  const [cachedSuggestions, setCachedSuggestions] = useState([]);
  const requestCancellationRef = useRef(new AbortController());
  const currentProject = useSelector(selectCurrentProject);
  const copyPasteHistory = useCopyPasteHistory(15);
  const [userInstructionsHistory, setUserInstructionsHistory] = useState([]);
  const [loading, q] = useLoading();

  const addUserInstructions = useCallback((instructions) => {
    setUserInstructionsHistory((prev) => {
      const updatedHistory = [instructions, ...prev];
      return updatedHistory.slice(0, 10);
    });
  }, []);

  const complete = useCallback(
    async (body) => {
      if (!requestCancellationRef.current?.signal?.aborted) {
        requestCancellationRef.current.abort("AI completion request cancelled");
      }
      requestCancellationRef.current = new AbortController();

      try {
        onLoading(true);
        const response = await q(
          Network.request(apiUrlLowcode.getComplete.format(currentProject?.id), {
            method: "POST",
            data: body,
            signal: requestCancellationRef.current.signal,
          })
        );

        let code = response || "";
        code = removeWrappedCode(code);
        return code;
      } catch (error) {
        if (error.name === "AbortError") {
          return "";
        }
        console.error("Completion fetch error:", error);
        return "";
      } finally {
        onLoading(false);
      }
    },
    [requestCancellationRef, currentProject?.id, q]
  );

  const triggerCompletion = useCallback(
    async (userInstructions) => {
      if (!editor) return;

      const model =
        editor.getEditorType() === "vs.editor.IDiffEditor" ? editor.getModifiedEditor().getModel() : editor.getModel();

      if (!model || !model.getValue()) return;

      const promptContextList = [];

      const position = editor.getPosition();

      const currentLineContent = model.getLineContent(position.lineNumber);
      const codeBeforeCursor = currentLineContent.slice(0, position.column - 1);
      const codeAfterCursor = currentLineContent.slice(position.column - 1);
      const offset = model.getOffsetAt(position);
      const codePreviousLinesBeforeCursor = model.getValue().substring(0, offset - currentLineContent.length);

      // const codeBeforeCursorAllLines = model.getValueInRange({
      //   startLineNumber: 1,
      //   startColumn: 1,
      //   endLineNumber: position.lineNumber,
      //   endColumn: position.column,
      // });

      const selectedText = model.getValueInRange(editor.getSelection());
      const fullCode = model.getValue();

      promptContextList.push(createCompletionContext("Current File", codeBlockWithLanguage(fullCode, language)));
      const cursorPosition = `Line ${position.lineNumber}, Column ${position.column}`;
      promptContextList.push(createCompletionContext("Cursor Position", cursorPosition));

      if (codeBeforeCursor?.length > 0) {
        promptContextList.push(
          createCompletionContext("Code Before Cursor", codeBlockWithLanguage(codeBeforeCursor, language))
        );
      }

      if (codeAfterCursor?.length > 0) {
        promptContextList.push(
          createCompletionContext("Code After Cursor", codeBlockWithLanguage(codeAfterCursor, language))
        );
      }

      if (selectedText?.length) {
        promptContextList.push(createCompletionContext("Selected Code", codeBlockWithLanguage(selectedText, language)));
      }

      if (userInstructions?.length > 0) {
        promptContextList.push(createCompletionContext("User Intent", userInstructions));
        addUserInstructions(userInstructions);
      }
      if (copyPasteHistory.length > 0) {
        promptContextList.push(
          createCompletionContext("Copy-Paste History", codeBlockWithLanguage(JSON.stringify(copyPasteHistory), "json"))
        );
      }

      if (userInstructionsHistory.length > 0) {
        promptContextList.push(
          createCompletionContext(
            "User’s Previous Interactions",
            codeBlockWithLanguage(
              userInstructionsHistory.map((instruction) => `- "${instruction}"`).join("\n"),
              "plaintext"
            )
          )
        );
      }

      const relevantContext = getRelevantContext?.();
      if (relevantContext) {
        promptContextList.push(createCompletionContext("Relevant Information", relevantContext));
      }

      //get all active editors
      const activeEditors = monaco.editor.getModels().map((model) => model.uri.path);

      const openFiles = activeEditors.filter((path) => path !== model.uri.path);

      if (openFiles.length > 0) {
        promptContextList.push(
          createCompletionContext("Open Files", codeBlockWithLanguage(openFiles.join("\n"), "plaintext"))
        );
      }

      const newCompletion = await complete({
        dynamic_context: promptContextList.join("\n\n"),
        language,
      });
      if (newCompletion) {
        const lines = newCompletion.split(/\r?\n/);
        const newSuggestion = {
          insertText: newCompletion,
          range: {
            startLineNumber: position.lineNumber,
            startColumn: position.column,
            endLineNumber: position.lineNumber + lines.length,
            endColumn: lines[lines.length - 1].length + 1,
          },
          textBeforeCursorOnCurrentLine: codeBeforeCursor,
          textBeforeCursor: codePreviousLinesBeforeCursor,
          SelectedCode: selectedText,
          selection: editor.getSelection(),
        };

        if (userInstructions?.length) {
          //instant apply the completion if userInstructions is provided
          editor.executeEdits("inlineCompletion", [
            {
              range: newSuggestion.range,
              text: newSuggestion.insertText,
            },
          ]);
        } else {
          setCachedSuggestions((prev) => [...prev.slice(-cacheSize + 1), newSuggestion]);
        }
      }
    },
    [editor, complete, language, cacheSize, monaco, copyPasteHistory, userInstructionsHistory, addUserInstructions]
  );

  useEffect(() => {
    if (!editor || !monaco) return;

    const model =
      editor.getEditorType() === "vs.editor.IDiffEditor" ? editor.getModifiedEditor().getModel() : editor.getModel();

    const modelLanguage = model.getLanguageId();
    if (modelLanguage !== "python") return;
    const disposableList = [];

    let disposable;
    disposable = editor.addAction({
      id: "triggerAIInlineSuggest",
      label: "Trigger AI completion",
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyB],
      contextMenuGroupId: "AICompletion",
      run: () => {
        triggerCompletion();
      },
    });
    disposableList.push(disposable);

    disposable = editor.addAction({
      id: "triggerAIInlineSuggestWithInstructions",
      label: "Trigger AI completion with instructions",
      contextMenuGroupId: "AICompletion",
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyI],
      run: () => {
        const userInstructions = prompt("Enter instructions for the AI:");
        if (userInstructions) {
          triggerCompletion(userInstructions);
        }
      },
    });
    disposableList.push(disposable);

    return () => {
      disposableList.forEach((disposable) => disposable.dispose());
    };
  }, [editor, monaco, triggerCompletion, language]);

  useEffect(() => {
    if (!monaco || !editor) return;

    const model =
      editor.getEditorType() === "vs.editor.IDiffEditor" ? editor.getModifiedEditor().getModel() : editor.getModel();
    const modelLanguage = model?.getLanguageId();
    if (modelLanguage !== "python") return;

    const provider = monaco.languages.registerInlineCompletionsProvider(language, {
      provideInlineCompletions: async (model, position, context, token) => {
        // if (context.triggerKind !== monaco.languages.CompletionTriggerKind.TriggerCharacter) {
        //   //allow only manual triggers
        //   return { items: [] };
        // }

        if (token.isCancellationRequested) {
          return { items: [] };
        }
        const textBeforeCursorOnCurrentLine = model
          .getLineContent(position.lineNumber)
          .substring(0, position.column - 1);

        const textBeforeCursor = model
          .getValue()
          .substring(0, model.getOffsetAt(position) - textBeforeCursorOnCurrentLine.length);

        let localSuggestions = cachedSuggestions.filter((suggestion) => {
          if (
            suggestion.range.startLineNumber !== position.lineNumber ||
            suggestion.textBeforeCursor !== textBeforeCursor
          )
            return false;

          if (textBeforeCursorOnCurrentLine.startsWith(suggestion.textBeforeCursorOnCurrentLine)) {
            const restText = textBeforeCursorOnCurrentLine.substring(suggestion.textBeforeCursorOnCurrentLine.length);
            return suggestion.insertText.startsWith(restText);
          }
          return false;
        });

        // let latest suggestion at the top
        localSuggestions.reverse();
        return {
          items: localSuggestions.map((suggestion) => {
            const formatter = new CompletionFormatter(monaco, model, position);
            const formattedCompletion = formatter.format(suggestion.insertText, suggestion.range);
            formattedCompletion.range = {
              ...formattedCompletion.range,
              endLineNumber: formattedCompletion.range.startLineNumber,
              endColumn: formattedCompletion.range.startColumn,
            };

            if (suggestion.selection) {
              formattedCompletion.range = {
                ...formattedCompletion.range,
                startLineNumber: suggestion.selection.startLineNumber,
                startColumn: suggestion.selection.startColumn,
                endLineNumber: suggestion.selection.endLineNumber,
                endColumn: suggestion.selection.endColumn,
              };
            }
            return formattedCompletion;
          }),
        };
      },
      freeInlineCompletions: () => {},
    });

    editor.trigger("anyString", "editor.action.inlineSuggest.trigger", {});

    return () => {
      provider.dispose();
    };
  }, [monaco, cachedSuggestions, language, editor]);

  return { loading };
};

export default useMonacoCodeCompletion;
