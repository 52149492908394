import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import styled from "@emotion/styled";

import { ReactComponent as BrokenFileSvg } from "~assets/images/webchat/broken-file.svg";
import ImagePreviewModal from "~common/modals/ImagePreviewModal";
import PRImg from "~components/Generic/PRImg";

import { usePRRefContext } from "../../context";
import SimpleMessage from "../SimpleMessage";

import "./style.scss";

const StyledImageContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: auto;
  min-height: 50px;
  max-height: 70vh;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 0px #0000000a;
  border: 1px solid #e8e8e8;
  border-radius: 14px 14px 14px 0px;
  opacity: 1;
  overflow: hidden;
  user-select: none;
  cursor: ${(props) => (props.isBroken ? "default" : "pointer")};
  img {
    height: auto;
    //stretch width
    width: 100%;
    //align center image
    object-fit: contain;
  }
  .pr-img-broken-placeholder {
    width: 100%;
  }

  /* color: ${(props) => (props.position === "right" ? props.theme.palette.primary.contrastText : "inherit")}; */
`;

const BrokenDiv = styled.div`
  text-align: left;
  .broken-container {
    width: 50px;
    height: 58px;
    padding: 8px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
  }
  svg {
    width: auto;
    height: 42px;
    transform: scale(1.2);
  }
  .broken-text {
    margin-top: 15px;
    height: 22px;
    font: normal normal normal 16px/18px Museo Sans;
    letter-spacing: 0px;
    /* color: #000000b3; */
    /* color: ${(props) => (props.position === "right" ? props.theme.palette.primary.contrastText : "inherit")}; */
  }
`;

export default function ImageMessage({
  plainView,
  format,
  position,
  messageTime,
  isSent,
  messageId,
  likeStatus,
  historyId,
  isLast,
  className,
  isRag,
  files,
}) {
  const Wrapper = plainView ? ({ children }) => children : SimpleMessage;

  return (
    <Wrapper
      isImage
      format={format}
      historyId={historyId}
      isLast={isLast}
      isRag={isRag}
      isSent={isSent}
      likeStatus={likeStatus}
      messageId={messageId}
      messageTime={messageTime}
      position={position}
    >
      {files?.map((file, index) => (
        <SingleImageContent
          key={`file-image-${index}`}
          className={className}
          position={position}
          src={file.url || file.file_url}
        />
      ))}
    </Wrapper>
  );
}

const SingleImageContent = ({ src, position, className }) => {
  const [isBroken, setIsBroken] = useState(false);
  const imgRef = useRef(null);

  const { t } = useTranslation();
  const distributedRef = usePRRefContext();
  const tempImgWidth = useRef(0);

  const handleClickImagePreview = async () => {
    distributedRef.current.disableScrollOnMessage = true;
    tempImgWidth.current = imgRef.current.offsetWidth;

    // Show image asynchronously until close button is clicked
    await ImagePreviewModal.show({ image: src });

    setTimeout(() => {
      distributedRef.current.disableScrollOnMessage = false;
    }, 100);
  };
  useEffect(() => {
    setIsBroken(false);
  }, [src]);

  const handleBroken = (e) => {
    setIsBroken(true);
    console.warn("An error occurred while loading the image", src, e);
  };

  return isBroken ? (
    <BrokenDiv position={position}>
      <div className="broken-container">
        <BrokenFileSvg />
      </div>
      <div className="broken-text">{t("chatbot.not-found")}</div>
    </BrokenDiv>
  ) : (
    <StyledImageContainer className={className} isBroken={isBroken} position={position}>
      <PRImg
        ref={imgRef}
        showBrokenPlaceholder
        showTransparentBackground
        src={src}
        onBroken={handleBroken}
        onClick={handleClickImagePreview}
      />
    </StyledImageContainer>
  );
};
