import { useEffect, useState } from "react";

import { PalButton, PalCodeField, PalIconButton, PalTextField, PalTooltip, PalTypography } from "@palamar/fe-library";
import { withCardon } from "cardon";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import { Code, Send } from "@mui/icons-material";
import { Alert, Box, Grid, styled } from "@mui/material";

import productImage1 from "~assets/images/demo-product/1.webp";
import productImage2 from "~assets/images/demo-product/2.webp";
import productImage3 from "~assets/images/demo-product/3.webp";
import useQueryParams from "~common/hooks/useQueryParams";
import PRModal from "~components/Generic/PRModal";
import AlertHelper from "~helpers/AlertHelper";
import StyledHelper from "~helpers/StyledHelper";

import { scriptTemplate } from "./ChatBot/TryChatbot";

const StyledBox = styled(Box, StyledHelper.sfw("fullHeight"))`
  /* z-index: -2; */
  background-color: #f5f5f5;
  position: relative;
  width: 100%;
  height: 100%;
  animation: animateBg 21s linear infinite;
  background-image: linear-gradient(
    90deg,
    #cf5c5c44,
    #c19b4a44,
    #def2a044,
    #c6ee4a44,
    #42eca644,
    #64b3d944,
    #208ea244,
    #498ada44,
    #5b73df44,
    #897ed344,
    #cf5c5c44,
    #c19b4a44
  );
  background-size: 1100% 100%;

  @keyframes animateBg {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 0%;
    }
  }
`;

const PreviewLabel = styled(PalTypography)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.0375;
  font-size: min(6rem, 15vw);
  white-space: nowrap;
  /* z-index: -1; */
`;

const Card = styled(Box)`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-width: 300px;
  max-width: 800px;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  z-index: 1;
`;

const ImgBox = styled("img")`
  max-width: 100%;
  height: 300px;
`;

const TestButton1 = styled(PalButton)`
  margin-top: 8px;
`;

const HeaderToolbar = styled(Box)`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 16px;
  height: 36px;
  background-color: rgb(194 194 194);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px 5px;
`;
function textWithoutNulls(text) {
  return text === "null" || text === "undefined" ? "" : text;
}
const EmbeddedCodeEditorModal = withCardon(
  function EmbeddedCodeEditorModalContent({ state, get, onClose }) {
    const { t } = useTranslation();
    const [code, setCode] = useState(state || "");
    const handleSubmit = () => {
      get(code)();
    };

    const handleChangeCode = (e) => {
      setCode(e.target.value);
    };
    return (
      <PRModal
        size="xl"
        submitText={t("common.update")}
        title={t("component.chatbotEmbeddedPreview.codeEditorModal.title")}
        onClick={handleSubmit}
        onClose={get(false)}
      >
        <PalCodeField
          defaultHeight={800}
          //   excludeFormatList={[PalCodeFieldFormat.markdown, PalCodeFieldFormat.plain]}
          //   format={PalCodeFieldFormat.html}
          editorProps={{
            language: "html",
            defaultLanguage: "html",
          }}
          value={code}
          onChange={handleChangeCode}
        />
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

const predefinedFunctionCallCode = `

// PREDEFINED TEST FUNCTIONS
_pproject.push([
  "fn_getSearchInput",
  (arg1) => {
    const currValue = document.getElementById("search-bar").value;
    document.getElementById("search-bar").value = currValue + ", " + arg1;
    return currValue;
  },
]);

_pproject.push([
  "fn_getBrowserInfo",
  () => {
    const data = {
      userAgent: navigator.userAgent,
      language: navigator.language,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      title: document.title,
      url: window.location.href,
      browserWidth: window.innerWidth,
      browserHeight: window.innerHeight,
      referrer: document.referrer,
      platform: navigator.platform,
      vendor: navigator.vendor,
      zoom: window.devicePixelRatio,
      maxTouchPoints: navigator.maxTouchPoints,
      cookieEnabled: navigator.cookieEnabled,
      doNotTrack: navigator.doNotTrack,
      connectionType: navigator.connection.effectiveType,
    };
    return data;
  }
]);
// END OF PREDEFINED TEST FUNCTIONS

`;

const ChatbotSendEventModal = withCardon(
  function ChatbotSendEventModalContent({ get, onClose }) {
    const [eventName, setEventName] = useState("");
    const [eventData, setEventData] = useState("");
    const [isDataJson, setIsDataJson] = useState(false);
    const { t } = useTranslation();

    const handleChangeEventName = (e) => {
      setEventName(e.target.value);
    };

    const handleChangeEventData = (e) => {
      setEventData(e.target.value);
      try {
        JSON.parse(e.target.value);
        setIsDataJson(true);
      } catch {
        setIsDataJson(false);
      }
    };

    const handleClick = () => {
      let jsonData;
      try {
        jsonData = JSON.parse(eventData);
      } catch {}
      window.palActions.sendEvent(eventName, jsonData ?? eventData);
    };

    return (
      <PRModal
        size="md"
        submitText={""}
        title={t("component.chatbotEmbeddedPreview.sendEventModal.title")}
        onClose={get(false)}
      >
        {isDataJson && (
          <Alert
            severity="info"
            sx={{
              width: "100%",
              mb: 2,
            }}
          >
            {t("component.chatbotEmbeddedPreview.sendEventModal.jsonDataInfo")}
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PalTextField
              fullWidth
              label={t("component.chatbotEmbeddedPreview.sendEventModal.eventName")}
              value={eventName}
              onChange={handleChangeEventName}
            />
          </Grid>
          <Grid item xs={12}>
            <PalTextField
              fullWidth
              multiline
              label={t("component.chatbotEmbeddedPreview.sendEventModal.eventData")}
              maxRows={6}
              minRows={2}
              value={eventData}
              onChange={handleChangeEventData}
            />
          </Grid>
          <Grid item xs={12}>
            <PalButton fullWidth color="primary" variant="contained" onClick={handleClick}>
              {t("common.send")}
            </PalButton>
          </Grid>
        </Grid>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

const useHoverSender = (func) => {
  const [hoverStates, setHoverStates] = useState({});
  const hoverFlags = {};

  const startHoverTimer = (name) => (element) => {
    if (!element) return;

    let timer;
    const handleMouseEnter = () => {
      if (hoverFlags[name]) return;

      timer = setTimeout(() => {
        hoverFlags[name] = true;
        func?.(name);
        setHoverStates((prev) => ({
          ...prev,
          [name]: true,
        }));
      }, 5000);
    };

    const handleMouseLeave = () => {
      clearTimeout(timer);
      hoverFlags[name] = false;
      setHoverStates((prev) => ({
        ...prev,
        [name]: false,
      }));
    };

    element.addEventListener("mouseenter", handleMouseEnter);
    element.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      clearTimeout(timer);
      element.removeEventListener("mouseenter", handleMouseEnter);
      element.removeEventListener("mouseleave", handleMouseLeave);
    };
  };

  return { hoverStates, startHoverTimer };
};

export default function ChatbotEmbeddedPreview() {
  const { token, alias } = useQueryParams();
  const [currentScript, setCurrentScript] = useState(null);

  const { hoverStates, startHoverTimer } = useHoverSender((name) => {
    window.palActions.sendEvent("hover-5seconds", name);
  });

  useEffect(() => {
    if (currentScript) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      const withoutScriptTags = currentScript.replace(/<script[^>]*>/, "").replace(/<\/script>/, "");
      script.innerHTML = withoutScriptTags;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
        window._pproject = null;
        const iframes = document.querySelectorAll("iframe");
        for (let i = 0; i < iframes.length; i++) {
          iframes[i].parentNode.removeChild(iframes[i]);
        }

        const scripts = document.querySelectorAll("script");
        for (let i = 0; i < scripts.length; i++) {
          if (scripts[i].src && scripts[i].src.endsWith("/iframe/popup.js")) {
            scripts[i].parentNode.removeChild(scripts[i]);
          }
        }
      };
    }
  }, [currentScript]);

  useEffect(() => {
    const origin = window.location.hostname === "localhost" ? "http://localhost:4000" : window.location.origin;
    const cleanAlias = textWithoutNulls(alias);
    const cleanToken = textWithoutNulls(token);
    if (cleanAlias || cleanToken) {
      const code = scriptTemplate.format(
        cleanAlias ? "_project_alias" : "_project_token",
        cleanAlias || cleanToken,
        origin + import.meta.env.VITE_APP_PUBLIC_URL,
        predefinedFunctionCallCode
      );
      setCurrentScript(code);
    }
  }, [token, alias]);

  const handleClickCodeEditor = async () => {
    const newCode = await EmbeddedCodeEditorModal.show({ state: currentScript });
    if (newCode) {
      setCurrentScript(newCode);
    }
  };

  const handleClickSendEvent = async () => {
    ChatbotSendEventModal.show();
  };

  const handleClickDynamicSendEvent = (key, value) => () => {
    window.palActions.sendEvent(key, value);
    AlertHelper.showSuccess(t("common.action") + ". Key: " + key + ", Value: " + value);
  };
  return (
    <StyledBox>
      <HeaderToolbar>
        <PalTooltip title={t("component.chatbotEmbeddedPreview.codeEditorTooltip")}>
          <PalIconButton size="small" onClick={handleClickCodeEditor}>
            <Code />
          </PalIconButton>
        </PalTooltip>
        <PalTooltip title={t("component.chatbotEmbeddedPreview.sendEventTooltip")}>
          <PalIconButton size="small" onClick={handleClickSendEvent}>
            <Send />
          </PalIconButton>
        </PalTooltip>
      </HeaderToolbar>
      <Grid
        container
        p={6}
        spacing={2}
        sx={{
          placeSelf: "center",
          maxWidth: (theme) => theme.breakpoints.values.xl,
        }}
      >
        <Grid item xs={12}>
          <PalTextField
            fullWidth
            id="search-bar"
            label={t("component.chatbotEmbeddedPreview.searchBar")}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={4} xs={6}>
          <Card ref={startHoverTimer("product1")}>
            <PalTypography variant="h2">{t("component.chatbotEmbeddedPreview.product").format("1")}</PalTypography>
            <ImgBox alt="Product 1" src={productImage1} />
            <PalTypography variant="body1">{t("component.chatbotEmbeddedPreview.productDescription")}</PalTypography>
            <TestButton1
              fullWidth
              color="primary"
              id="product1-test-button-1"
              variant="contained"
              onClick={handleClickDynamicSendEvent("product1-test-button-1", "clicked")}
            >
              {t("component.chatbotEmbeddedPreview.button").format("1")}
            </TestButton1>
            <TestButton1
              fullWidth
              color="secondary"
              id="product1-test-button-2"
              variant="contained"
              onClick={handleClickDynamicSendEvent("product1-test-button-2", "clicked")}
            >
              {t("component.chatbotEmbeddedPreview.button").format("2")}
            </TestButton1>
          </Card>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Card ref={startHoverTimer("product2")}>
            <PalTypography variant="h2">{t("component.chatbotEmbeddedPreview.product").format("2")}</PalTypography>
            <ImgBox alt="Product 2" src={productImage2} />
            <PalTypography variant="body1">{t("component.chatbotEmbeddedPreview.productDescription")}</PalTypography>
            <TestButton1
              fullWidth
              color="primary"
              id="product2-test-button-1"
              variant="contained"
              onClick={handleClickDynamicSendEvent("product2-test-button-1", "clicked")}
            >
              {t("component.chatbotEmbeddedPreview.button").format("1")}
            </TestButton1>
            <TestButton1
              fullWidth
              color="secondary"
              id="product2-test-button-2"
              variant="contained"
              onClick={handleClickDynamicSendEvent("product2-test-button-2", "clicked")}
            >
              {t("component.chatbotEmbeddedPreview.button").format("2")}
            </TestButton1>
          </Card>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Card ref={startHoverTimer("product3")}>
            <PalTypography variant="h2">{t("component.chatbotEmbeddedPreview.product").format("3")}</PalTypography>
            <ImgBox alt="Product 3" src={productImage3} />
            <PalTypography variant="body1">{t("component.chatbotEmbeddedPreview.productDescription")}</PalTypography>
            <TestButton1
              fullWidth
              color="primary"
              id="product3-test-button-1"
              variant="contained"
              onClick={handleClickDynamicSendEvent("product3-test-button-1", "clicked")}
            >
              {t("component.chatbotEmbeddedPreview.button").format("1")}
            </TestButton1>
            <TestButton1
              fullWidth
              color="secondary"
              id="product3-test-button-2"
              variant="contained"
              onClick={handleClickDynamicSendEvent("product3-test-button-2", "clicked")}
            >
              {t("component.chatbotEmbeddedPreview.button").format("2")}
            </TestButton1>
          </Card>
        </Grid>
      </Grid>
      {/* <PreviewLabel variant="h1">Bot Preview</PreviewLabel> */}
    </StyledBox>
  );
}
