import React, { useEffect } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import styled from "@emotion/styled";
import { Grid, Alert } from "@mui/material";

import { ReactComponent as Logo } from "~assets/images/palmate-logo.svg";
import useLoading from "~common/hooks/useLoading";
import useQueryParams from "~common/hooks/useQueryParams";
import Footer from "~components/Layout/Footer";
import PalButton from "~components/mui/PalButton";
import PalLink from "~components/mui/PalLink";
import PalTextField from "~components/mui/PalTextField";
import { LS_TOKEN } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import StorageHelper from "~helpers/StorageHelper";
import Utils from "~helpers/Utils";
import { resetPassword, sendResetPassword } from "~store/actions";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  padding: 30px 50px;
  @media (max-width: 600px) {
    padding: 30px 20px;
  }
  overflow: auto;
  /* svg {
    flex: 0 0 auto;
  } */
  .input-box {
    max-width: 360px;
    margin: 100px auto auto auto;
    @media (max-width: 600px) {
      max-width: 100%;
      margin: 50px auto auto;
    }
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .title {
      color: ${(props) => props.theme.palette.primary.dark};
      //
      font: normal normal 600 36px/34px MuseoModerno;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .desc {
      font: normal normal normal 22px/28px Museo Sans;
      color: ${(props) => props.theme.palette.primary.dark};
      letter-spacing: 0;
      margin-bottom: 29px;
      // text-align: center;
    }
    .checkbox {
      margin: -12px 0px -6px 0px;
    }
    .forgot-password {
      color: ${(props) => props.theme.palette.primary.light};

      font: normal normal normal 16px/20px Museo Sans;
      letter-spacing: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
    // }
  }
  .divider {
    width: calc(360px - 50px);
    height: 1px;
    background-color: #ddd;
    margin: 0px 25px;
  }
  .login {
    font-family: Museo Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #838383;
    a {
      margin-left: 5px;
      color: #50c8f4;
      text-decoration: none !important;
    }
  }
  .forgot-password-btn {
    font-weight: bold;
    background-color: ${(props) => props.theme.palette.primary.light};
    border-color: ${(props) => props.theme.palette.primary.light};
  }
`;

const ForgotPassword2 = () => {
  const { t } = useTranslation();
  const [loading, q] = useLoading(false);
  const [sendResetDisabled, setSendResetDisabled] = React.useState(false);
  const [resetDone, setResetDone] = React.useState(false);

  const { token } = useQueryParams();

  const history = useHistory();
  const [alertInfo, setAlertInfo] = React.useState({
    msg: "",
    color: "",
  });
  const dispatch = useDispatch();

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      answer: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t("public.forgotPassword.emailRequired"))
        .email(t("public.forgotPassword.emailInvalid")),
    }),
    onSubmit: async (values) => {
      await q(dispatch(sendResetPassword(values.email, values.answer)));
      setAlertInfo({
        msg: t("public.forgotPassword.resetLinkSentAlert"),
        color: "success",
      });
      setSendResetDisabled(true);
      setTimeout(() => {
        setSendResetDisabled(false);
        //   setAlertInfo({
        //     msg: "",
        //     color: "success",
        //   });
      }, 3000);
    },
  });

  const formikReset = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t("public.forgotPassword.newPasswordRequired"))
        .min(10, t("public.forgotPassword.passwordMinLength").format("10"))
        .max(64, t("public.forgotPassword.passwordMaxLength").format("64"))
        .test("passwordComplexity", t("public.forgotPassword.passwordComplexity"), (value) =>
          Utils.validatePassword(value)
        ),
      confirmPassword: Yup.string()
        .required(t("public.forgotPassword.confirmPasswordRequired"))
        .oneOf([Yup.ref("password"), null], t("public.forgotPassword.passwordMatch")),
    }),
    onSubmit: async (values) => {
      try {
        const response = await q(dispatch(resetPassword(token, { password: values.password })));
        if (response?.data === "password changed successfully") {
          setResetDone(true);
          setAlertInfo({
            msg: t("public.forgotPassword.resetSuccessAlert"),
            color: "success",
          });
          setTimeout(() => {
            history.push("/login");
          }, 3000);
        } else if (response?.data === "Invalid token!") {
          setAlertInfo({
            msg: t("public.forgotPassword.invalidTokenAlert"),
            color: "error",
          });
        } else {
          setAlertInfo({
            msg: response?.data,
            color: "error",
          });
        }
      } catch (error) {
        setAlertInfo({
          msg: t("public.forgotPassword.resetErrorAlert"),
          color: "error",
        });
      }
    },
  });

  useEffect(() => {
    // dont display login page if user already authenticated
    StorageHelper.get(LS_TOKEN).then((user) => {
      if (user) {
        HistoryHelper.push("/dashboard");
      }
    });
  }, []);

  useEffect(() => {
    document.title = t("public.forgotPassword.pageTitle");
  }, [t]);

  //   useEffect(() => {
  //     let timer;
  //     if (token && resetMsg) {
  //       timer = setTimeout(() => {
  //         HistoryHelper.push("/login");
  //       }, 3000);
  //     }
  //     return () => clearTimeout(timer);
  //   }, [token, resetMsg]);

  return (
    <StyledDiv>
      {/* <div className="wrapper"> */}
      <div>
        <PalLink noPrefix to="/">
          <Logo />
        </PalLink>
      </div>
      <div className="input-box">
        <Grid container spacing={"25px"}>
          <Grid item xs={12}>
            <div className="title">{t("public.forgotPassword.title")}</div>
            <div className="desc">{t("public.forgotPassword.description")}</div>
          </Grid>
          {alertInfo?.msg && (
            <Grid item xs={12}>
              <Alert fullWidth severity={alertInfo.color}>
                {alertInfo.msg}
              </Alert>
            </Grid>
          )}
          {!token && (
            <>
              <Grid item xs={12}>
                <PalTextField
                  fullWidth
                  autoComplete="email"
                  invalid={formik.touched.email && formik.errors.email}
                  label={t("public.forgotPassword.emailLabel")}
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <PalButton
                  fullWidth
                  className="forgot-password-btn"
                  disabled={sendResetDisabled}
                  loading={loading}
                  variant="contained"
                  onClick={formik.handleSubmit}
                >
                  {t("public.forgotPassword.resetButton")}
                </PalButton>
              </Grid>
            </>
          )}
          {token && (
            <>
              <Grid item xs={12}>
                <PalTextField
                  fullWidth
                  autoComplete="password"
                  invalid={formikReset.touched.password && formikReset.errors.password}
                  label={t("public.forgotPassword.newPasswordLabel")}
                  name="password"
                  type="password"
                  value={formikReset.values.password}
                  onBlur={formikReset.handleBlur}
                  onChange={formikReset.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <PalTextField
                  fullWidth
                  invalid={formikReset.touched.confirmPassword && formikReset.errors.confirmPassword}
                  label={t("public.forgotPassword.confirmPasswordLabel")}
                  name="confirmPassword"
                  type="password"
                  value={formikReset.values.confirmPassword}
                  onBlur={formikReset.handleBlur}
                  onChange={formikReset.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <PalButton
                  fullWidth
                  disabled={resetDone}
                  loading={loading}
                  variant="contained"
                  onClick={formikReset.handleSubmit}
                >
                  {t("public.forgotPassword.resetPassword")}
                </PalButton>
              </Grid>
            </>
          )}

          <Grid item className="login" xs={12}>
            {t("public.forgotPassword.redirectLoginPreLinkText")}
            <PalLink noPrefix to="/login">
              {t("public.forgotPassword.redirectLoginLinkText")}
            </PalLink>
            {t("public.forgotPassword.redirectLoginPostLinkText")}
          </Grid>
        </Grid>
      </div>
      <Footer />
    </StyledDiv>
  );
};

export default ForgotPassword2;
