import { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  MdAdd,
  MdAutoAwesome,
  MdChevronRight,
  MdDelete,
  MdOutlineNewLabel,
  MdOutlineReportProblem,
  MdPriorityHigh,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Col, FormFeedback, Input, Label, Progress, Row } from "reactstrap";
import * as Yup from "yup";

import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
import useLoading from "~common/hooks/useLoading";
import useQueryParams from "~common/hooks/useQueryParams";
import CreateIntentTagModal from "~common/modals/CreateIntentTagModal";
import ConfusedIntentsPopup from "~components/ConfusedIntentsPopup";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRFormGroup from "~components/Generic/PRFormGroup";
import PRInput from "~components/Generic/PRInput";
import PRLink from "~components/Generic/PRLink";
import PRSelect from "~components/Generic/PRSelect";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  dialogComponentsAuthTypeOptions,
  dialogComponentsIntentType,
  dialogComponentsIntentTypeOptions,
  dialogComponentsRagFactGroupStatus,
} from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import store from "~store";
import { getIntentTags } from "~store/dialogComponents/intentTags/actions";
import {
  getIntentPrediction,
  getIntentSamplePrediction,
  setIntentSamplePrediction,
  setScenarioFormState,
  setSessionSamplePrediction,
} from "~store/dialogComponents/scenarioManager/actions";
import {
  selectScenarioManagerAuthType,
  selectScenarioManagerDisplayText,
  selectScenarioManagerIntentSamplePrediction,
  selectScenarioManagerIntentTag,
  selectScenarioManagerIntentTagText,
  selectScenarioManagerIntentType,
  selectScenarioManagerRagFactGroup,
  selectScenarioManagerResponse,
  selectScenarioManagerSamples,
  selectScenarioManagerSessionSamplePrediction,
  selectScenarioManagerText,
  selectScenarioManagerUseInPrediction,
} from "~store/dialogComponents/scenarioManager/selectors";
import { selectCurrentBot, selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import { getSimplifiedResponse } from "../helper";

export default function Question() {
  const { question, sample, samples: initialSamplePredictions } = useQueryParams();
  const { id } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const intentTagSelectRef = useRef(null);
  const [predictionList, setPredictionList] = useState();
  const [predictionSearchText, setPredictionSearchText] = useState();
  const [isFocusedQuestion, setIsFocusedQuestion] = useState();
  const [isQuestionModified, setIsQuestionModified] = useState(!!id);
  const [sampleFromSession, setSampleFromSession] = useState("");

  const text = useSelector(selectScenarioManagerText);
  const displayText = useSelector(selectScenarioManagerDisplayText);
  const samples = useSelector(selectScenarioManagerSamples);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const intentSamplePrediction = useSelector(selectScenarioManagerIntentSamplePrediction);
  const sessionSamplePrediction = useSelector(selectScenarioManagerSessionSamplePrediction);
  const intentTag = useSelector(selectScenarioManagerIntentTag);
  const intentTagText = useSelector(selectScenarioManagerIntentTagText);
  const useInPrediction = useSelector(selectScenarioManagerUseInPrediction);
  const userInfo = useSelector(selectUserInfo);

  const authType = useSelector(selectScenarioManagerAuthType);
  const intentType = useSelector(selectScenarioManagerIntentType);
  const ragFactGroup = useSelector(selectScenarioManagerRagFactGroup);

  const handleChangeAuthType = (value) => {
    dispatch(setScenarioFormState("auth_type", value));
  };

  const handleChangeIntentType = (value) => {
    dispatch(setScenarioFormState("intent_type", value));
  };

  const [loadingPrediction, enqueuePrediction] = useLoading();

  useEffect(() => {
    if (sample) {
      setSampleFromSession(sample);
    }
  }, [sample]);

  useEffect(() => {
    if (initialSamplePredictions) {
      const decodedSamples = decodeURIComponent(initialSamplePredictions);
      const samples = decodedSamples.split("\n").filter((item) => item.trim());
      dispatch(setSessionSamplePrediction(samples));
    }
  }, [initialSamplePredictions]);

  useEffect(() => {
    if (intentType === dialogComponentsIntentType.RAG && !id) {
      dispatch(setScenarioFormState("samples", []));
    }
  }, [intentType, id]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,

    initialValues: {
      text: text || question || "",
      display_text: displayText || question || "",
      intent_tag: intentTag,
      questionSample: "",
    },
    validationSchema: Yup.object({
      text: Yup.string().required(t("component.question.questionRequired")),
      display_text: Yup.string().required(t("component.question.nameRequired")),
      questionSample: Yup.string()
        .required(t("component.question.questionSampleRequired"))
        .test("len2", t("component.formik.genericMinLength").format("4"), (val) => val?.length >= 4)
        .test("already-exists", t("component.question.questionSampleAlreadyExists"), (val) => {
          return !samples?.find((item) => item.intent_text?.trim() === val?.trim());
        }),
    }),
  });

  // useEffect(() => {
  //   //Trigger intent prediction if initial text is present
  //   if (question) {
  //     setIsFocusedQuestion(false);
  //   }
  // }, [question]);

  useEffect(() => {
    if (predictionSearchText === undefined && text) {
      setPredictionSearchText(text);
    }
  }, [predictionSearchText, text]);

  useDebouncedEffect(
    () => {
      if (
        formik.values.text?.length > 2 &&
        (!isQuestionModified || isFocusedQuestion === true) &&
        predictionSearchText !== formik.values.text
      ) {
        const controller = new AbortController();
        enqueuePrediction(
          dispatch(
            getIntentPrediction(currentProject.id, currentBot.id, formik.values.text, {
              signal: controller.signal,
            })
          )
            .then((res) => {
              const sortedPredictionList = res.sort((a, b) => b.confidence - a.confidence);
              setPredictionList(sortedPredictionList);
            })
            .catch(() => {
              setPredictionList([]);
            })
            .finally(() => {
              setPredictionSearchText(formik.values.text);
            })
        );
        return () => {
          controller.abort();
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, predictionSearchText, formik.values.text, isFocusedQuestion, enqueuePrediction],
    500
  );

  const handleFocusQuestion = () => {
    setIsFocusedQuestion(true);
  };

  const handleBlurQuestion = (e) => {
    setIsFocusedQuestion(false);
    formik.handleBlur(e);
  };

  const handleChange = (name) => (e) => {
    formik.handleChange(e);
    if (name === "text") {
      dispatch(setScenarioFormState("text", e.target.value));
    }
  };
  const handleClickAddSample = () => {
    if (!formik.values.questionSample || formik.errors.questionSample) return;
    dispatch(setScenarioFormState("samples", [...samples, { intent_text: formik.values.questionSample }]));
    formik.setFieldValue("questionSample", "");
  };
  const handleClickDeleteSample = (item) => () => {
    dispatch(setScenarioFormState("samples", [...samples.filter((sample) => sample !== item)]));
  };
  const handleClickDeletePrediction = (item, isSampleFromSession) => () => {
    if (isSampleFromSession) {
      setSampleFromSession("");
    }
    dispatch(setSessionSamplePrediction([...sessionSamplePrediction.filter((sample) => sample !== item?.intent_text)]));
    dispatch(setIntentSamplePrediction([...intentSamplePrediction.filter((sample) => sample !== item?.intent_text)]));
  };
  const handleClickAddPredictionToSamples = (item) => () => {
    dispatch(setScenarioFormState("samples", [...samples, { intent_text: item.intent_text }]));
    dispatch(setSessionSamplePrediction([...sessionSamplePrediction.filter((sample) => sample !== item?.intent_text)]));
    dispatch(setIntentSamplePrediction([...intentSamplePrediction.filter((sample) => sample !== item?.intent_text)]));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleClickAddSample();
    }
  };

  const handleChangeDisplayNameText = (e) => {
    const value = e.target.value;
    const isModified = value?.length > 0;
    setIsQuestionModified(isModified);
    dispatch(setScenarioFormState("display_text", value));
    formik.setFieldValue("display_text", value);
  };

  const handleChangeText = (e) => {
    const value = e.target.value;
    dispatch(setScenarioFormState("text", value));
    formik.setFieldValue("text", value);

    if (!isQuestionModified) {
      dispatch(setScenarioFormState("display_text", value));
      formik.setFieldValue("display_text", value);
    }
  };

  const handleChangeQuestionSample = (index) => (e) => {
    const value = e.target.value;
    dispatch(
      setScenarioFormState("samples", [
        ...samples.map((sample, i) => (i === index ? { ...sample, intent_text: value } : sample)),
      ])
    );
  };

  useEffect(() => {
    dispatch(setIntentSamplePrediction([]));
  }, [dispatch]);

  const handlePredictQuestionSamples = (e) => {
    const errors = [];
    if (samples.length < 1 && !formik.values.questionSample) {
      errors.push(t("component.question.questionSampleRequired"));
    }
    if (errors.length) {
      DialogHelper.showValidate(errors);
      return;
    }

    const response = selectScenarioManagerResponse(store.getState());
    const simplifiedResponse = getSimplifiedResponse(response);
    dispatch(setIntentSamplePrediction(null));
    dispatch(
      getIntentSamplePrediction(currentProject.id, currentBot.id, {
        text: formik.values.text,
        samples: samples.map((item) => item.intent_text),
        response: simplifiedResponse,
        count: e.shiftKey ? 25 : 10,
      })
    );
  };

  const samplesWithPrediction = useMemo(() => {
    if (!samples?.length && !intentSamplePrediction?.length && !sampleFromSession && !sessionSamplePrediction?.length)
      return [];
    const predictions = (sessionSamplePrediction || []).concat(intentSamplePrediction || []);
    const items = [
      ...(samples || []),
      ...predictions.map((item, index) => ({
        id: `prediction-${index}`,
        isPrediction: true,
        intent_text: item,
      })),
    ];
    if (sampleFromSession && !items.find((item) => item.intent_text === sampleFromSession)) {
      items.push({
        id: `sample-${sampleFromSession}`,
        intent_text: sampleFromSession,
        isPrediction: true,
        sampleFromSession: true,
      });
    }
    return items;
  }, [samples, intentSamplePrediction, sampleFromSession, sessionSamplePrediction]);

  const isPredictionLoading = useMemo(() => {
    return intentSamplePrediction === null;
  }, [intentSamplePrediction]);

  const handleSearchIntentTag = async (searchText, callback, signal) => {
    const response = await dispatch(
      getIntentTags(currentProject.id, currentBot.id, {
        params: {
          key__icontains: searchText,
          limit: 20,
          signal,
        },
      })
    );
    const result = response?.results || [];
    if (intentTag && !result?.find((item) => item.id === intentTag)) {
      result.unshift({
        id: intentTag,
        key: intentTagText,
      });
    }
    return result;
  };
  const handleChangeIntentTag = (id, type, obj) => {
    dispatch(setScenarioFormState("intent_tag", id));
    dispatch(setScenarioFormState("intent_tag_text", obj?.key));
  };
  const handleClickAddNewIntentTag = async () => {
    const result = await CreateIntentTagModal.show();
    if (result) {
      intentTagSelectRef.current.refresh();
      handleChangeIntentTag(result?.id, null, result);
    }
  };
  const handleChangeCheckbox = (key) => (e) => {
    const value = e.target.checked;
    dispatch(setScenarioFormState(key, value));
  };
  const handleCtrlVQuestion = async (e) => {
    if (e.ctrlKey && e.key === "v") {
      e.preventDefault();
      const text = await navigator.clipboard.readText();
      handleChangeText({ target: { value: text } });
    }
  };
  return (
    <div className="">
      <Alert className="" color="warning" isOpen={!useInPrediction}>
        <MdOutlineReportProblem className="me-2 " />
        {t("component.question.useInPredictionWarning")}
      </Alert>
      <Label className="" htmlFor="intent-question">
        {t("common.name")}:
      </Label>
      <Input
        className="w-md-50"
        id="intent-question"
        invalid={!!formik.touched.display_text && !!formik.errors.display_text}
        name="display_text"
        placeholder={t("component.question.namePlaceholder")}
        type="text"
        value={formik.values.display_text}
        onBlur={formik.handleBlur}
        onChange={handleChangeDisplayNameText}
      />
      {formik.touched.display_text && formik.errors.display_text && (
        <FormFeedback type="invalid">{formik.errors.display_text}</FormFeedback>
      )}

      <Label className="mt-3" htmlFor="intent-question-name">
        {t("common.question")}:
      </Label>
      <Input
        className="w-md-50"
        id="intent-question-name"
        invalid={!!formik.touched.text && !!formik.errors.text}
        name="text"
        placeholder={t("component.question.questionPlaceholder")}
        type="text"
        value={formik.values.text}
        onBlur={handleBlurQuestion}
        onChange={handleChangeText}
        onFocus={handleFocusQuestion}
        onKeyDown={handleCtrlVQuestion}
      />
      {formik.touched.text && formik.errors.text && <FormFeedback type="invalid">{formik.errors.text}</FormFeedback>}
      {(predictionList || loadingPrediction) && (
        <PRContainer bare className="mt-3" loading={loadingPrediction}>
          <Label>{t("component.question.similarIntents")}</Label>
          {!!predictionList?.length && (
            <ul>
              {predictionList?.map((item) => {
                return (
                  <li key={item.id}>
                    <div className="d-flex align-items-center">
                      <PRTooltip title={`${(item.confidence * 100).toPrecision(3)}% Confidence`}>
                        <Progress
                          className="me-2"
                          color="primary"
                          style={{
                            width: "125px",
                            height: "8px",
                          }}
                          value={item.confidence * 100}
                        />
                      </PRTooltip>

                      <PRLink newTab className="text-primary" to={`/chatbot/intent/form/${item.id}`}>
                        {item.display_text}
                      </PRLink>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {!loadingPrediction && predictionList?.length === 0 && (
            <div className="text-center text-muted">{t("component.question.noSimilarIntent")}</div>
          )}
        </PRContainer>
      )}

      <Row className="g-0 align-items-end w-md-50 w-xs-100 ">
        <Col xs>
          <Label className="mt-3" htmlFor="intent-tag-select">
            {t("common.intentTag")}:
          </Label>
          <PRSelect
            ref={intentTagSelectRef}
            isPrimitiveValue
            lazy
            id="intent-tag-select"
            labelSelector="key"
            loadOptions={handleSearchIntentTag}
            name="intent_tag"
            placeholder={t("component.question.intentTagPlaceholder")}
            value={formik.values.intent_tag}
            valueSelector="id"
            onChange={handleChangeIntentTag}
          />
        </Col>
        <Col className="d-flex justify-content-end align-items-end" xs="auto">
          <PRButton
            className="ms-2"
            color="primary"
            icon={MdOutlineNewLabel}
            tooltipText={t("component.question.addNewIntentTag.tooltip")}
            onClick={handleClickAddNewIntentTag}
          />
        </Col>
      </Row>

      <div className="mt-3 w-md-50 w-xs-100 ">
        <Label>{t("common.intentType")}:</Label>
        <PRSelect
          isPrimitiveValue
          isClearable={false}
          options={dialogComponentsIntentTypeOptions}
          placeholder={t("component.question.intentTypePlaceholder")}
          value={intentType}
          onChange={handleChangeIntentType}
        />
      </div>
      <div className="mt-3 w-md-50 w-xs-100 ">
        <Label>{t("component.question.authenticationType")}</Label>
        <PRSelect
          isPrimitiveValue
          isClearable={false}
          options={dialogComponentsAuthTypeOptions}
          placeholder={t("component.question.authenticationTypePlaceholder")}
          value={authType}
          onChange={handleChangeAuthType}
        />
      </div>

      <PRFormGroup check switch className="mt-3">
        <PRInput
          checked={useInPrediction}
          id="use_in_prediction"
          type="checkbox"
          onChange={handleChangeCheckbox("use_in_prediction")}
        />
        <Label check htmlFor="use_in_prediction">
          {t("common.useInPrediction")}
        </Label>
      </PRFormGroup>

      <>
        <div className="mt-3" />
        <Alert
          className=""
          color="info"
          isOpen={
            intentType === dialogComponentsIntentType.RAG &&
            ragFactGroup?.status !== dialogComponentsRagFactGroupStatus.Processed
          }
        >
          <MdPriorityHigh className="me-2 " />
          {ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processing
            ? t("component.question.ragIntentProcessingAlert")
            : t("component.question.ragIntentNotProcessedAlert")}
        </Alert>

        {intentType === dialogComponentsIntentType.STANDARD && (
          <>
            <Label htmlFor="intent-question-sample">
              {t("component.question.questionSamples").format(samples?.length ? samples?.length.toString() : "0")}
            </Label>
            <div
              disabled={
                isPredictionLoading ||
                (intentType === dialogComponentsIntentType.RAG &&
                  ragFactGroup?.status !== dialogComponentsRagFactGroupStatus.Processed)
              }
            >
              <Row>
                <Col xs={12}>
                  <div className="input-group">
                    <Input
                      className="form-control"
                      id="intent-question-sample"
                      invalid={!!formik.values.questionSample && !!formik.errors.questionSample}
                      name="questionSample"
                      placeholder={t("component.question.questionSamplePlaceholder")}
                      type="text"
                      value={formik.values.questionSample}
                      onBlur={formik.handleBlur}
                      onChange={handleChange("samples")}
                      onKeyDown={handleKeyDown}
                    />
                    <PRButton disabled={!!formik.errors.questionSample} icon={MdAdd} onClick={handleClickAddSample} />
                  </div>
                  <Input invalid={true} type="hidden" />
                  {formik.values.questionSample && formik.errors.questionSample && (
                    <FormFeedback type="invalid">{formik.errors.questionSample}</FormFeedback>
                  )}
                </Col>
              </Row>
              <Row className="g-2 pt-2">
                {samplesWithPrediction.map((sample, index) => {
                  const isPrediction = sample.isPrediction;
                  return (
                    <Fragment key={`${sample.id || index}`}>
                      <Col xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="gap-2 ms-2 d-flex align-items-center w-100">
                            <MdChevronRight className="fs-3" />
                            {isPrediction ? (
                              <div
                                className="w-100 opacity-50"
                                style={{
                                  overflowWrap: "anywhere",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                }}
                              >
                                <Label size="md">{sample.intent_text}</Label>
                              </div>
                            ) : (
                              <PRInput
                                borderless
                                className="w-100"
                                name="intent_text"
                                placeholder={t("component.question.questionSamplePlaceholder")}
                                value={sample.intent_text}
                                onChange={handleChangeQuestionSample(index)}
                                {...(isPrediction && {
                                  tooltipText: sample.intent_text,
                                })}
                              />
                            )}
                            {!isPrediction ? (
                              <>
                                <div className="d-flex align-items-center justify-content-end" style={{ width: 75 }}>
                                  {!!sample.confused_intents?.length && userInfo?.is_superuser && (
                                    <ConfusedIntentsPopup
                                      data={sample.confused_intents}
                                      iconClass={"fs-5"}
                                      placement="left"
                                    />
                                  )}
                                  <PRButton
                                    outline
                                    className="ms-1"
                                    color="danger"
                                    icon={MdDelete}
                                    onClick={handleClickDeleteSample(sample)}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <PRButton
                                  outline
                                  color="success"
                                  icon={MdAdd}
                                  tooltipText={t("component.question.addPredictionTooltip")}
                                  onClick={handleClickAddPredictionToSamples(sample)}
                                />
                                <PRButton
                                  outline
                                  color="secondary"
                                  icon={MdDelete}
                                  tooltipText={
                                    !sample.sampleFromSession
                                      ? t("component.question.removePredictionTooltip")
                                      : t("common.remove")
                                  }
                                  onClick={handleClickDeletePrediction(sample, sample.sampleFromSession)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Fragment>
                  );
                })}
              </Row>
              <Row className="g-2 pt-2 justify-content-end">
                <Col xs="auto">
                  <PRButton
                    icon={MdAutoAwesome}
                    loading={isPredictionLoading}
                    size="sm"
                    tooltipDelay={1500}
                    tooltipText={
                      <div>
                        <div className="text-center fw-bold">
                          {t("component.question.generateSamplesButtonTooltip")}
                        </div>
                        <span className="text-end fw-bold me-1">
                          {t("component.question.generateSamplesButtonTooltipShift")}
                        </span>
                        <span className="text-start">{t("component.question.generateSamplesButtonTooltip2")}</span>
                      </div>
                    }
                    onClick={handlePredictQuestionSamples}
                  >
                    {t("component.question.generateSamplesButton")}
                  </PRButton>
                </Col>
              </Row>
            </div>
          </>
        )}
      </>
    </div>
  );
}
