import "~helpers/prototypes";

import { useEffect, useState } from "react";

import { CardonContainer } from "cardon";
import i18next from "i18next";
import moment from "moment-timezone";
import { IconContext } from "react-icons/lib";
import { Circle } from "react-preloaders2";
import { useDispatch, useSelector } from "react-redux";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import PRAlertProvider from "~components/Generic/PRAlertProvider";
import PRDialogProvider from "~components/Generic/PRDialogProvider";
import PRErrorBoundary from "~components/Generic/PRErrorBoundary";
import PRThemeProvider from "~components/Generic/PRThemeProvider";
import { LS_WEBCHAT_BACKUP_TOKEN, LS_WEBCHAT_TOKEN, LS_WEBCHAT_TOKEN_EXPIRE, apiUrlKeyStore } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import CryptoHelper from "~helpers/CryptoHelper";
import DialogHelper from "~helpers/DialogHelper";
import IFrameHelper from "~helpers/IFrameHelper";
import Network from "~helpers/Network";
import SanitizeHelper from "~helpers/SanitizeHelper";
import StorageHelper from "~helpers/StorageHelper";
import TokenHelper from "~helpers/TokenHelper";
import { selectLoadingPre } from "~store/helpers/loading/selectors";
import { clearPushNotification, requestPushNotification } from "~store/notification/actions";

const iconContextValue = {
  className: "pr-icon",
};
let printOnce = false;
const useLoadKeyStore = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  window.pr_config ??= {};
  if (import.meta.env.DEV) {
    const obj = {
      google: import.meta.env.VITE_APP_GOOGLE_MAP_API_KEY,
      firebase: import.meta.env.VITE_APP_VAPID_KEY,
      recaptcha_v2: import.meta.env.VITE_APP_GOOGLE_RECAPTCHA_KEY,
    };
    Object.keys(obj).forEach((key) => {
      window.pr_config[key] = obj[key];
    });
    CryptoHelper.encrypt(JSON.stringify(obj)).then((result) => {
      window.pr_config.generatedAppKey = result;
    });
    window.pr_config.CryptoHelper = CryptoHelper;
    const storeData = {
      api: window.pr_config.generatedAppKey,
    };
    try {
      if (printOnce) {
        printOnce = true;
        CryptoHelper.decrypt(storeData.api).then((decrypted) => {
          console.log("Dynamic API keys ready & decrypted:", storeData, decrypted);
        });
      }
    } catch (error) {
      console.error("Dynamic API keys decrypt error:", error);
    }
  }
  useEffect(() => {
    Network.request(apiUrlKeyStore.get, {
      noBearer: true,
      loading: false,
      noAlert: true,
    })
      .then(async (data) => {
        if (data?.api) {
          const keyObj = JSON.parse(await CryptoHelper.decrypt(data.api));
          window.pr_config ??= {};
          Object.keys(keyObj).forEach((key) => {
            window.pr_config[key] = keyObj[key];
          });
        }
      })
      .catch((e) => {
        const safeMessageText = e?.message?.replace(/[^a-zA-Z0-9_-]/g, "").replace(/\n|\r/g, "");
        console.warn("useLoadKeyStore", safeMessageText);
      })
      .finally(() => {
        setLoaded(true);
        if (import.meta.env.VITE_APP_NOTIFICATION_ENABLED === "true") {
          dispatch(requestPushNotification());
        } else {
          dispatch(clearPushNotification());
        }
      });
  }, [dispatch]);

  return loaded;
};

const StorageLoader = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const loadedKeyStore = useLoadKeyStore();
  useEffect(() => {
    (async () => {
      await import("~i18n.js");
      const storageAvailable = await StorageHelper.isAvailable();
      //load import "~i18n.js"; here
      // require("~i18n.js");

      if (storageAvailable) {
        const isWebChatMode = IFrameHelper.isWebChatMode();
        if (isWebChatMode) {
          const params = new URLSearchParams(window?.location?.search || {});
          const token = SanitizeHelper.safeText(params.get("token"));
          const alias = TokenHelper.getChatbotAlias();
          const session = SanitizeHelper.safeText(params.get("session_token"));
          if ((token || alias) && session) {
            await StorageHelper.set(LS_WEBCHAT_TOKEN.format(token || alias), session);
            await StorageHelper.set(LS_WEBCHAT_BACKUP_TOKEN.format(token || alias), session);
            await StorageHelper.set(LS_WEBCHAT_TOKEN_EXPIRE.format(token || alias), "2030-01-01T00:00:00.000000Z");
          }
        }
        if (window.__pp_storage_mode === "parent") {
          AlertHelper.showWarning(i18next.t("error.storage.thirdparty-cookies"));
        }
      } else {
        if (!IFrameHelper.isInIFrame()) {
          if (IFrameHelper.isWebChatMode()) {
            AlertHelper.showError(i18next.t("error.storage.message"), {
              persist: true,
              key: "storage-error",
            });
          } else {
            DialogHelper.showOk(i18next.t("error.storage.title"), i18next.t("error.storage.message"));
          }
        }
      }
      setLoaded(true);
    })();
  }, []);
  return loaded && loadedKeyStore ? children : null;
};
const RenderWrapper = ({ children }) => {
  const [hideLoading, setHideLoading] = useState(false);
  const loadingPre = useSelector(selectLoadingPre);
  useEffect(() => {
    if (loadingPre) {
      setHideLoading(true);
    }
    const timeout = setTimeout(() => {
      setHideLoading(false);
    }, 1500);
    return () => clearTimeout(timeout);
  }, [loadingPre]);

  return (
    <PRErrorBoundary>
      <IconContext.Provider value={iconContextValue}>
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment} locale="tr">
          <PRThemeProvider>
            <StorageLoader>
              <CardonContainer />
              <PRDialogProvider />
              <PRAlertProvider />
              {children}
            </StorageLoader>
            {IFrameHelper.isWebChatMode() && !IFrameHelper.isInIFrame() && hideLoading && (
              <Circle customLoading={loadingPre} time={0} />
            )}
          </PRThemeProvider>
        </LocalizationProvider>
      </IconContext.Provider>
    </PRErrorBoundary>
  );
};

export default RenderWrapper;
