import { useEffect, useMemo } from "react";

import { useMonaco } from "@palamar/fe-library";

import Utils from "~helpers/Utils";

import "./style.scss";

/**
 * @param {any} editor
 * @param editorBase
 * @param {{
 *   variableResolver: (variableName: string) => string;
 *   tagClassName: string;
 *   variableClassName: string;
 *   wrapperType: "angleBrackets" | "curlyBrackets";
 *   validator: (variableName: string) => boolean;
 * }} opt
 */
export default function useMonacoDecorator(editorBase, opt = {}) {
  const editor = editorBase?.getEditorType() === "vs.editor.IDiffEditor" ? editorBase.getModifiedEditor() : editorBase;
  const monaco = useMonaco();
  const { variableResolver, tagClassName, variableClassName, wrapperType } = useMemo(() => {
    return {
      variableResolver:
        opt.variableResolver ??
        function (variableName) {
          return variableName;
        },
      tagClassName: opt.tagClassName ?? "template-variable-tag",
      variableClassName: opt.variableClassName ?? "template-variable",
      wrapperType: opt.wrapperType ?? "angleBrackets", // "angleBrackets" | "curlyBrackets"
      validator: opt.validator ?? (() => true),
    };
  }, [opt]);
  const wStart = wrapperType === "angleBrackets" ? "<<" : "${";
  const wEnd = wrapperType === "angleBrackets" ? ">>" : "}";

  useEffect(() => {
    if (!editor) return;

    let decorations;
    const updateDecorations = (e) => {
      const newDecorations = [];
      try {
        if (editor.getEditorType() === "vs.editor.IDiffEditor" && !editor.getModifiedEditor().getModel()) return;
        if (!editor.hasModel()) return;
      } catch (error) {
        return;
      }
      const model =
        editor.getEditorType() === "vs.editor.IDiffEditor" ? editor.getModifiedEditor().getModel() : editor.getModel();
      const text = model.getValue();
      decorations?.clear();
      const variableRegex = new RegExp(`${Utils.escapeRegExp(wStart)}(?<key>.+?)${Utils.escapeRegExp(wEnd)}`, "g");
      let match;
      while ((match = variableRegex.exec(text))) {
        const key = match?.groups?.key;
        if (opt.validator && !opt.validator(key)) continue;
        const startPos = model.getPositionAt(match.index);
        const endPos = model.getPositionAt(match.index + match[0].length);
        // const matchedString = match[0].replace(
        //   new RegExp(`${Utils.escapeRegExp(wStart)}|${Utils.escapeRegExp(wEnd)}`, "g"),
        //   ""
        // );
        const startLength = wStart.length;
        const endLength = wEnd.length;
        newDecorations.push({
          range: new monaco.Range(
            startPos.lineNumber,
            startPos.column,
            startPos.lineNumber,
            startPos.column + startLength
          ),
          options: {
            inlineClassName: tagClassName,
          },
        });
        newDecorations.push({
          range: new monaco.Range(endPos.lineNumber, endPos.column - endLength, endPos.lineNumber, endPos.column),
          options: {
            inlineClassName: tagClassName,
          },
        });
        // const hoverLabel = variableResolver?.(matchedString);
        newDecorations.push({
          range: new monaco.Range(
            startPos.lineNumber,
            startPos.column + startLength,
            endPos.lineNumber,
            endPos.column - endLength
          ),
          options: {
            inlineClassName: variableClassName,
            // hoverMessage: [
            //   {
            //     value: `Label: **${hoverLabel}**`,
            //   },
            //   {
            //     value: `Value: **${matchedString}**`,
            //   },
            // ],
          },
        });
      }
      decorations = editor.createDecorationsCollection(newDecorations);
    };
    updateDecorations();
    const disposables = [];
    disposables.push(editor?.onDidChangeModelContent(updateDecorations));
    return () => {
      disposables.forEach((d) => d?.dispose?.());
      //clear all decorations
      decorations?.clear();
    };
  }, [editor, monaco, variableResolver, tagClassName, variableClassName, wrapperType, wStart, wEnd, opt.validator]);
}
