import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import styled from "@emotion/styled";
import { Done } from "@mui/icons-material";
import { Box } from "@mui/material";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: calc(100%);
  padding: 30px 50px;
  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  overflow-y: auto;
  svg {
    flex: 0 0 auto;
  }

  .success-message {
    text-align: center;
    font: normal normal 600 24px/34px MuseoModerno;
    color: ${(props) => props.theme.palette.primary.dark};
  }
  .success-icon {
    font-size: 48px;
    margin-top: 8px;
  }
`;

const IntegrationCallbackPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (window.opener && code) {
      window.opener.postMessage({ code }, "*");
    }

    window.close();
  }, []);

  return (
    <StyledDiv>
      <Box alignItems="center" display="flex" flexDirection={"column"} height="100%" justifyContent="center">
        <div className="success-message">{t("onboarding.integrationCallbackPage.successMessage")}</div>
        <Done className="success-icon" color="success" />
      </Box>
    </StyledDiv>
  );
};

export default IntegrationCallbackPage;
