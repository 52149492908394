import i18next from "i18next";

import { dialogClose, dialogShow } from "~store/helpers/dialog/actions";

import store from "../store";

export default class DialogHelper {
  /**
   * @param {string} title Dialog title
   * @param {string | React.DOMElement} content Content of dialog
   * @param {{
   *   onClick: () => void;
   *   label: string;
   *   variant?: "text" | "outlined" | "contained";
   *   color?: "default" | "inherit" | "primary" | "secondary";
   *   close?: boolean;
   * }[]} actions
   *   Actions of dialog
   * @param {{
   *   disableBackdropClick?: boolean;
   *   dialogOptions: import("@mui/material/Dialog").DialogProps;
   * }} options
   *   Options of dialog
   */
  static show = (title, content, actions, options = {}) => {
    const { key: customKey, onClose, ...restOptions } = options;
    const key = customKey || new Date().getTime().toString();

    return new Promise((resolve) => {
      store.dispatch(
        dialogShow({
          title,
          content,
          actions,
          key,
          options: { icon: null, ...restOptions },
          onClose: () => {
            onClose?.();
            resolve();
            this.close(key);
          },
        })
      );
    });
  };
  static showQuestion = (title, content, okAction, cancelAction = {}, options = {}) => {
    const { key: customKey, onClose, ...restOptions } = options;
    const key = customKey || new Date().getTime().toString();
    return new Promise((resolve) => {
      const handleResolve = (result) => () => {
        onClose?.(result);
        resolve(result);
        if (cancelAction?.onClose && !result) {
          cancelAction.onClose(result);
        } else if (okAction?.onClose && result) {
          okAction.onClose(result);
        }
        this.close(key);
      };
      store.dispatch(
        dialogShow({
          title,
          content,
          key,
          options: restOptions,
          actions: [
            okAction && {
              onClick: handleResolve(true),
              ...okAction,
              variant: okAction.variant || "contained",
            },
            {
              onClick: handleResolve(false),
              label: okAction ? i18next.t("chatbot.closeText.cancel") : i18next.t("dialog.ok"),
              variant: okAction ? "outlined" : "contained",
              color: "primary",
              ...cancelAction,
            },
          ].filter(Boolean),
          onClose: handleResolve,
        })
      );
    });
  };
  static showQuestionYesNo = (title = "", content = "", okAction = {}, cancelAction = {}, options = {}) => {
    return DialogHelper.showQuestion(
      title || i18next.t("question.yesNo.warning"),
      content || i18next.t("question.yesNo.title"),
      {
        label: i18next.t("question.yes"),
        ...okAction,
        autoFocus: true,
      },
      {
        label: i18next.t("question.no"),
        variant: "outlined",
        ...cancelAction,
      },

      options
    );
  };

  static showQuestionDelete = (title, content, okAction, cancelAction = {}, options = {}) => {
    return DialogHelper.showQuestion(
      title || i18next.t("question.delete.warning"),
      content || i18next.t("question.delete.title"),
      {
        label: i18next.t("question.delete"),
        ...okAction,
        autoFocus: true,
      },
      cancelAction,
      options
    );
  };

  static showQuestionDeleteWithFormat = (
    nameToDelete,
    { title, content, okAction, cancelAction, ...restOptions } = {
      title: null,
      content: null,
      okAction: null,
      cancelAction: {},
    }
  ) => {
    return DialogHelper.showQuestion(
      title || i18next.t("question.delete.warning"),
      <div
        dangerouslySetInnerHTML={{
          __html: i18next
            .t("question.delete.titleWithFormat")
            .format('<span style="font-weight:700;color:#333">' + nameToDelete + "</span>"),
        }}
      />,
      {
        label: i18next.t("question.delete"),
        ...okAction,
        autoFocus: true,
      },
      cancelAction,
      restOptions
    );
  };
  static showOk = (title, content, okAction = {}, options = {}) => {
    const { key: customKey, onClose, ...restOptions } = options;
    const key = customKey || new Date().getTime().toString();
    return new Promise((resolve) => {
      const handleResolve = () => {
        onClose?.();
        resolve();
        if (okAction?.onClose) {
          okAction.onClose();
        }
        this.close(key);
      };
      store.dispatch(
        dialogShow({
          title,
          content,
          key,
          options: restOptions,
          actions: [
            {
              onClick: handleResolve,
              label: i18next.t("dialog.ok"),
              variant: "contained",
              sx: (theme) => ({ color: theme.palette.primary.contrastText }),
              ...okAction,
            },
          ].filter(Boolean),
          onClose: handleResolve,
        })
      );
    });
  };
  /**
   * Automatically convert error string list to error dialog.
   *
   * @param {string[]} errors - List of error message. Nested array will be converted to nested list in popup dialog
   * @param {any} optionsProp - Options of dialog
   */
  static showValidate = (
    errors,
    optionsProp = {
      noDuplicate: true,
    }
  ) => {
    const defaultOptions = {
      noDuplicate: true,
    };
    const options = { ...defaultOptions, ...optionsProp };
    let errorList = [...errors];

    const buildMessage = (error, depth = 0) => {
      return (
        <>
          {error.map((error, index) => {
            let messageData = error;
            if (Array.isArray(messageData) && messageData.length) {
              const firstMessageItem = messageData[0];
              let restMessageItem = messageData.slice(1);
              if (options.noDuplicate) {
                restMessageItem = [...new Set(restMessageItem)];
              }
              messageData = buildMessage(restMessageItem, depth + 1);
              return (
                <div key={index}>
                  <li key={index}>{firstMessageItem}</li>
                  <ul>{messageData}</ul>
                </div>
              );
            } else if (typeof messageData === "string") {
              return <li key={index}>{error}</li>;
            }
          })}
        </>
      );
    };

    if (options.noDuplicate) {
      errorList = [...new Set(errorList)];
    }
    const content = <ul className="text-start mx-5 mt-2">{buildMessage(errorList)}</ul>;
    return DialogHelper.showOk(i18next.t("dialog.validate.title"), content);
  };
  static close = (key) => {
    store.dispatch(dialogClose({ key }));
  };
}
