import { useEffect, useMemo, useRef, useState } from "react";

import { withCardon } from "cardon";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { MdContentPaste, MdDelete, MdHelp, MdKey } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { Switch } from "@mui/material";

import PRButton from "~components/Generic/PRButton";
import { PRDividerLabel } from "~components/Generic/PRDivider";
import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import { apiUrlOrganization } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import { createOrUpdateSecretKey, deleteSecretKey } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";

function CredentialsManagerModalContent({ get, value = "" }) {
  const { t } = useTranslation();
  const [generatedKey, setGeneratedKey] = useState();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  //   const secretKeyList = useSelector(selectSecretKeyList);

  const [keepMissingMode, setKeepMissingMode] = useState(true);
  const tableRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t("component.formik.required").format(t("common.name")))
        .min(3, t("component.formik.minLength").format(t("common.name"), "3")),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const response = await dispatch(createOrUpdateSecretKey(currentProject.id, values, keepMissingMode));
      tableRef.current.refresh();
      setGeneratedKey(response);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    // dispatch(getSecretKeyList(currentProject.id));
  }, [dispatch, currentProject.id]);

  const handleSwitchChange = (event) => {
    setKeepMissingMode(event.target.checked);
  };

  const columns = useMemo(() => {
    const handleClickDelete = (id, row) => async () => {
      if (await DialogHelper.showQuestionDeleteWithFormat(row.name)) {
        await dispatch(deleteSecretKey(currentProject.id, id));
        if (id === generatedKey?.id) {
          setGeneratedKey(null);
        }
        tableRef.current.refresh();
      }
    };

    return [
      {
        label: t("common.name"),
        key: "name",
      },
      {
        label: t("component.credentialsManagerModal.expiresIn"),
        key: "expires_in",
        render: (row) => {
          return DateHelper.getDateTimeLocal(row.expires_in).format("LLT");
        },
      },
      {
        label: t("component.credentialsManagerModal.maskedSecretKey"),
        key: "masked_secret_key",
      },
      {
        label: t("component.credentialsManagerModal.updateMode"),
        key: "keep_missing",
        render(row) {
          return row.keep_missing
            ? t("component.credentialsManagerModal.updateAndCreate")
            : t("component.credentialsManagerModal.exactReplacement");
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => {
          //   const url = new URL(row.data_file);
          //   const newUrl = `${window.location.origin}${url.pathname}`;

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText={t("common.delete")}
                onClick={handleClickDelete(row.id, row)}
              />
            </div>
          );
        },
      },
    ];
  }, [dispatch, currentProject.id, generatedKey, t]);

  const handleClickCopyKey = () => {
    navigator.clipboard.writeText(generatedKey?.secret_key);
    AlertHelper.showSuccess(t("component.credentialsManagerModal.keyCopied"));
  };
  return (
    <PRModal size="lg" submitText={""} title={t("dashboard.customerList.apiCredentials")} onClose={get(false)}>
      <p>{t("component.credentialsManagerModal.description1")} </p>

      <p>
        {t("component.credentialsManagerModal.description2")}
        <span className="bg-secondary bg-soft rounded px-1 mx-1">
          "{window.location.origin}
          {import.meta.env.VITE_APP_PUBLIC_URL}/api/project/organization/load-data/"
        </span>
        {t("component.credentialsManagerModal.description3")}
        <span className="bg-secondary bg-soft rounded px-1 mx-1">"secret_key"</span>
        {t("component.credentialsManagerModal.description4")}
        <span className="bg-secondary bg-soft rounded px-1 mx-1">"file"</span>
        {t("component.credentialsManagerModal.description5")}
      </p>
      <Row className="g-2">
        <Col className="d-flex  align-items-center" xs={"12"}>
          <Label className="mt-2 me-2">{t("common.name")}:</Label>

          <PRInput
            className="w-100"
            invalid={formik.touched.name && formik.errors.name}
            name="name"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Col>
        <Col xs></Col>
        <Col className="d-flex align-items-center" xs={"auto"}>
          {keepMissingMode
            ? t("component.credentialsManagerModal.updateAndCreate")
            : t("component.credentialsManagerModal.exactReplacement")}
          <PRTooltip title={t("component.credentialsManagerModal.updateModeTooltip")}>
            <span>
              <MdHelp className="ms-1 me-1" />
            </span>
          </PRTooltip>
          <Switch checked={keepMissingMode} inputProps={{ "aria-label": "controlled" }} onChange={handleSwitchChange} />
        </Col>
        <Col xs={"auto"}>
          <PRButton icon={MdKey} onClick={formik.handleSubmit}>
            {t("component.credentialsManagerModal.generate")}
          </PRButton>
        </Col>
      </Row>
      {generatedKey?.secret_key && (
        <div className="fs-5 text-center border border-2 border-orange p-2 my-4">
          <span className="fs-4">
            <div>
              <MdKey /> {generatedKey?.name}
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <span className="  bg-secondary bg-soft rounded px-1 mx-1">{generatedKey?.secret_key}</span>
              <PRButton
                noBorder
                outline
                color="secondary"
                icon={MdContentPaste}
                tooltipText="Copy to clipboard"
                onClick={handleClickCopyKey}
              />
            </div>
          </span>
          Do not share this key with anyone. You will not be able to see this key again. If you lose this key, you can
          create a new one.
        </div>
      )}

      <PRDividerLabel pageSeparator className="my-3" color="secondary">
        {t("dashboard.customerList.apiCredentials")}{" "}
      </PRDividerLabel>

      <PRTable
        ref={tableRef}
        inline
        noPagination
        columns={columns}
        responseDataCountKey={""}
        responseDataKey={""}
        url={apiUrlOrganization.getSecretKey.format(currentProject.id)}
      />
    </PRModal>
  );
}

const CredentialsManagerModal = withCardon(CredentialsManagerModalContent, { destroyOnHide: true });
export default CredentialsManagerModal;
