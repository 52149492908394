import { useEffect, useState } from "react";

import { withCardon } from "cardon";
import { useFormik } from "formik";
import { cloneDeep } from "lodash";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, Input, Label, Modal, Row } from "reactstrap";
import * as Yup from "yup";

import styled from "@emotion/styled";

import PRAccordion, { PRAccordionItem } from "~components/Generic/PRAccordion";
import PRButton from "~components/Generic/PRButton";
import { PRAgenda } from "~components/Generic/PRCalendar";
import PRDate from "~components/Generic/PRDate";
import PRInput from "~components/Generic/PRInput";
import { defaultModalZIndex } from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import { organizationDayOptions } from "~constants";
import DateHelper from "~helpers/DateHelper";
import { addResourceSlots } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";

const StyledPreviewContainer = styled.div`
  .accordion-body {
    max-height: 30vh;
    overflow: auto;
  }
`;

const isTimePairArrayFollowingNext = (timePairArray) => {
  return timePairArray.every((item) => {
    const [start, end] = item;
    if (!start || !end) return false;
    const [startHour, startMinute] = start.split(":");
    const [endHour, endMinute] = end.split(":");
    const startHourInt = parseInt(startHour);
    const startMinuteInt = parseInt(startMinute);

    const endHourInt = parseInt(endHour);
    const endMinuteInt = parseInt(endMinute);

    return startHourInt < endHourInt || (startHourInt === endHourInt && startMinuteInt < endMinuteInt);
  });
};
function AddBulkReservationSlots({ get, resourceId, dynamic }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const currentProject = useSelector(selectCurrentProject);
  const dispatch = useDispatch();
  const [eventPreviewList, setEventPreviewList] = useState([]);

  const [accordionState, setAccordionState] = useState({
    1: false,
  });

  const handleClickAccordion = (id) => () => {
    setAccordionState({
      ...accordionState,
      [id]: !accordionState[id],
    });
  };

  const formik = useFormik({
    initialValues: {
      from_date: DateHelper.getDateTimeLocal().startOf("day"),
      to_date: DateHelper.getDateTimeLocal().startOf("day"),
      resource_length: 25,
      break_length: 5,
      exclude_days: [5, 6],
      operation_hours: [["09:00", "10:00"]],
      slot_count: 1,
    },
    validationSchema: Yup.object().shape({
      from_date: Yup.date().required(
        t("component.formik.required").format(t("component.addBulkReservationSlots.fromDate"))
      ),
      to_date: Yup.date()
        .required(t("component.formik.required").format(t("component.addBulkReservationSlots.toDate")))
        .min(Yup.ref("from_date"), t("component.addBulkReservationSlots.dateError")),
      resource_length: Yup.number().required(
        t("component.formik.required").format(t("component.addBulkReservationSlots.resourceLength"))
      ),
      break_length: Yup.number().required(
        t("component.formik.required").format(t("component.addBulkReservationSlots.breakLength"))
      ),
      exclude_days: Yup.array(),
      operation_hours: Yup.array()
        .of(
          Yup.array().of(
            Yup.string().required(
              t("component.formik.required").format(t("component.addBulkReservationSlots.operationHours"))
            )
          )
        )
        .test(
          "end-time-greater-than-start-time",
          t("component.addBulkReservationSlots.timeError"),
          isTimePairArrayFollowingNext
        )
        .test("min-one-item", t("component.addBulkReservationSlots.minOneHour"), (value) => {
          return value.length > 0;
        })
        .test(
          "following-item-start-time-greater-than-previous-item-end-time",
          t("component.addBulkReservationSlots.followingTimeError"),
          (value) => {
            return value.every((item, index) => {
              if (index === 0) return true;
              const [start, end] = item;
              const [previousStart, previousEnd] = value[index - 1];
              if (!start || !end || !previousStart || !previousEnd) return false;
              const [startHour, startMinute] = start.split(":");
              const [endHour, endMinute] = end.split(":");
              const [previousEndHour, previousEndMinute] = previousEnd.split(":");
              const startHourInt = parseInt(startHour);
              const startMinuteInt = parseInt(startMinute);

              const endHourInt = parseInt(endHour);
              const endMinuteInt = parseInt(endMinute);

              const previousEndHourInt = parseInt(previousEndHour);
              const previousEndMinuteInt = parseInt(previousEndMinute);

              return (
                (startHourInt > previousEndHourInt ||
                  (startHourInt === previousEndHourInt && startMinuteInt >= previousEndMinuteInt)) &&
                (startHourInt < endHourInt || (startHourInt === endHourInt && startMinuteInt < endMinuteInt))
              );
            });
          }
        ),

      slot_count: Yup.number().required(
        t("component.formik.required").format(t("component.addBulkReservationSlots.slotCount"))
      ),
    }),

    onSubmit: async (values) => {
      setLoading(true);
      const payload = {
        ...values,
        resource_length: parseInt(values.resource_length),
        from_date: DateHelper.format(values.from_date),
        to_date: DateHelper.format(values.to_date),
        ...(dynamic && {
          break_length: 0,
        }),
      };
      try {
        await dispatch(addResourceSlots(payload, resourceId, currentProject.id));
        get(true)();
      } catch {}
      setLoading(false);
    },
  });

  const handleChangeDate = (key) => (momentDate) => {
    formik.setFieldValue(key, momentDate);
  };
  const handleChangeInput = (key) => (e) => {
    formik.setFieldValue(key, e.target.value);
  };
  const handleChangeSelect = (key) => (values) => {
    formik.setFieldValue(key, values);
  };

  const handleChangeOperationHours = (index, key) => (momentDate) => {
    const operationHours = [...formik.values.operation_hours];
    const momentToHHmm = momentDate.format("HH:mm");
    operationHours[index][key] = momentToHHmm;
    formik.setFieldValue("operation_hours", operationHours);
  };

  const handleAddOperationHours = () => {
    const operationHours = [...formik.values.operation_hours];
    operationHours.push(["", ""]);
    formik.setFieldValue("operation_hours", operationHours);
  };
  const handleRemoveOperationHours = (index) => () => {
    const operationHours = [...formik.values.operation_hours];
    operationHours.splice(index, 1);
    formik.setFieldValue("operation_hours", operationHours);
  };

  const operationIsInvalid = formik.touched.operation_hours && formik.errors.operation_hours;

  useEffect(() => {
    function findMeetingTimes(
      startDate,
      endDate,
      timeArray,
      excludeDayArray,
      sessionLength,
      breakLength,
      slot,
      dynamic = false
    ) {
      let start = moment(startDate);
      let end = moment(endDate);
      let times = [];

      if (dynamic) {
        // Eğer dynamic true ise, sessionLength, breakLength ve slot önemli değil, operation_hours üzerinden işlemler yapılacak
        if (!timeArray || !startDate || !endDate) return times;

        while (start <= end) {
          start.isoWeekday();
          let day = (start.day() + 6) % 7;
          if (!excludeDayArray.includes(day)) {
            for (let i = 0; i < timeArray.length; i++) {
              const startHourInt = parseInt(timeArray[i][0].split(":")[0]);
              const startMinuteInt = parseInt(timeArray[i][0].split(":")[1]);
              const endHourInt = parseInt(timeArray[i][1].split(":")[0]);
              const endMinuteInt = parseInt(timeArray[i][1].split(":")[1]);

              let startTime = moment(start).hours(startHourInt).minutes(startMinuteInt);
              let endTime = moment(start).hours(endHourInt).minutes(endMinuteInt);

              const event = {
                start: startTime.format(),
                end: endTime.format(),
                title: "Slot",
                available: true,
                allDay: startTime.startOf("day").isSame(endTime.endOf("day")),
              };
              times.push(event);
            }
          }
          start.add(1, "d");
        }
      } else {
        const meetLength = sessionLength + breakLength;
        const ifEmptyTimePairExist = timeArray.some((item) => !item[0] || !item[1]);
        const ifTimesFollowNext = isTimePairArrayFollowingNext(timeArray);
        if (
          breakLength < 0 ||
          sessionLength < 0 ||
          slot < 0 ||
          !timeArray ||
          !excludeDayArray ||
          !startDate ||
          !endDate ||
          !meetLength ||
          ifEmptyTimePairExist ||
          !ifTimesFollowNext
        )
          return times;

        while (start <= end) {
          start.isoWeekday();
          let day = (start.day() + 6) % 7;
          if (!excludeDayArray.includes(day)) {
            for (let i = 0; i < timeArray.length; i++) {
              const startHourInt = parseInt(timeArray[i][0].split(":")[0]);
              const startMinuteInt = parseInt(timeArray[i][0].split(":")[1]);
              const endHourInt = parseInt(timeArray[i][1].split(":")[0]);
              const endMinuteInt = parseInt(timeArray[i][1].split(":")[1]);

              let startTime = moment(start).hours(startHourInt).minutes(startMinuteInt);
              let endTime = moment(start).hours(endHourInt).minutes(endMinuteInt);

              let tempStart = start.clone();

              while ((startTime >= tempStart || tempStart < endTime) && startTime.isSame(tempStart, "day")) {
                let meetingStart = moment(startTime);
                let meetingEnd = moment(startTime).add(meetLength, "m");

                if (meetingEnd <= endTime) {
                  let isValid = true;
                  for (let j = 0; j < times.length; j++) {
                    let prevStart = moment(times[j][0]);
                    let prevEnd = moment(times[j][1]);
                    if (prevStart < meetingEnd && prevEnd > meetingStart) {
                      isValid = false;
                      break;
                    }
                  }
                  if (isValid) {
                    const event = {
                      start: meetingStart.format(),
                      end: meetingEnd.clone().add(-breakLength, "m").format(),
                      title: t("common.slot"),
                      available: true,
                      allDay: meetingStart.startOf("day").isSame(meetingEnd.endOf("day")),
                    };
                    for (let k = 0; k < slot; k++) {
                      times.push(cloneDeep(event));
                    }
                  }
                }
                startTime.add(meetLength, "m");
              }
            }
          }
          start.add(1, "d");
        }
      }

      return times;
    }
    const meetings = findMeetingTimes(
      formik.values.from_date,
      formik.values.to_date,
      formik.values.operation_hours,
      formik.values.exclude_days,
      parseInt(formik.values.resource_length),
      parseInt(formik.values.break_length),
      parseInt(formik.values.slot_count),
      dynamic
    );
    setEventPreviewList(meetings);
  }, [
    formik.values.resource_length,
    formik.values.break_length,
    formik.values.slot_count,
    formik.values.from_date,
    formik.values.to_date,
    formik.values.exclude_days,
    formik.values.operation_hours,
    dynamic,
    t,
  ]);

  return (
    <Modal centered isOpen={true} size="lg" zIndex={defaultModalZIndex}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Add Bulk Slots</h5>
        <button aria-label="Close" className="close" data-dismiss="modal" onClick={get(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body mb-3">
        <Row className="g-2">
          <Col lg={6} xs="12">
            <Label>{t("component.addBulkReservationSlots.fromDate")}</Label>
            <PRDate
              invalid={formik.touched.from_date && formik.errors.from_date}
              placeholder={t("component.addBulkReservationSlots.fromDatePlaceholder")}
              timeFormat={false}
              value={formik.values.from_date}
              onChange={handleChangeDate("from_date")}
            />
          </Col>
          <Col lg={6} xs="12">
            <Label>{t("component.addBulkReservationSlots.toDate")}</Label>
            <PRDate
              invalid={formik.touched.to_date && formik.errors.to_date}
              placeholder={t("component.addBulkReservationSlots.toDatePlaceholder")}
              timeFormat={false}
              value={formik.values.to_date}
              onChange={handleChangeDate("to_date")}
            />
          </Col>
          {!dynamic && (
            <>
              <Col xs={4}>
                <Label>{t("component.addBulkReservationSlots.resourceLength")}</Label>
                <PRInput
                  invalid={formik.touched.resource_length && formik.errors.resource_length}
                  placeholder={t("component.addBulkReservationSlots.resourceLengthPlaceholder")}
                  type="number"
                  value={formik.values.resource_length}
                  onChange={handleChangeInput("resource_length")}
                />
              </Col>
              <Col xs={4}>
                <Label>{t("component.addBulkReservationSlots.breakLength")}</Label>
                <PRInput
                  invalid={formik.touched.break_length && formik.errors.break_length}
                  placeholder={t("component.addBulkReservationSlots.breakLengthPlaceholder")}
                  type="number"
                  value={formik.values.break_length}
                  onChange={handleChangeInput("break_length")}
                />
              </Col>
              <Col xs={4}>
                <Label>{t("component.addBulkReservationSlots.slotCount")}</Label>
                <PRInput
                  invalid={formik.touched.slot_count && formik.errors.slot_count}
                  placeholder={t("component.addBulkReservationSlots.slotCountPlaceholder")}
                  type="number"
                  value={formik.values.slot_count}
                  onChange={handleChangeInput("slot_count")}
                />
              </Col>
            </>
          )}
          <Col xs={12}>
            <Label>Exclude days</Label>
            <PRSelect
              isMulti
              isPrimitiveValue
              isClearable={false}
              options={organizationDayOptions}
              value={formik.values.exclude_days}
              onChange={handleChangeSelect("exclude_days")}
            />
          </Col>
          <Col xs={12}>
            <Label>{t("component.addBulkReservationSlots.operationHours")}</Label>
            <Row className="g-2" tag="ul">
              {formik.values.operation_hours.map((item, index) => {
                const start = item?.[0] ? DateHelper.getDateTime(item?.[0], "HH:mm", true) : null;
                const end = item?.[1] ? DateHelper.getDateTime(item?.[1], "HH:mm", true) : null;
                return (
                  <Col key={index} tag="li" xs={12}>
                    <Row className="g-2">
                      <Col xs>
                        <PRDate
                          dateFormat={false}
                          placeholder={t("component.addBulkReservationSlots.startTime")}
                          value={start}
                          onChange={handleChangeOperationHours(index, 0)}
                        />
                      </Col>
                      <Col xs>
                        <PRDate
                          dateFormat={false}
                          placeholder={t("component.addBulkReservationSlots.endTime")}
                          value={end}
                          onChange={handleChangeOperationHours(index, 1)}
                        />
                      </Col>
                      <Col xs="auto">
                        <PRButton outline color="danger" icon={MdDelete} onClick={handleRemoveOperationHours(index)} />
                      </Col>
                    </Row>
                  </Col>
                );
              })}
              <Col xs={12}>
                <Input className="d-none m-0" invalid={operationIsInvalid} />
                {operationIsInvalid && (
                  <FormFeedback type="invalid">
                    {Array.isArray(operationIsInvalid) ? operationIsInvalid.flat().find((a) => a) : operationIsInvalid}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <div className="d-flex justify-content-end">
                  <PRButton outline color="primary" icon={MdAdd} onClick={handleAddOperationHours} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="pr-calendar" xs={12}>
            <StyledPreviewContainer>
              <PRAccordion>
                <PRAccordionItem
                  noSpacing
                  secondary
                  collapsed={accordionState[1]}
                  title={t("component.addBulkReservationSlots.slotPreview").format(eventPreviewList.length.toString())}
                  onClick={handleClickAccordion(1)}
                >
                  <div className="mx-2 my-2">
                    <PRAgenda
                      disableCollapse
                      hideShowAll
                      columnRatio={[4, 8]}
                      events={accordionState[1] ? eventPreviewList : []}
                    />
                  </div>
                </PRAccordionItem>
              </PRAccordion>
            </StyledPreviewContainer>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <PRButton outline color="primary" data-dismiss="modal" onClick={get(false)}>
          {t("common.close")}
        </PRButton>
        <PRButton color="primary" disabled={loading} loading={loading} type="button" onClick={formik.handleSubmit}>
          {t("common.create")}
        </PRButton>
      </div>
    </Modal>
  );
}
const AddBulkReservationSlotsModal = withCardon(AddBulkReservationSlots, { destroyOnHide: true });
export default AddBulkReservationSlotsModal;
