import { useEffect, useMemo, useRef, useState } from "react";

import { withCardon } from "cardon";
import { useFormik } from "formik";
import { cloneDeep, isObject } from "lodash";
import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import useLoading from "~common/hooks/useLoading";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput, { PRTextArea } from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  apiUrlChatbot,
  dialogComponentsVariableTypeOptions,
  dialogComponentsVariableTypes,
  tableFilterStorageKey,
} from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import Utils from "~helpers/Utils";
import { createOrUpdateVariable, deleteVariable } from "~store/dialogComponents/scenarioManager/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

function renameKey(obj, oldKey, newKey) {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (key === oldKey) {
      newObj[newKey] = value;
    } else {
      newObj[key] = value;
    }
  });

  return newObj;
}

const AddEditVariableModal = withCardon(
  function AddEditVariable({ get, variable = {} }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const [tab, setTab] = useState("array");
    const currentProject = useSelector(selectCurrentProject);
    const currentBot = useSelector(selectCurrentBot);
    const [loading, q] = useLoading();

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        id: variable.id,
        name: variable.name || "",
        variable_type: variable.variable_type || dialogComponentsVariableTypes.STR,
        value: variable.value,
      },
      validationSchema: Yup.object({
        name: Yup.string()
          .required(t("component.formik.required").format(t("common.name")))
          .test("test-variable", t("component.formik.notValid").format(t("common.variable")), function (value) {
            const { valid, reason } = Utils.isValidPythonVariableName(value);
            if (valid) return true;
            return this.createError({ message: reason });
          }),
        variable_type: Yup.string().required(
          t("component.formik.required").format(t("dashboard.variables.variableType"))
        ),

        value: Yup.mixed()
          .when("variable_type", {
            is: (variable_type) => variable_type === dialogComponentsVariableTypes.STR,
            then: Yup.string().required(t("component.formik.required").format(t("common.value"))),
          })
          .when("variable_type", {
            is: (variable_type) => variable_type === dialogComponentsVariableTypes.INT,
            then: Yup.number()
              .required(t("component.formik.required").format(t("common.value")))
              .test("must-be-int", t("component.addEditVariableModal.invalidValue"), (value) => {
                return Number.isInteger(value);
              }),
          })
          .when("variable_type", {
            is: (variable_type) => variable_type === dialogComponentsVariableTypes.FLOAT,
            then: Yup.number().required(t("component.formik.required").format(t("common.value"))),
          })
          .when("variable_type", {
            is: (variable_type) => variable_type === dialogComponentsVariableTypes.JSON,
            then: Yup.mixed().test(
              "test-json",
              t("component.formik.invalid").format(t("common.JSON")),
              function (value) {
                if (typeof value === "object") return true;
                try {
                  JSON.parse(value);
                  return true;
                } catch (error) {
                  return this.createError({ message: t("component.formik.invalid").format(t("common.JSON")) });
                }
              }
            ),
          }),
      }),

      onSubmit: async (values) => {
        try {
          const payload = cloneDeep(values);
          if (payload.variable_type === dialogComponentsVariableTypes.JSON && typeof payload.value !== "string") {
            payload.value = JSON.stringify(payload.value);
          }
          await q(dispatch(createOrUpdateVariable(currentProject.id, currentBot.id, payload)));
        } catch (error) {}
        get(true)();
      },
    });

    useEffect(() => {
      if (variable.variable_type === dialogComponentsVariableTypes.JSON) {
        let parsed;
        try {
          parsed = JSON.parse(variable.value);
        } catch (error) {}
        if (Array.isArray(parsed) && parsed.every((item) => typeof item === "string")) {
          setTab("array");
          formik.setFieldValue("value", parsed);
        } else if (
          !Array.isArray(parsed) &&
          typeof parsed === "object" &&
          Object.values(parsed).every((item) => typeof item === "string")
        ) {
          setTab("object");
          formik.setFieldValue("value", parsed);
        } else {
          setTab("json");
        }
      }
    }, [variable]);

    const handleChangeSelect = (value) => {
      formik.setFieldValue("variable_type", value);
    };

    const handleAddArrayItem = () => {
      const currentArray = Array.isArray(formik.values.value) ? formik.values.value : [];
      formik.setFieldValue("value", [...currentArray, ""]);
    };
    const handleKeyDownAddArrayItem = (e) => {
      if (e.key === "Enter") {
        handleAddArrayItem();
      }
    };

    const handleRemoveArrayItem = (index) => () => {
      const currentArray = Array.isArray(formik.values.value) ? formik.values.value : [];
      currentArray.splice(index, 1);
      formik.setFieldValue("value", [...currentArray]);
    };
    const handleChangeArrayItem = (index) => (e) => {
      const value = e.target.value;
      const currentArray = Array.isArray(formik.values.value) ? formik.values.value : [];
      currentArray[index] = value;
      formik.setFieldValue("value", [...currentArray]);
    };

    const handleAddObjectItem = () => {
      const currentObject =
        typeof formik.values.value === "object" && !Array.isArray(formik.values.value) ? formik.values.value : {};
      const totalKeyCount = Object.keys(currentObject).length;
      formik.setFieldValue("value", { ...currentObject, [`key_${totalKeyCount + 1}`]: "" });
    };
    const handleKeydownAddObjectItem = (e) => {
      if (e.key === "Enter") {
        handleAddObjectItem();
      }
    };

    const handleRemoveObjectItem = (key) => () => {
      const currentObject =
        typeof formik.values.value === "object" && !Array.isArray(formik.values.value) ? formik.values.value : {};
      delete currentObject[key];
      formik.setFieldValue("value", { ...currentObject });
    };
    const handleChangeObjectItem =
      (key, isValue = false) =>
      (e) => {
        const value = e.target.value;
        let currentObject =
          typeof formik.values.value === "object" && !Array.isArray(formik.values.value) ? formik.values.value : {};
        if (isValue) {
          currentObject[key] = value;
        } else {
          currentObject = renameKey(currentObject, key, value);
        }
        formik.setFieldValue("value", { ...currentObject });
      };

    const handleChangeJson = (e) => {
      let value = e.target.value;
      // try {
      //   value = JSON.parse(value);
      // } catch (error) {}
      formik.setFieldValue("value", value);
    };

    const handleTabChange = (tab) => {
      setTab(tab);
      if (tab === "array") {
        let arrValue = formik.values.value;
        if (typeof formik.values.value === "string") {
          try {
            arrValue = JSON.parse(formik.values.value);
          } catch (error) {}
        }
        if (!Array.isArray(arrValue) || !arrValue.every((item) => typeof item === "string")) {
          arrValue = [];
        }
        formik.setFieldValue("value", arrValue);
      } else if (tab === "object") {
        let objValue = formik.values.value;
        if (typeof formik.values.value === "string") {
          try {
            objValue = JSON.parse(formik.values.value);
          } catch (error) {}
        }
        if (
          Array.isArray(objValue) ||
          !isObject(objValue) ||
          !Object.values(objValue).every((item) => typeof item === "string")
        ) {
          objValue = {};
        }
        formik.setFieldValue("value", objValue);
      } else {
        formik.setFieldValue(
          "value",
          typeof formik.values.value === "object" ? JSON.stringify(formik.values.value) : formik.values.value
        );
      }
    };

    const handleValueChange = (e) => {
      console.log(textRef.current);
      if (textRef && textRef.current) {
        textRef.current.style.height = "0px";
        const taHeight = e.target.scrollHeight;
        textRef.current.style.height = taHeight + "px";
      }
      formik.handleChange(e);
    };

    return (
      <PRModal
        loading={loading}
        size="lg"
        submitText={variable?.id ? t("common.update") : t("common.create")}
        title={
          variable?.id
            ? t("component.addEditVariableModal.editVariable")
            : t("component.addEditVariableModal.addVariable")
        }
        onClick={formik.handleSubmit}
        onClose={get(false)}
      >
        <Row className="g-2">
          <Col xs={12}>
            <Label>{t("common.name")}</Label>
            <PRInput
              invalid={formik.touched.name && formik.errors.name}
              name="name"
              placeholder={t("common.name")}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs={12}>
            <Label>{t("dashboard.variables.variableType")}</Label>
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.variable_type && formik.errors.variable_type}
              isClearable={false}
              name="variable_type"
              options={dialogComponentsVariableTypeOptions}
              placeholder={t("dashboard.variables.variableType")}
              value={formik.values.variable_type}
              onChange={handleChangeSelect}
            />
          </Col>

          {formik.values.variable_type === dialogComponentsVariableTypes.JSON ? (
            <Col xs={12}>
              <PRTab
                tab={tab}
                tabList={[
                  {
                    id: "array",
                    label: t("common.array"),
                  },
                  {
                    id: "object",
                    label: t("component.addEditVariableModal.keyValuePair"),
                  },
                  {
                    id: "json",
                    label: t("common.JSON"),
                  },
                ]}
                onChange={handleTabChange}
              />

              {tab === "array" && (
                <div className="mt-2">
                  <div className="d-flex align-items-center">
                    <span>
                      {t("component.addEditVariableModal.arrayDescription1")}
                      <span className="fw-semibold" style={{ whiteSpace: "pre" }}>
                        {t("component.addEditVariableModal.arrayDescription2") + " "}
                      </span>
                      {t("component.addEditVariableModal.arrayDescription3")}
                    </span>
                    <PRButton className="ms-auto" color="success" icon={MdAdd} onClick={handleAddArrayItem}></PRButton>
                  </div>
                  <div className="d-flex flex-column gap-2 mt-2">
                    {Array.isArray(formik.values.value) &&
                      formik.values.value.map((item, index) => (
                        <div key={index}>
                          <Row className="g-2 align-items-center">
                            <Col xs>
                              <PRInput
                                autoFocus={index === formik.values.value.length - 1}
                                name={`value_${index}`}
                                placeholder={t("common.value")}
                                value={item}
                                onChange={handleChangeArrayItem(index)}
                                onKeyDown={handleKeyDownAddArrayItem}
                              />
                            </Col>
                            <Col xs="auto">
                              <PRButton color="danger" icon={MdDelete} onClick={handleRemoveArrayItem(index)} />
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {tab === "object" && (
                <div className="mt-2">
                  <div className="d-flex align-items-center">
                    <span>
                      {t("component.addEditVariableModal.objectDescription1")}
                      <span className="fw-semibold" style={{ whiteSpace: "pre" }}>
                        {t("component.addEditVariableModal.objectDescription2") + " "}
                      </span>
                      {t("component.addEditVariableModal.objectDescription3")}
                    </span>
                    <PRButton className="ms-auto" color="success" icon={MdAdd} onClick={handleAddObjectItem}></PRButton>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex flex-column gap-2 mt-2">
                      {isObject(formik.values.value) &&
                        Object.keys(formik.values.value).map((item, index) => (
                          <div key={index}>
                            <Row className="g-2 align-items-center">
                              <Col xs>
                                <PRInput
                                  autoFocus={index === Object.keys(formik.values.value).length - 1}
                                  name={`key_${index}`}
                                  placeholder={t("common.key")}
                                  value={item}
                                  onChange={handleChangeObjectItem(item)}
                                  onKeyDown={handleKeydownAddObjectItem}
                                />
                              </Col>
                              <Col xs="auto">
                                <div className="text-center">:</div>
                              </Col>
                              <Col xs>
                                <PRInput
                                  name={`value_${index}`}
                                  placeholder={t("common.value")}
                                  value={formik.values.value[item]}
                                  onChange={handleChangeObjectItem(item, true)}
                                  onKeyDown={handleKeydownAddObjectItem}
                                />
                              </Col>
                              <Col xs="auto">
                                <PRButton color="danger" icon={MdDelete} onClick={handleRemoveObjectItem(item)} />
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {tab === "json" && (
                <div className="mt-2">
                  <PRTextArea
                    editorMode
                    editorProps={{
                      defaultLanguage: "json",
                      defaultHeight: 400,
                    }}
                    invalid={formik.touched.value && formik.errors.value}
                    name="value"
                    placeholder={t("common.JSON")}
                    rows={10}
                    value={formik.values.value}
                    onChange={handleChangeJson}
                  />
                </div>
              )}
            </Col>
          ) : (
            <Col xs={12}>
              <Label>{t("common.value")}</Label>
              <PRTextArea
                innerRef={textRef}
                invalid={formik.touched.value && formik.errors.value}
                name="value"
                placeholder={t("common.value")}
                rows={1}
                type={formik.values.variable_type === dialogComponentsVariableTypes.STR ? "text" : "number"}
                value={formik.values.value}
                onBlur={formik.handleBlur}
                onChange={handleValueChange}
              />
            </Col>
          )}
        </Row>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

function Variables() {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const handleClickAddEditVariable = (variable) => async () => {
    if (await AddEditVariableModal.show({ variable })) {
      tableRef.current.refresh();
    }
  };

  const columns = useMemo(() => {
    const handleClickDelete = (id, row) => async () => {
      if (await DialogHelper.showQuestionDeleteWithFormat(row.name)) {
        await dispatch(deleteVariable(currentProject.id, currentBot.id, id));
        tableRef.current.refresh();
      }
    };
    return [
      {
        label: t("common.name"),
        key: "name",
      },
      {
        label: t("dashboard.variables.variableType"),
        key: "variable_type",
        render: (row) => {
          return (
            t(dialogComponentsVariableTypeOptions.find((item) => item.value === row.variable_type)?.label) ||
            t(row.variable_type)
          );
        },
      },
      {
        label: t("common.value"),
        key: "value",
        render: (row) => {
          let truncatedValue = row.value;
          if (truncatedValue?.length > 4096) {
            truncatedValue = truncatedValue.substring(0, 4096) + "...";
          }
          if (row.variable_type === dialogComponentsVariableTypes.JSON) {
            let jsonObj, prettyJson;
            try {
              jsonObj = JSON.parse(truncatedValue);
              prettyJson = JSON.stringify(jsonObj, null, 2);
            } catch (error) {
              prettyJson = truncatedValue;
            }
            if (Array.isArray(jsonObj) && jsonObj.every((item) => typeof item === "string")) {
              return (
                <>
                  {jsonObj?.map((value) => {
                    return (
                      <Badge key={value} className="badge-soft-secondary me-1">
                        <span className="font-size-12">{value}</span>
                      </Badge>
                    );
                  })}
                </>
              );
            } else if (
              !Array.isArray(jsonObj) &&
              typeof jsonObj === "object" &&
              Object.values(jsonObj).every((item) => typeof item === "string")
            ) {
              return (
                <>
                  {Object.entries(jsonObj)?.map(([key, value]) => {
                    return (
                      <Badge key={value} className="badge-soft-secondary me-1">
                        <span className="font-size-12">
                          <b className="me-1">{key}:</b>
                          {value}
                        </span>
                      </Badge>
                    );
                  })}
                </>
              );
            }
            if (prettyJson?.length > 1000) {
              const prettyJsonMin = prettyJson.substring(0, 1000) + "...";
              return (
                <PRTooltip placement="bottom" title={prettyJson}>
                  <span>{prettyJsonMin}</span>
                </PRTooltip>
              );
            }
            return prettyJson;
          }
          return truncatedValue;
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton // TODO: Ask put from BE for edit
              outline
              color="primary"
              icon={MdEdit}
              size="sm"
              tooltipText={t("common.edit")}
              onClick={handleClickAddEditVariable(row)}
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleClickDelete(row.id, row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, currentProject.id, currentBot.id, t]);

  const actions = [
    {
      label: t("common.createNew"),
      icon: MdAdd,
      color: "success",
      onClick: handleClickAddEditVariable(),
    },
  ];

  const filters = useMemo(
    () => [
      {
        key: "variable_type",
        options: dialogComponentsVariableTypeOptions,
      },
    ],
    []
  );
  const parentName = [
    {
      label: t("common.variables"),
      url: "/chatbot/variables/",
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      actions={actions}
      description={t("dashboard.variables.description")}
      name={t("common.chatbot")}
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.variablesList}
        url={apiUrlChatbot.getVariable.format(currentProject.id, currentBot.id)}
      />
    </PRContainer>
  );
}

export default Variables;
