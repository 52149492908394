import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import chroma from "chroma-js";
import { sortBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";
import { Box, Popper } from "@mui/material";

import { useStorage } from "~common/hooks/useStorage";
import { LS_TEMPLATE_POPUP_HISTORY } from "~constants";
import { getDefaultMessageList } from "~store/knowledgeBase/actions";
import { selectCurrentProject } from "~store/user/selectors";

const UsageDot = styled.div`
  background-color: ${(props) => props.color};
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 5px;
`;

function AutoColorBadge({ selected, lastUsed, ...rest }) {
  const [timeDiff, setTimeDiff] = useState(0);

  useEffect(() => {
    const intervalFunc = () => {
      const currentTime = Date.now();
      const diff = currentTime - (lastUsed ?? 0);
      setTimeDiff(diff);
    };
    const interval = setInterval(intervalFunc, 1000);
    intervalFunc();
    return () => {
      clearInterval(interval);
    };
  }, [selected, lastUsed]);

  let style = {};
  if (!selected) {
    const diffMin = 10 * 60 * 1000; // 5 minutes
    const targetColor = "#6c757d";
    const lux = 0.32;
    if (lastUsed && timeDiff <= diffMin) {
      const colorScale = chroma.scale(["#F46A6A", targetColor]);
      const colorValue = colorScale(timeDiff / diffMin)
        .luminance(lux)
        .hex();

      style = {
        backgroundColor: `${colorValue}`,
      };
    } else {
      const luxTargetColor = chroma(targetColor).luminance(lux).hex();
      style = {
        backgroundColor: luxTargetColor,
      };
    }
  }

  return <UsageDot color={style.backgroundColor} style={{ marginRight: 0 }} />;
}
function replaceTags(text, obj) {
  const pattern = /<<([^>]+)>>/g;
  const matches = text.match(pattern);

  if (matches) {
    for (let i = 0; i < matches.length; i++) {
      const tag = matches[i].substring(2, matches[i].length - 2);
      if (obj.hasOwnProperty(tag)) {
        const replacement = obj[tag];
        text = text.replace(matches[i], replacement);
      }
    }
  }

  return text;
}

const StyledPopper = styled(Popper)`
  z-index: 1250;
  background-color: #fff;
`;
const StyledPopperBox = styled(Box)`
  border-radius: 14px;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #e8e8e8;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
`;

const AutoCompleteLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.7);
  padding: 7px 10px;
  opacity: 0.8;
  border-radius: 2px;
  background-color: #fff;

  &:hover {
    background-color: #f8f8f8;
    opacity: 1;
  }
  cursor: pointer;
`;

function TemplatePopup({ onSubmit, variableMap, projectId }) {
  const popperContentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [targetDom, setTargetDom] = useState(null);
  const [width, setWidth] = useState(0);
  const currentProject = useSelector(selectCurrentProject);
  const [usageHistory, setUsageHistory] = useStorage(LS_TEMPLATE_POPUP_HISTORY, {});
  const [textareaText, setTextareaText] = useState("");
  const dispatch = useDispatch();

  const [templateMessageList, setTemplateMessageList] = useState();
  useEffect(() => {
    const dom = document.getElementById("chat-textbox");
    const inputDom = document.getElementById("chat-textbox-input");
    setTargetDom(dom);
    const onChangeText = (text) => {
      setTextareaText(text);
    };
    if (inputDom) {
      inputDom.addEventListener("input", (e) => {
        onChangeText(e.target.value);
      });
      inputDom.addEventListener("dblclick", (e) => {
        setOpen(true);
      });
      // handle ctrl + space for win and cmd + space for mac
      inputDom.addEventListener("keydown", (e) => {
        if (e.key === " " && (e.ctrlKey || e.metaKey)) {
          setOpen(true);
        }
      });
    }

    dispatch(getDefaultMessageList(projectId || currentProject?.id, { loading: false, params: { limit: 9999 } })).then(
      (res) => {
        setTemplateMessageList(res.results);
      }
    );

    return () => {
      if (inputDom) {
        inputDom.removeEventListener("input", onChangeText);
      }
    };
  }, [dispatch, projectId, currentProject]);

  useEffect(() => {
    //handle global ESC key
    const handleEsc = (e) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    //handle global click and if out of popup, close popup
    const handleClick = (e) => {
      if (!popperContentRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (!targetDom) return;
    const observer = new ResizeObserver(() => {
      setWidth(targetDom.offsetWidth);
    });
    observer.observe(targetDom);
    return () => {
      observer.disconnect();
    };
  }, [targetDom]);

  const orderedTemplateList = useMemo(() => {
    if (!templateMessageList?.length) return [];
    let orderedList = [...templateMessageList];
    orderedList = sortBy(orderedList, (item) => {
      return -usageHistory?.[item?.id]?.lastUsed ?? 0;
    });

    return orderedList;
  }, [templateMessageList, usageHistory]);

  const filteredTemplateList = useMemo(() => {
    if (!textareaText) return orderedTemplateList;
    return orderedTemplateList.filter((item) => {
      return item.title?.toLowerCase().includes(textareaText.toLowerCase());
    });
  }, [orderedTemplateList, textareaText]);

  const handleSendTemplate = useCallback(
    (item) => (e) => {
      const mappedText = replaceTags(item?.data, variableMap);

      onSubmit?.(e, mappedText);
      setUsageHistory({
        ...usageHistory,

        [item?.id]: {
          ...usageHistory?.[item?.id],
          count: (usageHistory?.[item?.id]?.count ?? 0) + 1,
          lastUsed: Date.now(),
        },
      });
    },
    [onSubmit, usageHistory, setUsageHistory, variableMap]
  );

  useEffect(() => {
    setOpen(!!textareaText && filteredTemplateList?.length);
  }, [textareaText, filteredTemplateList]);

  return (
    <>
      <StyledPopper anchorEl={targetDom} open={open} sx={{ width: width }}>
        <StyledPopperBox ref={popperContentRef}>
          {!!filteredTemplateList.length &&
            filteredTemplateList.map((item) => {
              const historyItem = usageHistory?.[item.id];

              return (
                <AutoCompleteLabel key={item.id} onClick={handleSendTemplate(item)}>
                  <AutoColorBadge key={item.id} lastUsed={historyItem?.lastUsed} />
                  {item.title}
                </AutoCompleteLabel>
              );
            })}
        </StyledPopperBox>
      </StyledPopper>
    </>
  );
}

export default TemplatePopup;
