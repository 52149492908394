import React, { useEffect } from "react";

import { useFormik } from "formik";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import styled from "@emotion/styled";
import { Grid, Alert } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

import { ReactComponent as LockSvg } from "~assets/images/lock.svg";
import { ReactComponent as Logo } from "~assets/images/palmate-logo.svg";
import useQueryParams from "~common/hooks/useQueryParams";
import Footer from "~components/Layout/Footer";
import PalButton from "~components/mui/PalButton";
import PalCheckbox from "~components/mui/PalCheckbox";
import PalLink from "~components/mui/PalLink";
import PalTextField from "~components/mui/PalTextField";
import { LS_TOKEN, LS_TOKEN_REFRESH, userAuthenticationMethod } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import StorageHelper from "~helpers/StorageHelper";
import TokenHelper from "~helpers/TokenHelper";
import Utils from "~helpers/Utils";
import { getCurrentUser, login, validate2FA } from "~store/user/user.actions";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  padding: 30px 50px;
  @media (max-width: 600px) {
    padding: 30px 20px;
  }
  overflow: auto;
  .app-logo {
  }
  .input-box {
    max-width: 360px;
    margin: 100px auto auto auto;
    @media (max-width: 600px) {
      max-width: 100%;
      margin: 30px auto auto;
    }
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .title {
      color: ${(props) => props.theme.palette.primary.dark};
      //
      font: normal normal 600 36px/34px MuseoModerno;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .desc {
      font: normal normal normal 22px/28px Museo Sans;
      color: ${(props) => props.theme.palette.primary.dark};
      letter-spacing: 0;
      margin-bottom: 29px;
      // text-align: center;
    }
    .checkbox {
      margin: -12px 0px -6px 0px;
    }
    .forgot-password {
      color: ${(props) => props.theme.palette.primary.light};

      font: normal normal normal 16px/20px Museo Sans;
      letter-spacing: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
    // }
  }
  .login-button {
    font-weight: bold;
    background-color: ${(props) => props.theme.palette.primary.light};
    border-color: ${(props) => props.theme.palette.primary.light};
  }
  .create-account-button {
    font-size: 16px;
    font-weight: 500;
  }
`;

const Login2 = () => {
  //meta title
  const { t } = useTranslation();
  const { return: returnUrl } = useQueryParams();
  const redirectUrl = returnUrl || "/dashboard/project/0/bot/0/";
  const dispatch = useDispatch();
  const [showOtp, setShowOtp] = React.useState(false);
  const [otpValue, setOtpValue] = React.useState("");
  const [autoSubmittedAtInitialComplete, setAutoSubmittedAtInitialComplete] = React.useState(false);
  const [twoFactorToken, setTwoFactorToken] = React.useState("");
  const [refreshToken, setRefreshToken] = React.useState("");
  const [alertInfo, setAlertInfo] = React.useState({
    msg: "",
    color: "success",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required(t("public.login.usernameRequired"))
        .min(3, t("public.login.usernameMin").format("3"))
        .test("no-whitespace", t("public.login.usernameNoWhitespace"), (value) => !/\s/.test(value)),
      password: Yup.string().required(t("public.login.passwordRequired")),
    }),
    validate: (values) => {
      const errors = {};
      if (values.username.includes("@")) {
        if (!Utils.validateEmail(values.username)) {
          errors.username = t("component.formik.emailInvalid");
        }
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (showOtp) {
        setAlertInfo({
          msg: "",
          color: "success",
        });
        const responseRaw = await dispatch(
          validate2FA(otpValue, {
            noAlert: true,
            rawResponse: true,
            noThrow: true,
            bearerToken: twoFactorToken,
          })
        );
        if (200 !== responseRaw?.status) {
          setAlertInfo({
            msg: responseRaw?.data?.detail || t("public.login.otpInvalid"),
            color: "error",
          });
        } else {
          StorageHelper.set(LS_TOKEN, twoFactorToken);
          if (formik.values.rememberMe) {
            StorageHelper.set(LS_TOKEN_REFRESH, refreshToken);
          } else {
            StorageHelper.remove(LS_TOKEN_REFRESH);
          }
          HistoryHelper.push(redirectUrl);
        }
        return;
      } else {
        loginUser({
          username: values.username?.normalizeAccent("", true)?.toLowerCase()?.trim(),
          password: values.password?.trim(),
        });
      }
    },
  });

  useEffect(() => {
    document.title = t("public.login.title");
  }, [t]);

  useEffect(() => {
    TokenHelper.getJwtIfValid().then((jwt) => {
      if (jwt) {
        HistoryHelper.push(redirectUrl);
      }
    });
  }, [redirectUrl]);

  const handleChangeOtpValue = (newValue) => {
    setOtpValue(newValue);
  };
  async function loginUser(values) {
    setAlertInfo({});
    setIsLoading(true);
    try {
      const response = await dispatch(login(values));
      if (response?.preferred_2fa_method === userAuthenticationMethod.email) {
        setAlertInfo({
          msg: t("public.login.otpEmailSent"),
          color: "info",
        });
      } else if (response?.preferred_2fa_method === userAuthenticationMethod.authenticator) {
        setAlertInfo({
          msg: t("public.login.otpAuthenticatorSent"), //TODO: Check the translation
          color: "info",
        });
      }
      const userInfo = await dispatch(
        getCurrentUser({
          noThrow: true,
          bearerToken: response.access,
          noAlert: true,
        })
      );
      if (userInfo?.code === "mfa_wait_session") {
        setTwoFactorToken(response.access);
        setRefreshToken(response.refresh);
        setShowOtp(true);
      } else {
        StorageHelper.set(LS_TOKEN, response.access);
        if (formik.values.rememberMe) {
          StorageHelper.set(LS_TOKEN_REFRESH, response.refresh);
        } else {
          StorageHelper.remove(LS_TOKEN_REFRESH);
        }
        HistoryHelper.push(redirectUrl);
      }

      // Cookies.set(CK_TOKEN, response.access, {
      //   secure: import.meta.env.NODE_ENV === "production",
      // });
    } finally {
      setIsLoading(false);
    }
  }

  const handleKeydown1 = (e) => {
    if (e.key === "Enter") {
      //focus password
      const dom = document.getElementById("password");
      dom.focus();
    }
  };
  const handleKeydown2 = (e) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };
  const handleOnComplete = (value) => {
    if (autoSubmittedAtInitialComplete) {
      return;
    }
    setAutoSubmittedAtInitialComplete(true);
    formik.handleSubmit();
  };
  const handleChangeRememberMe = (e, data) => {
    formik.setFieldValue("rememberMe", data);
  };
  return (
    <StyledDiv>
      {/* <div className="wrapper"> */}
      <div className="app-logo">
        <PalLink noPrefix to="/">
          <Logo />
        </PalLink>
      </div>
      <div className="input-box">
        <Grid container spacing={"25px"}>
          <Grid item xs={12}>
            <div className="title">{t("public.login.welcome")}</div>
            <div className="desc">{t("public.login.description")}</div>
          </Grid>
          {alertInfo?.msg && (
            <Grid item xs={12}>
              <Alert severity={alertInfo.color}>{alertInfo.msg}</Alert>
            </Grid>
          )}
          {showOtp ? (
            <Grid item xs={12}>
              <MuiOtpInput
                autoFocus
                length={6}
                value={otpValue}
                onChange={handleChangeOtpValue}
                onComplete={handleOnComplete}
                onKeyDown={handleKeydown2}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <PalTextField
                  fullWidth
                  autoComplete="username"
                  id="username"
                  invalid={formik.touched.username && formik.errors.username}
                  label={t("public.login.usernameLabel")}
                  name="username"
                  type="text"
                  value={formik.values.username?.toLowerCase() || ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onKeyDown={handleKeydown1}
                />
              </Grid>
              <Grid item xs={12}>
                <PalTextField
                  fullWidth
                  autoComplete="password"
                  id="password"
                  invalid={formik.touched.password && formik.errors.password}
                  label={t("public.login.passwordLabel")}
                  name="password"
                  type="password"
                  value={formik.values.password || ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onKeyDown={handleKeydown2}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className="checkbox"
                  control={<PalCheckbox />}
                  label={t("public.login.rememberMe")}
                  name="rememberMe"
                  onChange={handleChangeRememberMe}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <PalButton
              fullWidth
              className="login-button"
              loading={isLoading}
              variant="contained"
              onClick={formik.handleSubmit}
            >
              {showOtp ? t("public.login.otpButton") : t("public.login.loginButton")}
            </PalButton>
          </Grid>
          <Grid item xs={12}>
            <PalLink noPrefix className="forgot-password" to="/forgot-password" underline="none">
              <LockSvg style={{ marginRight: "7px" }} /> {t("public.login.forgotPassword")}
            </PalLink>
          </Grid>
        </Grid>
      </div>
      <Footer />
      {/* </div> */}
    </StyledDiv>
  );
};

export default Login2;
