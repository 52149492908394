import { useState, useRef, useEffect, useCallback, useMemo, forwardRef, useImperativeHandle } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { MdAdd, MdClose } from "react-icons/md";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import { Badge, Box, Button, Menu } from "@mui/material";

import { ReactComponent as DropdownChevronSvg } from "~assets/images/icons/dropdown-chevron.svg";
import { ReactComponent as ContainsIcon } from "~assets/images/icons/filter-contains.svg";
import { ReactComponent as ExactIcon } from "~assets/images/icons/filter-exact.svg";
import { ReactComponent as GtIcon } from "~assets/images/icons/filter-gt.svg";
import { ReactComponent as GteIcon } from "~assets/images/icons/filter-gte.svg";
import { ReactComponent as IcontainsIcon } from "~assets/images/icons/filter-icontains.svg";
import { ReactComponent as InIcon } from "~assets/images/icons/filter-in.svg";
import { ReactComponent as LtIcon } from "~assets/images/icons/filter-lt.svg";
import { ReactComponent as LteIcon } from "~assets/images/icons/filter-lte.svg";
import { ReactComponent as SettingsIcon } from "~assets/images/icons/settings.svg";
import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import { lookupKeysMap } from "~constants";
import DateHelper from "~helpers/DateHelper";
import StorageHelper from "~helpers/StorageHelper";

import PRDate from "./Generic/PRDate";
import PRInput from "./Generic/PRInput";
import PRModal from "./Generic/PRModal";
import PRNumber from "./Generic/PRNumber";
import PRSelect from "./Generic/PRSelect";
import PalIconButton from "./mui/PalIconButton";

function LookupSelect({ type, onChange, value, lookupKeys: lookupKeysProp, usedLookupKeys, disabled }) {
  const { t } = useTranslation();
  const [selectedLookup, setSelectedLookup] = useState(value || "contains");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    setSelectedLookup(value);
  }, [value]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleClickLookup = useCallback(
    (lookupKey) => () => {
      setSelectedLookup(lookupKey);
      onChange(lookupKey);
    },
    [onChange]
  );

  let lookupContent = null;
  if (selectedLookup === "contains") {
    lookupContent = (
      <div className="d-flex align-items-center">
        <ContainsIcon />
      </div>
    );
  } else if (selectedLookup === "icontains") {
    lookupContent = (
      <div className="d-flex align-items-center">
        <IcontainsIcon />
      </div>
    );
  } else if (selectedLookup === "startswith") {
    lookupContent = <div className="d-flex align-items-center">Starts with</div>;
  } else if (selectedLookup === "istartswith") {
    lookupContent = <div className="d-flex align-items-center">iStarts with</div>;
  } else if (selectedLookup === "endswith") {
    lookupContent = <div className="d-flex align-items-center">Ends with</div>;
  } else if (selectedLookup === "iendswith") {
    lookupContent = <div className="d-flex align-items-center">iEnds with</div>;
  } else if (selectedLookup === "in") {
    lookupContent = (
      <div className="d-flex align-items-center">
        <InIcon />
      </div>
    );
  } else if (selectedLookup === "exact") {
    lookupContent = <ExactIcon />;
  } else if (selectedLookup === "gt") {
    lookupContent = <GtIcon />;
  } else if (selectedLookup === "gte") {
    if (type === "date") {
      lookupContent = <GteIcon />;
      // lookupContent = <GtIcon />;
    } else {
      lookupContent = <GteIcon />;
    }
  } else if (selectedLookup === "lt") {
    lookupContent = <LtIcon />;
  } else if (selectedLookup === "lte") {
    if (type === "date") {
      lookupContent = <LteIcon />;
      // lookupContent = <LtIcon />;
    } else {
      lookupContent = <LteIcon />;
    }
  }

  const visibleLookupKeys = Object.entries(lookupKeysMap).filter(
    ([key]) => !lookupKeysProp || lookupKeysProp.includes(key)
  );

  const handleScroll = useCallback(
    (e) => {
      const isUp = e.deltaY < 0;
      const availableVisibleLookupKeys = visibleLookupKeys.filter(([key]) => !usedLookupKeys?.includes(key));
      const currentIndex = availableVisibleLookupKeys.findIndex(([key]) => key === selectedLookup);

      let key;
      if (isUp && availableVisibleLookupKeys[currentIndex - 1]) {
        key = availableVisibleLookupKeys[currentIndex - 1][0];
      } else if (!isUp && availableVisibleLookupKeys[currentIndex + 1]) {
        key = availableVisibleLookupKeys[currentIndex + 1][0];
      }

      if (key) {
        handleClickLookup(key)();
      }
    },
    [selectedLookup, visibleLookupKeys, usedLookupKeys, handleClickLookup]
  );

  return (
    <Box
      sx={{
        height: "26px",
        padding: "4px 10px",
        borderRadius: "4px",
        display: "flex",
        ...(!disabled && {
          border: "solid 1px #ddd",
          backgroundColor: "#fff",
        }),
        alignItems: "center",
        a: {
          gap: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
      }}
    >
      <Dropdown disabled={disabled} isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret outline color="secondary" disabled={disabled} tag="span" onWheel={handleScroll}>
          <div className="border-0">
            {!disabled ? (
              <a href="#">
                {lookupContent}
                <DropdownChevronSvg />
              </a>
            ) : (
              lookupContent
            )}
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {/* <DropdownItem header>Lookup method</DropdownItem> */}
          <Box
            sx={{
              padding: "9px 5px",
              button: {
                padding: "5px",
              },
            }}
          >
            {visibleLookupKeys.map(([key, value]) => {
              const isDisabled = usedLookupKeys?.includes(key);
              const isSelected = selectedLookup === key;
              return (
                <DropdownItem key={key} disabled={isDisabled} onClick={handleClickLookup(key)}>
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      alignItems: "center",
                      color: isSelected ? theme.palette.secondary.main : theme.palette.secondary.dark,
                      // button: {
                      fontSize: 12,
                      fontWeight: isSelected ? 500 : 300,
                      lineHeight: "12px",
                      div: {
                        marginLeft: "4px",
                      },
                      // },
                    })}
                  >
                    {key === "icontains" && <IcontainsIcon />}
                    {key === "istartswith" && <IcontainsIcon />}
                    {key === "iendswith" && <IcontainsIcon />}
                    {key === "contains" && <ContainsIcon />}
                    {key === "startswith" && <ContainsIcon />}
                    {key === "endswith" && <ContainsIcon />}
                    {key === "in" && <InIcon />}
                    {key === "exact" && <ExactIcon />}
                    {key === "gt" && <GtIcon />}
                    {key === "gte" && type !== "date" && <GteIcon />}
                    {/* {key === "gte" && type === "date" && <GtIcon />} */}
                    {key === "gte" && type === "date" && <GteIcon />}
                    {key === "lt" && <LtIcon />}
                    {key === "lte" && type !== "date" && <LteIcon />}
                    {key === "lte" && type === "date" && <LteIcon />}
                    {/* {key === "lte" && type === "date" && <LtIcon />} */}
                    <div>
                      {key === "lte" && type === "date"
                        ? t("component.lookupSelect.beforeTheDate")
                        : key === "gte" && type === "date"
                          ? t("component.lookupSelect.afterTheDate")
                          : value}
                    </div>
                  </Box>
                </DropdownItem>
              );
            })}
          </Box>
        </DropdownMenu>
      </Dropdown>
    </Box>
  );
}

const FilterElement = forwardRef(function FilterElement(
  { filterItem: filterItemProp, isFilterInstance, onClick, usedLookupKeys },
  ref
) {
  const { t } = useTranslation();
  const [filterState, setFilterState] = useState(filterItemProp);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const menuBlockerRef = useRef(null);

  const handleMenuOpen = useCallback(
    (status) => () => {
      // if (menuBlockerRef.current) {
      //   menuBlockerRef.current = false;
      //   return;
      // }
      setIsMenuOpen(status);
    },
    []
  );

  useImperativeHandle(ref, () => ({
    getFilterState: () => {
      // const mappedFilterState = { ...filterState };
      // if (mappedFilterState.multiplier != null && mappedFilterState.type === "number") {
      //   mappedFilterState.value = mappedFilterState.value.map((value) => value * mappedFilterState.multiplier);
      // }

      return filterState;
    },
  }));

  useEffect(() => {
    const newFilterState = { ...filterItemProp };
    setFilterState((prev) => {
      const options = newFilterState.options || prev.options;
      const value = newFilterState.value || prev.value;
      return { ...newFilterState, options: options, value };
    });
  }, [filterItemProp]);

  const handleChangeBooleanFilterValue = useCallback(
    (value) => {
      setFilterState((prev) => ({
        ...prev,
        value: [value],
        lookupKey: filterState.lookupKeys?.[0] || "exact",
      }));
    },
    [filterState]
  );
  const handleChangeTextFilterValue = useCallback(
    (e) => {
      setFilterState((prev) => ({ ...prev, value: [e.target.value] }));
    },
    [setFilterState]
  );
  const handleChangeLookupKey = useCallback((lookupKey) => {
    setFilterState((prev) => ({
      ...prev,
      lookupKey,
    }));
  }, []);
  const handleChangeSelectFilterValue = useCallback(
    (choiceItem) => {
      setFilterState((prev) => ({
        ...prev,
        value: filterState.multiple ? choiceItem : [choiceItem],
      }));
    },
    [filterState]
  );
  const handleChangeDateFilterValue = useCallback((choiceItem) => {
    setFilterState((prev) => ({
      ...prev,
      value: [choiceItem?.local?.()],
    }));
  }, []);

  const handleClick = () => {
    onClick(filterState);
  };

  let component = null;

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Delete" && isFilterInstance) {
        onClick(filterState);
      } else if (e.key === "Enter" && filterState.value?.[0] != null) {
        onClick(filterState, true);
      }
    },
    [onClick, filterState, isFilterInstance]
  );

  const handleKeyDownSelect = useCallback(
    (e) => {
      if (["ArrowUp", "ArrowDown"].includes(e.key)) {
        e.stopPropagation();
      }

      if (e.key === "Delete" && isFilterInstance) {
        onClick(filterState);
      } else if (e.key === "Enter" && filterState.value?.[0] != null && !isMenuOpen) {
        onClick(filterState, true);
      }
    },
    [onClick, filterState, isFilterInstance, isMenuOpen]
  );

  const handleChangeNumberFilterValue = useCallback((e) => {
    setFilterState((prev) => ({
      ...prev,
      value: [e.floatValue],
    }));
  }, []);

  switch (filterState.type) {
    case "text":
      component = (
        <PRInput
          //   innerRef={handleInputRef}
          placeholder="Value"
          value={filterState.value?.[0]}
          onChange={handleChangeTextFilterValue}
          onKeyDown={handleKeyDown}
        />
      );
      break;
    // case "choices":
    case "options":
      const isRemoteFetch = !!filterState.onFetch; //; && !filterState.options;
      component = (
        <PRSelect
          isPrimitiveValue
          menuPortal
          dropdownIcon={<DropdownChevronSvg />}
          isMulti={filterState.multiple}
          labelRenderer={filterState.labelRenderer}
          menuIsOpen={isMenuOpen}
          placeholder="Value"
          value={filterState.multiple ? filterState.value : filterState.value?.[0]}
          onChange={handleChangeSelectFilterValue}
          onKeyDown={handleKeyDownSelect}
          onMenuClose={handleMenuOpen(false)}
          onMenuOpen={handleMenuOpen(true)}
          {...(isRemoteFetch
            ? {
                lazy: true,
                loadOptions: filterState.onFetch,
              }
            : {
                options: filterState.options,
              })}
        />
      );
      break;
    case "date":
    case "datetime":
    case "time":
      component = (
        <PRDate
          placeholder="Value"
          value={filterState.value?.[0] || ""}
          onChange={handleChangeDateFilterValue}
          {...(filterState.type === "date" ? { timeFormat: false } : {})}
          {...(filterState.type === "time" ? { dateFormat: false } : {})}
          isClearable={false}
          onKeyDown={handleKeyDown}
        />
      );
      break;
    case "boolean":
      component = (
        <PRSelect
          isPrimitiveValue
          menuPortal
          dropdownIcon={<DropdownChevronSvg />}
          menuIsOpen={isMenuOpen}
          options={[
            {
              label: t("common.yes"),
              value: true,
            },
            {
              label: t("common.no"),
              value: false,
            },
          ]}
          placeholder={t("component.filterElement.yesNo")}
          value={filterState.value?.[0]}
          onChange={handleChangeBooleanFilterValue}
          onKeyDown={handleKeyDownSelect}
          onMenuClose={handleMenuOpen(false)}
          onMenuOpen={handleMenuOpen(true)}
        />
      );
      break;
    case "number":
      component = (
        <PRNumber
          format={filterState.numberFormat}
          isAllowed={(values) => {
            const { floatValue } = values;
            return (
              (!filterState.min || floatValue >= filterState.min) && (!filterState.max || floatValue <= filterState.max)
            );
          }}
          max={filterState.max ?? Infinity}
          min={filterState.min ?? -Infinity}
          placeholder={t("common.number")}
          step={filterState.step ?? 1}
          value={filterState.value?.[0]}
          onChange={handleChangeNumberFilterValue}
          onKeyDown={handleKeyDown}
        />
      );
      break;
  }

  return (
    <Box
      component={"tr"}
      sx={{
        td: {
          ...(isFilterInstance && {
            backgroundColor: "#fafafa",
          }),
          paddingTop: "9px",
          paddingBottom: "9px",
          //only fist and last
          "&.filter-label": {
            paddingLeft: "5px",
          },
          "&.filter-lookup": {
            paddingLeft: "10px",
            width: "50px",
          },
          "&.filter-input": {
            paddingLeft: "5px",
            width: "100%",
          },
          "&.filter-action": {
            paddingLeft: "5px",
            paddingRight: "5px",
          },
        },
        tr: {
          // margin: "3px 16px",
        },
        ".background-layert": {
          // padding: "3px 16px",
        },
        fontSize: "12px",
        fontWeight: 300,
        color: "#000",
        whiteSpace: "nowrap",

        "input, .pr-sel__placeholder": {
          lineHeight: "14px",
          fontSize: 12,
          fontWeight: 300,
          color: "#000",
          "&::placeholder": {
            color: "rgba(0, 0, 0, 0.45)",
          },
          padding: "6px 0px 6px 10px",
          '&[type="text"]': {
            height: "26px",
          },
        },
        ".pr-sel__multi-value__label": {
          padding: "2px 2px 2px 6px",
        },
        ".pr-sel__placeholder": {
          margin: 0,
          paddingLeft: 0,
          color: "rgba(0, 0, 0, 0.45)",
        },
        ".pr-sel__control ": {
          input: {
            height: "auto",
          },
          background: "#fff",
          ".pr-sel__indicators": {
            height: "auto",
          },
          ".pr-sel__indicator-separator": {
            margin: "2px 0px",
            minHeight: 20,
          },
          svg: {
            margin: "0px 4px",
          },
          minHeight: "26px",

          ".pr-sel__value-container": {
            padding: "0 0 0 10px",
            ".pr-sel__input-container": {
              padding: "0 0 0 0px",
              margin: 0,
            },
          },
          ".pr-sel__indicator": {
            padding: 0,
          },
        },
      }}
    >
      <td className="filter-label">{filterState.label}</td>
      <td className="filter-lookup">
        {filterState.type !== "boolean" && (
          <LookupSelect
            lookupKeys={filterState.lookupKeys}
            type={filterState.type}
            usedLookupKeys={usedLookupKeys}
            value={filterState.lookupKey}
            onChange={handleChangeLookupKey}
          />
        )}
        {filterState.type === "boolean" && <LookupSelect disabled value={"exact"} />}
      </td>
      <td className="filter-input">{component}</td>
      <td className="filter-action">
        <PalIconButton
          size="small"
          sx={(theme) => ({
            backgroundColor: isFilterInstance ? theme.palette.secondary.dark : "#f4f4f4",
            "&:hover": {
              backgroundColor: isFilterInstance ? theme.palette.secondary.dark : "#f4f4f4",
            },
            ...(isFilterInstance && {
              svg: {
                fill: "#fff",
              },
            }),
          })}
          onClick={handleClick}
        >
          {isFilterInstance ? <MdClose /> : <MdAdd />}
        </PalIconButton>
      </td>
    </Box>
  );
});

const PalFilter = forwardRef(function PalFilter(
  { renderButton, size, onChange, filters: filtersProp, value, storageKey, className },
  ref
) {
  const { t } = useTranslation();
  const [filtersOptionsCacheMap, setFiltersOptionsCacheMap] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const filterElementRefs = useRef({});
  const temporaryFilterElementRefs = useRef({});
  const [filterItems, setFilterItems] = useState([]);

  const isSm = useBootstrapBreakpoint("sm");

  const internalData = useMemo(() => {
    if (value) {
      return value;
    }
    return filterItems;
  }, [filterItems, value]);

  const filters = useMemo(() => {
    if (!filtersProp) return [];
    const newFilters = filtersProp.map((filter) => {
      if (filter.type === "options" && filter.onFetch) {
        const handleOnFetch = async (inputValue, callback, signal, valueProp) => {
          const options = await filter.onFetch(inputValue, callback, signal, valueProp);
          setFiltersOptionsCacheMap((prev) => ({ ...prev, [filter.key]: options }));
          return options;
        };
        return {
          ...filter,
          onFetch: handleOnFetch,
        };
      }
      return filter;
    });
    return newFilters.filter((filter) => filter.visible !== false);
  }, [filtersProp]);

  const getMappedFilterItems = useCallback(
    (activeFilterItems, filterList) => {
      const mappedItems = activeFilterItems.map((filterItem) => {
        const filter = filterList.find((filter) => filter?.key === filterItem?.key) || {};
        const restObj = {
          ...(filter.lookup_keys?.includes(filterItem.lookupKey)
            ? { lookupKey: filterItem.lookupKey }
            : { lookupKey: filter.lookup_keys?.[0] }),
        };
        return {
          ...filterItem,
          label: filter.label ?? filterItem.label,
          type: filter.type ?? filterItem.type,
          multiple: filter.multiple ?? filterItem.multiple,
          labelRenderer: filter.labelRenderer ?? filterItem.labelRenderer,
          options: (filtersOptionsCacheMap[filter?.key] || filter.options) ?? filterItem.options,
          onFetch: filter.onFetch ?? filterItem.onFetch,
          min: filter.min ?? filterItem.min,
          max: filter.max ?? filterItem.max,
          step: filter.step ?? filterItem.step,
          multiplier: filter.multiplier ?? filterItem.multiplier,
          numberFormat: filter.numberFormat ?? filterItem.numberFormat,
          defaultLookupKey: filter.default_lookup_key ?? filterItem.defaultLookupKey,
          localFilter: filter.localFilter ?? filterItem.localFilter,
          ...restObj,
          lookupKey: restObj.lookupKey ?? filterItem.lookupKey,
        };
      });

      return mappedItems;
    },
    [filtersOptionsCacheMap]
  );

  const mappedFilterItems = useMemo(() => {
    return getMappedFilterItems(internalData, filters);
  }, [getMappedFilterItems, filters, internalData]);

  const setData = (dataOrFunc) => {
    const isUncontrolled = !value;

    let newFilterItemsData = dataOrFunc;
    if (typeof newFilterItemsData === "function") {
      newFilterItemsData = dataOrFunc(isUncontrolled ? filterItems : value);
    }

    const newMappedFilterItems = getMappedFilterItems(newFilterItemsData, filters);
    const filterQuery = newMappedFilterItems.reduce((acc, filter) => {
      if (filter.value?.length) {
        let postfix = "";
        let value = filter.multiple ? filter.value.join(",") : filter.value?.[0];
        if (filter.defaultLookupKey !== filter.lookupKey) {
          postfix = "__" + filter.lookupKey;
        }
        if (["date", "datetime", "time"].includes(filter.type)) {
          value = DateHelper.format(value);
        }
        if (filter.type === "number") {
          if (filter.multiplier != null) {
            value = value * filter.multiplier;
          }
        }
        acc[filter.key + postfix] = value;
      }
      return acc;
    }, {});

    if (!value) {
      setFilterItems(dataOrFunc);
    }
    onChange?.(newFilterItemsData, filterQuery);
  };

  useImperativeHandle(ref, () => ({
    getFilterItems: () => internalData,
  }));

  useEffect(() => {
    if (!storageKey) return;
    if (value && Array.isArray(value)) {
      setData(value);
      return;
    }

    StorageHelper.get(storageKey).then((localFilterItems) => {
      if (localFilterItems?.length) {
        // const optionFilterInstances = localFilterItems.filter((filterItem) => filterItem.type === "options");
        // for (const optionFilter of optionFilterInstances) {
        //   //TODO: Improve onFetch to fetch only selected options to avoid fetching all options
        //   const filterFromProps = filters.find((filter) => filter.key === optionFilter.key);
        //   filterFromProps?.onFetch?.();
        // }
        for (const item of localFilterItems) {
          if (["date", "datetime", "time"].includes(item.type)) {
            item.value = item.value.map((value) => DateHelper.getDateTimeLocal(value));
          }
        }

        setData(localFilterItems);
      } else {
        setData([]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey, value]);

  useEffect(() => {
    if (!storageKey || value) return;
    const json = JSON.stringify(filterItems, (key, value) => {
      if (key === "value" && moment.isMoment(value?.[0])) {
        return [DateHelper.format(value?.[0])];
      }
      return value;
    });
    StorageHelper.set(storageKey, json);
    return () => {};
    // Only save filterItems to local storage when filterItems change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  // useEffect(() => {
  //   const filterQuery = mappedFilterItems.reduce((acc, filter) => {
  //     if (filter.value?.length) {
  //       let postfix = "";
  //       let value = filter.multiple ? filter.value.join(",") : filter.value?.[0];
  //       if (filter.defaultLookupKey !== filter.lookupKey) {
  //         postfix = "__" + filter.lookupKey;
  //       }
  //       if (["date", "datetime", "time"].includes(filter.type)) {
  //         value = DateHelper.format(value);
  //       }
  //       acc[filter.key + postfix] = value;
  //     }
  //     return acc;
  //   }, {});

  //   if (!isEqual(value, filterItems)) {
  //     onChange?.(filterItems, filterQuery);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterItems]);

  const handleOpenFilter = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClickAddNewFilter = (filterItem) => {
    setData((prev) => {
      const newItems = [
        ...prev.filter((item) => !(item.key === filterItem.key && item.lookupKey === filterItem.lookupKey)),
      ];
      if (filterItem.value.length) {
        newItems.push(filterItem);
      }
      return newItems;
    });
  };

  const handleRemoveOrUpdateFilter = (index) => (filterItem, isEnter) => {
    if (isEnter) {
      handleClickAddNewFilter(filterItem);
    } else {
      setData((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleClearFilters = () => {
    setData([]);
    setAnchorEl(null);
  };

  const handleSubmitAllFilters = () => {
    setAnchorEl(null);
    const filterElements = Object.values(filterElementRefs.current);
    const filterItems = filterElements.map((filterElement) => filterElement?.getFilterState());

    const temporaryFilterElements = Object.values(temporaryFilterElementRefs.current);
    const temporaryFilterItems = temporaryFilterElements.map((filterElement) => filterElement?.getFilterState());
    const nonEmptyFilters = [...filterItems, ...temporaryFilterItems]
      .filter(Boolean)
      .filter((filterItem) => filterItem.value?.length && filterItem.value?.[0] != null);
    setData(nonEmptyFilters);
  };

  const handleFilterElementRef = useCallback(
    (key) => (ref) => {
      filterElementRefs.current[key] = ref;
    },
    []
  );
  const handleTemporaryFilterElementRef = useCallback(
    (key) => (ref) => {
      temporaryFilterElementRefs.current[key] = ref;
    },
    []
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const stopPropagationToMenu = useCallback((e) => {
    //<Menu/> component is handling keydown events to helps auto focus on menu items but it also prevents to type in input like "f"
    e.stopPropagation();
  }, []);

  const popoverContent = (
    <Box
      sx={{
        maxWidth: 1200,
        minWidth: 280,
        table: {
          width: "100%",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "8px",
        boxShadow: "2px 2px 12px 0 rgba(0, 0, 0, 0.08)",
        border: "solid 1px #f8f8f8",
        backgroundColor: "#fff",
        padding: "0px 10px 20px 10px",
        overflow: "auto",
        ...(isSm && {
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }),
        ".filter-divider": {
          height: "1px",
          backgroundColor: "#efeded",
          width: "100%",
          // padding: "10px 0px",
        },
        // maxHeight: 400,
      }}
      onKeyDown={stopPropagationToMenu}
    >
      <Box component="table">
        <tbody>
          <tr>
            <td colSpan={4}>
              <Box
                sx={{
                  fontSize: 13,
                  fontWeight: 500,
                  color: "#000",
                  height: "15px",
                  margin: "15px 0px 15px 5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {t("component.palFilter.title")}
                <PalIconButton
                  size="small"
                  sx={{
                    "&.MuiIconButton-root": {
                      padding: 0,
                    },
                    svg: { color: "#535353", height: 20, width: 20 },
                  }}
                  onClick={handleClose}
                >
                  <MdClose />
                </PalIconButton>
              </Box>
              <div className="filter-divider" />
            </td>
          </tr>

          {mappedFilterItems.map((filterItem, index) => {
            const relatedFilters = mappedFilterItems.filter((f) => f.key === filterItem.key);
            const uniqueUsedLookupKeys = [...new Set(relatedFilters.map((f) => f.lookupKey))];
            return (
              <FilterElement
                key={index}
                ref={handleFilterElementRef(index)}
                isFilterInstance
                usedLookupKeys={uniqueUsedLookupKeys}
                filterItem={filterItem}
                // onChange={handleChangeFilterItem}
                onClick={handleRemoveOrUpdateFilter(index)}
              />
            );
          })}

          {!!mappedFilterItems?.length && !!filters.length && (
            <tr>
              <td colSpan={4}>
                <div className="filter-divider" />
              </td>
            </tr>
          )}

          {filters.map((filterItem, index) => {
            const relatedFilters = mappedFilterItems.filter((f) => f.key === filterItem.key);
            const uniqueUsedLookupKeys = [...new Set(relatedFilters.map((f) => f.lookupKey))];
            const isAllLookupKeysUsed = filterItem.lookup_keys?.every((lookupKey) =>
              uniqueUsedLookupKeys.includes(lookupKey)
            );
            if (isAllLookupKeysUsed) return null;
            return (
              <FilterElement
                key={index}
                filterItem={{
                  type: filterItem.type,
                  key: filterItem.key,
                  label: filterItem.label,
                  multiple: filterItem.multiple,
                  value: [],
                  lookupKey: filterItem.lookup_keys?.[0],
                  lookupKeys: filterItem.lookup_keys,
                  defaultLookupKey: filterItem.default_lookup_key,
                  labelRenderer: filterItem.labelRenderer,
                  onFetch: filterItem.onFetch,
                  min: filterItem.min,
                  max: filterItem.max,
                  step: filterItem.step,
                  multiplier: filterItem.multiplier,
                  numberFormat: filterItem.numberFormat,
                  localFilter: filterItem.localFilter,
                  options: filtersOptionsCacheMap[filterItem.key] || filterItem.options,
                }}
                usedLookupKeys={uniqueUsedLookupKeys}
                ref={handleTemporaryFilterElementRef(index)}
                // onChange={handleChangeFilterItem}
                onClick={handleClickAddNewFilter}
              />
            );
          })}
          <tr>
            <td colSpan={4}>
              <div className="filter-divider" />
            </td>
          </tr>
        </tbody>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 8.5px 0px 0px",
          alignItems: "center",
          ".MuiButton-root": {
            // fontFamily: "Museo Sans",
            textTransform: "none",
            height: "32px",
          },
          ".MuiButton-root:first-of-type": {
            ".pr-icon": {
              width: 24,
              height: 24,
              backgroundColor: "#d5d5d5",
              padding: "2px",
              color: "#fff",
              borderRadius: "50%",
            },
            // fontFamily: "Museo Sans",
            textTransform: "none",
            fontSize: 13,
            fontWeight: 500,
            color: "#535353",
          },
          ".MuiButton-root:last-of-type": {
            borderRadius: "6px",
            backgroundColor: "#50c8f4",
            // fontFamily: "Museo Sans",
            textTransform: "none",
            fontSize: 14,
            fontWeight: "bold",
            color: "#fff",
            minWidth: "126px",
          },
        }}
      >
        <Button startIcon={<MdClose />} onClick={handleClearFilters}>
          {t("component.palFilter.clear")}
        </Button>
        <Button onClick={handleSubmitAllFilters}>{t("common.apply")}</Button>
      </Box>
      {!!filters.length === 0 && (
        <div className="text-center text-muted my-2 ">{t("component.palFilter.noFilter")}</div>
      )}
    </Box>
  );

  return (
    <div>
      <Badge
        badgeContent={internalData.length}
        color="primary"
        sx={{
          ".MuiBadge-badge": {
            p: 0,
            width: "16px",
            minWidth: "16px",
            height: "16px",
            fontSize: 13,
            fontWeight: "bold",
            color: "#fff",
          },
        }}
      >
        {renderButton ? (
          typeof renderButton === "function" ? (
            renderButton({ onClick: handleOpenFilter, size: size === "sm" ? "small" : size === "md" ? "medium" : size })
          ) : (
            renderButton
          )
        ) : (
          <PalIconButton size={size === "sm" ? "small" : size === "md" ? "medium" : size} onClick={handleOpenFilter}>
            <SettingsIcon />
          </PalIconButton>
        )}
      </Badge>
      {!isSm && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          slotProps={{
            paper: {
              sx: {
                "&.MuiMenu-paper": {
                  overflow: "auto",
                },
                ".MuiList-root": {
                  padding: 0,
                },
              },
            },
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleClose}
        >
          {popoverContent}
        </Menu>
      )}
      {isSm && anchorEl && (
        <PRModal fullscreen noFooter noPadding>
          {popoverContent}
        </PRModal>
      )}
    </div>
  );
});
export default PalFilter;
