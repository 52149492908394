import { useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRLink from "~components/Generic/PRLink";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import { apiUrlChatbot, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteIntentTag } from "~store/dialogComponents/intentTags/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

export default function IntentTagsList() {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const dispatch = useDispatch();
  useProjectChange(() => {
    tableRef.current.resetPagination();
  }, []);
  const columns = useMemo(() => {
    return [
      {
        label: t("common.name"),
        key: "key",
      },
      {
        label: t("common.count"),
        key: "intent_count",
      },
      {
        label: t("dashboard.intentTagsList.boundedIntents"),
        key: "intent_list",
        render: (row) => {
          const first20 = row.intent_list?.slice(0, 20);
          const restItems = row.intent_list?.slice(20);
          const restCount = row.intent_list?.length - 20;
          return (
            <>
              {first20?.map((item) => {
                return (
                  <Badge key={item.id} className="badge-soft-secondary me-1">
                    <PRLink newTab to={`/chatbot/intent/form/${item.id}/`}>
                      <div
                        className="font-size-12 text-truncate
                      "
                        style={{ maxWidth: 225 }}
                      >
                        <PRTooltip title={item.display_text || item.text}>{item.display_text || item.text}</PRTooltip>
                      </div>
                    </PRLink>
                  </Badge>
                );
              })}

              {restCount > 0 && (
                <Badge className="badge-soft-secondary me-1">
                  <div className="font-size-12 text-primary">
                    <PRTooltip
                      title={
                        <ul className="text-start">
                          {restItems.map((item) => (
                            <li key={item.id}>{item.display_text || item.text}</li>
                          ))}
                        </ul>
                      }
                    >
                      +{restCount}
                    </PRTooltip>
                  </div>
                </Badge>
              )}
            </>
          );
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => {
          const handleClickDelete = async () => {
            if (!(await DialogHelper.showQuestionDeleteWithFormat(row.key))) return;
            dispatch(deleteIntentTag(currentProject.id, currentBot.id, row.id)).then(() => {
              tableRef.current.refresh();
            });
          };

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                color="primary"
                icon={MdEdit}
                link={`/chatbot/intent-tags/form/${row.id}`}
                size="sm"
                tooltipText={t("common.edit")}
              />
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText={t("common.delete")}
                onClick={handleClickDelete}
              />
            </div>
          );
        },
      },
    ];
  }, [dispatch, currentProject, currentBot, t]);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: `/chatbot/intent-tags/form/`,
      },
    ];
  }, []);

  const filters = useMemo(() => {
    return [
      {
        key: "bot",
        visible: false,
      },
      {
        key: "key",
        label: t("common.name"),
      },
    ];
  }, [t]);

  const parentName = [
    {
      label: t("common.intentTags"),
      url: "/chatbot/intent-tags",
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      actions={actions}
      description={t("dashboard.intentTagsList.description")}
      name={t("common.chatbot")}
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.intentTagsList}
        url={apiUrlChatbot.getIntentTags.format(currentProject.id, currentBot.id)}
      />
    </PRContainer>
  );
}
